import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../customizes/view/base-component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent extends BaseComponent implements OnInit {
    constructor(
        protected router: Router,
        protected route: ActivatedRoute
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

}
