/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ValidatorMessageComponent} from './components/validator-message/validator-message.component';
import {ToolbarModule} from "primeng/toolbar";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ValidateRegexDirective} from "./directives/validate-regex.directive";
import {TableControlComponent} from './components/table-control/table-control.component';
import {TooltipModule} from "primeng/tooltip";
import {GeneralMessagesComponent} from './components/general-messages/general-messages.component';
import {ToastModule} from "primeng/toast";
import {MessagesModule} from "primeng/messages";
import {GeneralToolbarComponent} from './components/general-toolbar/general-toolbar.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {DialogComponent} from './components/dialog/dialog.component';
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import {TelsoftComponent} from './components/telsoft/telsoft.component';
import {DialogAdvanceComponent} from './components/dialog-advance/dialog-advance.component';
import {ScrollPanelComponent} from './components/scroll-panel/scroll-panel.component';
import {TranslateModule} from "@ngx-translate/core";
import {SpinnerComponent} from "./components/spinner/spinner.component";
import {SharedModule} from "primeng/api";
import {DataFilterPipe} from "./pipes/data-filter.pipe";
import {RippleModule} from "primeng/ripple";
import { KeepaliveComponent } from './components/keepalive/keepalive.component';
import {CountdownModule} from "ngx-countdown";
import { NoRightClickDirective } from './directives/no-right-click.directive';
import { CheckboxComponent } from './controls/checkbox/checkbox.component';
import {CheckboxModule} from "primeng/checkbox";
import {LabelComponent} from "./components/label/label.component";
import { FormChangePwdComponent } from './components/form-change-pwd/form-change-pwd.component';
import {PasswordModule} from "primeng/password";

@NgModule({
    declarations: [
        HasPermissionDirective,
        ValidateRegexDirective,
        ConfirmDialogComponent,
        ValidatorMessageComponent,
        TableControlComponent,
        GeneralMessagesComponent,
        GeneralToolbarComponent,
        BreadcrumbComponent,
        DialogComponent,
        TelsoftComponent,
        DialogAdvanceComponent,
        ScrollPanelComponent,
        SpinnerComponent,
        DataFilterPipe,
        KeepaliveComponent,
        NoRightClickDirective,
        CheckboxComponent,
        LabelComponent,
        FormChangePwdComponent
    ],
    imports: [
        RouterModule,
        SharedModule,
        CommonModule,
        FormsModule,
        ConfirmDialogModule,
        ToolbarModule,
        ConfirmPopupModule,
        TooltipModule,
        ToastModule,
        MessagesModule,
        BreadcrumbModule,
        DialogModule,
        TableModule,
        TranslateModule,
        RippleModule,
        CountdownModule,
        CheckboxModule,
        PasswordModule
    ],
    exports: [
        HasPermissionDirective,
        ValidateRegexDirective,
        ConfirmDialogComponent,
        ValidatorMessageComponent,
        TableControlComponent,
        GeneralMessagesComponent,
        GeneralToolbarComponent,
        BreadcrumbComponent,
        DialogComponent,
        DialogAdvanceComponent,
        TelsoftComponent,
        ScrollPanelComponent,
        SpinnerComponent,
        KeepaliveComponent,
        NoRightClickDirective,
        CheckboxComponent,
        LabelComponent,
        FormChangePwdComponent
    ]
})

export class CustomizesModule {
}
