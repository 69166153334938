/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 01/09/2021.
 */

import {BaseComponent} from "./base-component";
import {Utils} from '../../common/utils';
import {StringUtils} from "../../common/string-utils";
import {LazyLoadEvent, TreeNode} from "primeng/api";
import {TableData} from "../../models/lazy-load-data/table-data";
import {plainToClass} from "class-transformer";
import {ApiFilter} from "./api-filter";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {ApiSearch} from "./api-search";
import {AlertMessages} from "../../models/messages/alertMessages";


export abstract class CrudComponent extends BaseComponent {
    isView: boolean;
    isCreate: boolean;
    isCopy: boolean;
    isUpdate: boolean;
    isShowEttDialog: boolean;
    isDisable: boolean;
    isSubmitted: boolean;

    abstract onPreCreate();

    abstract onPreCopy(ett: any);

    abstract onPreView(ett: any);

    abstract onPreUpdate(ett: any);

    abstract onPreDelete(obj: any, reloadList?: boolean);

    abstract async onCreate(alertMessages?: AlertMessages, reloadList?: boolean);

    abstract async onUpdate(alertMessages?: AlertMessages, reloadList?: boolean);

    abstract async onDelete(alertMessages?: AlertMessages, reloadList?: boolean);

    abstract async onDeleteMany(alertMessages?: AlertMessages, reloadList?: boolean);

    async preSave() {}

    async afterSave() {}

    async afterDelete() {}

    getDialogTitle() {
        if (this.isCreate) {
            return this.messageService.translate("common.label.addRecord");

        } else if (this.isUpdate) {
            return this.messageService.translate("common.label.updateRecord");

        } else if (this.isView) {
            return this.messageService.translate("common.label.viewRecord");
        }
    }

    hideEttDialog() {
        this.isShowEttDialog = false;
        this.mainMessage.msgs = [];
        this.otherMessage1.msgs = [];
    }

    async handleSimpleSave(invalidForm?: boolean, alertMessages?: AlertMessages) {
        this.isSubmitted = true;
        this.errorResponse = null;

        if (invalidForm === true) {
            this.messageService.showErrorViaToast("common.alert.pleaseCheckInputData");
            return;
        }

        await this.preSave();

        if (this.isCreate) {
            await this.onCreate(alertMessages);

        } else if (this.isUpdate) {
            await this.onUpdate(alertMessages);

        } else {
            await this.onSave();
        }

        if (Utils.isNullOrUndefined(this.errorResponse)) {
            this.mainMessage = new AlertMessages();
            this.otherMessage1 = new AlertMessages();
        }

        await this.afterSave();
    }

    async handleSave(invalidForm?: boolean, alertMessages?: AlertMessages, reloadList?: boolean) {
        this.isSubmitted = true;
        this.errorResponse = null;

        this.mainMessage = new AlertMessages();
        this.otherMessage1 = new AlertMessages();

        if (invalidForm === true) {
            this.messageService.showErrorViaToast("common.alert.pleaseCheckInputData");
            return;
        }

        await this.preSave();

        if (this.isCreate) {
            await this.onCreate(alertMessages, reloadList);

        } else if (this.isUpdate) {
            await this.onUpdate(alertMessages, reloadList);

        } else {
            await this.onSave();
        }

        if (Utils.isNullOrUndefined(this.errorResponse)) {
            this.isUpdate = false;
            this.isCreate = false;
            this.isShowEttDialog = false;
            this.mainMessage = new AlertMessages();
            this.otherMessage1 = new AlertMessages();
        }

        await this.afterSave();
    }

    async onSave() {
    }

    preCreate = () => {
        this.isSubmitted = false;
        this.isCreate = true;
        this.isCopy = false;
        this.isUpdate = false;
        this.isView = false;
        this.isShowEttDialog = true;
        this.isDisable = false;
        this.onPreCreate();
    }

    preCopy = (ett: any) => {
        this.isSubmitted = false;
        this.isShowEttDialog = true;
        this.isCreate = true;
        this.isCopy = true;
        this.isUpdate = false;
        this.isView = false;
        this.isDisable = false;
        this.onPreCopy(ett);
    }

    preView = (ett: any) => {
        this.isSubmitted = false;
        this.isShowEttDialog = true;
        this.isView = true;
        this.isCreate = false;
        this.isCopy = false;
        this.isUpdate = false;
        this.isDisable = true;
        this.onPreView(ett);
    }

    preUpdate = (ett: any) => {
        this.isSubmitted = false;
        this.isShowEttDialog = true;
        this.isUpdate = true;
        this.isCreate = false;
        this.isCopy = false;
        this.isView = false;
        this.isDisable = false;
        this.onPreUpdate(ett);
    }

    preDelete = (obj: any, event?: Event, reloadList?: boolean) => {
        this.isSubmitted = false;
        this.onPreDelete(obj, reloadList);

        const deleteCallback = async () => {
            await this.onDelete();
        };

        this.confirmDialog(
            this.messageService.translate("common.label.confirmDelete"),
            this.messageService.translate("common.label.confirm"),
            deleteCallback,
            event
        );
    }

    preDeleteMany = (reloadList?: boolean) => {
        const deleteManyCallback = async () => {
            await this.onDeleteMany(null, reloadList);
        };

        this.confirmDialog(
            this.messageService.translate("common.label.confirmDeleteMany"),
            this.messageService.translate("common.label.confirm"),
            deleteManyCallback
        );
    }
}
