<p-toolbar [styleClass]="!isTableToolbar ? 'p-mb-4' : 'table-toolbar'">
    <ng-template pTemplate="left" *ngIf="!isTableToolbar">
        <button *appPermissions="['C']" pButton (click)="preCreate()" label="{{'common.button.create'|translate}}"
                type="button" class="{{'common.button.class.create'|translate}}" pRipple
                icon="{{'common.button.icon.create'|translate}}"></button>

        <div *ngIf="btnDeleteMany">
            <button *appPermissions="['D']" type="button" pButton (click)="preDeleteMany(reloadList)"
                    label="{{'common.button.delete'|translate}}" pRipple
                    class="{{'common.button.class.delete'|translate}}" icon="{{'common.button.icon.delete'|translate}}"
                    [disabled]="!isAllowDeleteMany"></button>
        </div>
    </ng-template>

    <ng-template pTemplate="left" *ngIf="isTableToolbar">
        <button *appPermissions="['C']" pButton (click)="preCreate()" type="button" pRipple
                pTooltip="{{'common.button.create'|translate}}"
                class="{{'common.button.class.table.create'|translate}}"
                icon="{{'common.button.icon.create'|translate}}"></button>

        <div *ngIf="btnDeleteMany">
            <button *appPermissions="['D']" type="button" pButton (click)="preDeleteMany(reloadList)" pRipple
                    pTooltip="{{'common.button.delete'|translate}}"
                    class="{{'common.button.class.table.delete'|translate}}"
                    icon="{{'common.button.icon.delete'|translate}}"
                    [disabled]="!isAllowDeleteMany"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="right" >
        <ng-content></ng-content>
        <button *ngIf="exportExcel" type="button" pButton (click)="exportExcel()" pRipple
                label="{{'common.button.exportExcel'|translate}}"
                class="p-button-info"
                icon="{{'common.button.icon.export'|translate}}"></button>
    </ng-template>
</p-toolbar>
