import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LOCATION_INITIALIZED, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AccordionModule} from 'primeng/accordion';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {PasswordModule} from 'primeng/password';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {AppComponent} from './app.component';
import {AppCodeModule} from './_app-core/shared/layout/app.code.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from "primeng/api";
import {setAppInjector} from "./app-injector";
import {BlockUIModule} from "primeng/blockui";
import {AlifeFileToBase64Module} from "alife-file-to-base64";
import {ChartModule} from "primeng/chart";
import {FullCalendarModule} from "primeng/fullcalendar";
import {ProgressBarModule} from "primeng/progressbar";
import {MenuModule} from "primeng/menu";
import {InputSwitchModule} from "primeng/inputswitch";
import {FieldsetModule} from 'primeng/fieldset';
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";

import {storageKey} from "./app-constant";
import {AppMainComponent} from "./_app-core/shared/layout/app.main.component";
import {AppMenuComponent} from "./_app-core/shared/layout/app.menu.component";
import {AppMenuitemComponent} from "./_app-core/shared/layout/app.menuitem.component";
import {AppConfigComponent} from "./_app-core/shared/layout/app.config.component";
import {AppRightMenuComponent} from "./_app-core/shared/layout/app.right-menu.component";
import {AppTopBarComponent} from "./_app-core/shared/layout/app.topbar.component";
import {AppFooterComponent} from "./_app-core/shared/layout/app.footer.component";
import {DashboardDemoComponent} from "./app-demo/pages/dashboard/dashboarddemo.component";
import {AppNotfoundComponent} from "./_app-core/shared/pages/app.notfound.component";
import {AppErrorComponent} from "./_app-core/shared/pages/app.error.component";
import {AppAccessdeniedComponent} from "./_app-core/shared/pages/app.accessdenied.component";
import {AppLoginComponent} from "./_app-core/shared/pages/app.login.component";
import {MngTokenCodeComponent} from "./app-demo/pages/mng-token-code/mng-token-code.component";
import {AppMessageService} from "./_app-core/customizes/services/app-message.service";
import {CoreModule} from "./_app-core/core.module";
import {CustomizesModule} from "./_app-core/customizes/customizes.module";
import {StringUtils} from "./_app-core/common/string-utils";
import {BasicAuthHttpInterceptorService} from "./_app-core/services/autho/basic-auth-http-interceptor.service";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {MenuService} from "./_app-core/shared/layout/app.menu.service";
import {AppCookie} from "./_app-core/services/auth/auth-cookies-handler";
import {AuthGuard} from "./_app-core/services/auth/auth.guard";
import {Language} from "./_app-core/models/language/language";
import {Utils} from "./_app-core/common/utils";
import {ToolbarModule} from "primeng/toolbar";
import {KeyFilterModule} from "primeng/keyfilter";
import {CountdownModule} from "ngx-countdown";
import {InputMaskModule} from "primeng/inputmask";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {ToggleButtonModule} from "primeng/togglebutton";
import {registerLocaleData} from '@angular/common';
import vi from '@angular/common/locales/vi';
import {AutoCompleteModule} from "primeng/autocomplete";
import {MngCustomerComponent} from './app-demo/pages/mng-customer/mng-customer.component';
import {TokenListComponent} from './app-demo/pages/mng-customer/token-list/token-list.component';
import {TransactionListComponent} from './app-demo/pages/mng-customer/transaction-list/transaction-list.component';
import {MngProductStoreComponent} from "./app-demo/pages/mng-product-store/mng-product-store.component";
import {MngProductComponent} from "./app-demo/pages/mng-product/mng-product.component";
import {ProductPriceComponent} from "./app-demo/pages/mng-product/product-price/product-price.component";
import {MngOrganizationComponent} from "./app-demo/pages/mng-organizaion/mng-organization.component";
import { AccountListComponent } from './app-demo/pages/mng-organizaion/account-list/account-list.component';
import {MngPromotionComponent} from "./app-demo/pages/mng-promotion/mng-promotion.component";
import {MngAwardComponent} from "./app-demo/pages/mng-award/mng-award.component";
import {MngEventComponent} from "./app-demo/pages/mng-event/mng-event.component";
import {MngAccountComponent} from "./app-demo/pages/mng-account/mng-account.component";
import {MngClassificationComponent} from "./app-demo/pages/mng-classification/mng-classification.component";
import { RptOrgStatusComponent } from './app-demo/pages/rpt-org-status/rpt-org-status.component';
import {SelectButtonModule} from "primeng/selectbutton";
import {RptOrgInfoComponent} from "./app-demo/pages/rpt-org-info/rpt-org-info.component";
import {RptLoyaltyInfoComponent} from "./app-demo/pages/rpt-loyalty-info/rpt-loyalty-info.component";
import {RptCustomerComponent} from './app-demo/pages/rpt-customer/rpt-customer.component';
import {RptTransComponent} from './app-demo/pages/rpt-trans/rpt-trans.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}

const maskConfig: Partial<IConfig> = {
    showMaskTyped: true,
};

@NgModule({
    imports: [
        NgxMaskModule.forRoot(maskConfig),
        TranslateModule.forRoot({
            defaultLanguage: 'vi',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CountdownModule,
        CoreModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CheckboxModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ChartModule,
        FullCalendarModule,
        ProgressBarModule,
        MenuModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        InputNumberModule,
        InputTextModule,
        InputTextareaModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OverlayPanelModule,
        PanelModule,
        PasswordModule,
        RadioButtonModule,
        RippleModule,
        TableModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        TreeModule,
        NgbModule,
        CustomizesModule,
        BlockUIModule,
        AlifeFileToBase64Module,
        InputSwitchModule,
        FieldsetModule,
        ToolbarModule,
        KeyFilterModule,
        ToggleButtonModule,
        InputMaskModule,
        AutoCompleteModule,
        SelectButtonModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppRightMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        MngTokenCodeComponent,
        MngCustomerComponent,
        TokenListComponent,
        TransactionListComponent,
        MngProductStoreComponent,
        MngProductComponent,
        ProductPriceComponent,
        MngOrganizationComponent,
        AccountListComponent,
        MngPromotionComponent,
        MngAwardComponent,
        MngEventComponent,
        AccountListComponent,
        MngAccountComponent,
        MngClassificationComponent,
        RptOrgStatusComponent,
        RptOrgInfoComponent,
        RptLoyaltyInfoComponent,
        RptCustomerComponent,
        RptTransComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthHttpInterceptorService, multi: true},
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
        MenuService, AuthGuard, AppCookie,
        JwtHelperService, MessageService, AppMessageService, ConfirmationService, StringUtils
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(injector: Injector) {
        setAppInjector(injector);
    }
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    let locale = new Language(1, "vi", "Việt Nam");
    registerLocaleData(vi);

    // Set locale
    if (Utils.isNullOrUndefined(localStorage.getItem(storageKey.LOCALE))) {
        localStorage.setItem(storageKey.LOCALE, JSON.stringify(locale));
    } else {
        locale = JSON.parse(localStorage.getItem(storageKey.LOCALE));
    }

    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            const langToSet = locale.languageCode;
            translate.setDefaultLang(langToSet);
            translate.use(langToSet).subscribe(() => {
                // console.info(`Successfully initialized '${langToSet}' language.'`);
            }, err => {
                // console.error(`Problem with '${langToSet}' language initialization.'`);
            }, () => {
                resolve(null);
            });
        });
    });
}
