<div *ngIf="templateVar?.invalid && (isSubmitted || (templateVar.dirty || templateVar.touched))">
    <small *ngIf="templateVar.errors?.required"
           class="p-invalid invalid-msg">{{getRequiredMsg()}}</small>

    <small *ngIf="templateVar.errors?.minlength"
           class="p-invalid invalid-msg">{{getMinLengthMsg()}}</small>

    <small *ngIf="templateVar.errors?.maxlength"
           class="p-invalid invalid-msg">{{getMaxLengthMsg()}}</small>

    <small *ngIf="templateVar.errors?.email"
           class="p-invalid invalid-msg">{{getEmailMsg()}}</small>

    <small *ngIf="templateVar.errors?.mask"
           class="p-invalid invalid-msg">{{getMaskMsg()}}</small>

    <small *ngIf="templateVar.errors?.regex"
           class="p-invalid invalid-msg">{{templateVar.errors?.regex.message | translate}}</small>
</div>
