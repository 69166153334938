import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessages} from "../../../models/messages/alertMessages";

@Component({
    selector: 'app-form-change-pwd',
    templateUrl: './form-change-pwd.component.html',
    styleUrls: ['./form-change-pwd.component.scss']
})
export class FormChangePwdComponent extends BaseComponent implements OnInit {
    @Output() showDialogChange = new EventEmitter<boolean>();
    @Input() showDialog;
    oldPassword = '';
    newPassword = '';
    newPasswordVerify = '';
    isSubmitted = false;


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

    async handleChangePwd(formInvalid: boolean) {
        this.isSubmitted = true;

        if (formInvalid) {
            return;
        }

        // Clear old msg
        this.mainMessage = new AlertMessages();
        this.otherMessage1 = new AlertMessages();

        // tslint:disable-next-line:max-line-length
        await this.executeHttpRequest(this.authService.changePwd(this.authService.getUser(), this.oldPassword, this.newPassword, this.newPasswordVerify), result => {
            this.messageService.toastUpdateSuccess();
            this.showDialogChange.emit(false);
        }, null, this.otherMessage1);

        this.isSubmitted = false;
    }
}
