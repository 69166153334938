import {Component, OnInit, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";

import {EventService} from "../../services/event/event.service";
import {ProductStoreService} from "../../services/product-store/product-store.service";
import {Event} from "../../models/event/event";
import {EventStatusService} from "../../services/event/event-status.service";

@Component({
    selector: 'app-event',
    templateUrl: './mng-event.component.html',
    styleUrls: ['./mng-event.component.scss']
})
export class MngEventComponent extends SimpleCrudLazyComponent implements OnInit {

    @ViewChild('dt') table: Table;
    selectedEtt: Event;
    listStatus: any[] = [];

    windowHeight: number;

    displayDialogAddProductStore: boolean;
    selectedProductStores: any[] = [];
    productStores: any[] = [];
    totalProductStores: number;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private eventService: EventService,
        private eventStatusService: EventStatusService,
        private productStoreService: ProductStoreService
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.windowHeight = window.innerHeight;
        this.executeHttpRequest(this.eventStatusService.listAll(), result => {
            this.listStatus = [];
            result.listData.forEach((item) => {
                this.listStatus.push({value: item.statusId, label: item.description});
            });
        });
    }

    afterPreAction() {
        if (this.isCreate) {
            this.selectedEtt.productStores = [];
        } else {
            this.executeHttpRequest(
                this.eventService.view(this.selectedEtt.storePromotionId), result => {
                    if (result.fromDate) {
                        result.fromDate = new Date(result.fromDate);
                    }
                    if (result.thruDate) {
                        result.thruDate = new Date(result.thruDate);
                    }
                    this.selectedEtt = result;
                    this.selectedEtt.deletedProductStores = [];
                }
            );
        }
        super.afterPreAction();
    }

    getEttClass(): any {
        return Event;
    }

    getEttKey(): string {
        return "storePromotionId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return EventService;
    }

    showDialogAddProductStore(): void {
        this.displayDialogAddProductStore = true;
        this.selectedProductStores = this.selectedEtt.productStores;
    }

    loadProductStoresLazy(event: any): void {
        this.executeHttpRequest(this.productStoreService.listLazy(event), result => {
            this.productStores = result?.listData;
            this.totalProductStores = result?.totalRecords;
        });
    }

    addProductStore(): void {
        if (this.selectedProductStores && this.selectedProductStores.length) {
            this.selectedProductStores.forEach((store) => {
                let exists = false;
                for (const s of this.selectedEtt.productStores) {
                    if (s.productStoreId === store.productStoreId) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    this.selectedEtt.productStores.push(store);
                }
            });
        }
        this.displayDialogAddProductStore = false;
    }

    deleteProductStore(rowData: any): void {
        this.selectedEtt.productStores = this.selectedEtt.productStores.filter((item: any) => {
            return item.productStoreId !== rowData.productStoreId;
        });
        if (rowData.parameterName) {
            this.selectedEtt.deletedProductStores.push(rowData.parameterName);
        }
    }

    approve(): void {
        if (this.selectedEtt && this.selectedEtt.storePromotionId) {
            this.eventService.approve(this.selectedEtt.storePromotionId).subscribe(() => {
                this.onLoadList().then();
            });
        }
    }

}
