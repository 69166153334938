export class Product {
    organizationId: string;
    lastUpdatedStamp: number;
    productCode: string;
    productId: string;
    organizationName: string;
    description: string;
    productName: string;
    comments: string;
    amountUomName: string;
    amountUomId: string;
    amountFixed: number;
    productTypeEnumId: string;

    getEttName() {
        return this.productName;
    }
}
