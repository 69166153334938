import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName, getServerApiUrl} from "../../../app-constant";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenTransactionService extends AbstractCrudService {

  customerId: string;
  fromDate: any;
  thruDate: any;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getApiName() {
    // console.log(this.fromDate);
    let dateQuery = '';
    if (this.fromDate) {
      dateQuery = `fromDate=${new Date(this.fromDate).getTime()}`;
    }
    if (this.thruDate) {
      if (dateQuery) {
        dateQuery = `${dateQuery}&thruDate=${new Date(this.thruDate).getTime()}`;
      } else {
        dateQuery = `thruDate=${new Date(this.thruDate).getTime()}`;
      }
    }
    return apiName.CUSTOMER + `/${this.customerId}/tokenTransactions?${dateQuery}`;
  }

  getDefaultSortField(): string {
    return "postedDate";
  }

  create<T>(data: any): Observable<T> {
    const url = getServerApiUrl() + '/iam-customer/customers/' + data.url;
    delete data.url;
    return super.post<T>(data, url);
  }

}
