import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../_app-core/customizes/view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {RptLoyaltyInfoService} from "../../services/report/rpt-loyalty-info.service";

@Component({
    selector: 'app-rpt-org-info',
    templateUrl: './rpt-loyalty-info.component.html',
    styleUrls: ['./rpt-loyalty-info.component.scss']
})
export class RptLoyaltyInfoComponent extends BaseComponent implements OnInit {
    listData: any[] = [];
    fromDate: Date;
    thruDate: Date;
    isSubmitted: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected service: RptLoyaltyInfoService,
    ) {
        super(router, route);

        this.fromDate = new Date();
        this.fromDate.setHours(0, 0, 0, 0);
        this.thruDate = new Date(this.fromDate);
    }

    ngOnInit(): void {
    }

    search(isInvalidForm: boolean) {
        if (isInvalidForm) {
            return;
        }

        this.executeHttpRequest(this.service.listByDateRange(this.fromDate, this.thruDate), result => {
            this.listData = result.listData;
        });
    }
}
