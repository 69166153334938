import {Type} from "class-transformer";

export class Award {
    storePromotionId: string;
    pseudoId: string;
    itemDescription: string;
    includeAllProductStores: boolean;
    productStores: any[];
    deletedProductStores: string[];
    onlyClassificationCustomer: boolean;
    partyClassifications: any[];
    deletedPartyClassifications: string[];
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    thruDate: Date;
    gifts: any[];
    deletedGifts: string[];
}
