import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-general-toolbar',
    templateUrl: './general-toolbar.component.html',
    styleUrls: ['./general-toolbar.component.scss']
})
export class GeneralToolbarComponent implements OnInit {
    @Input() isTableToolbar = false;
    @Input() isAllowDeleteMany: boolean;
    @Input() btnDeleteMany = true;
    @Input() preCreate: () => void;
    @Input() preDeleteMany: (reloadList: boolean) => void;
    @Input() reloadList = false;

    @Input() exportExcel: () => void;

    constructor() {
    }

    ngOnInit(): void {
    }

}
