import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-table-control',
    templateUrl: './table-control.component.html',
})
export class TableControlComponent implements OnInit {
    @Input() entity: any;
    @Input() preView: (ett: any) => void;
    @Input() preCopy: (ett: any) => void;
    @Input() preUpdate: (ett: any, event?: any) => void;
    @Input() preDelete: (ett: any, event: any, reloadList: boolean) => void;
    @Input() btnView = true;
    @Input() btnCopy = true;
    @Input() isAllowCopy: boolean;
    @Input() btnUpdate = true;
    @Input() isAllowUpdate: boolean;
    @Input() btnDelete = true;
    @Input() isAllowDelete: boolean;
    @Input() reloadList = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
