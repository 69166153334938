import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";

@Injectable({
    providedIn: 'root'
})
export class ProductPriceService extends AbstractCrudService {
    productId: string;

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.PRODUCT + `/${this.productId}/prices`;
    }

    getDefaultSortField(): string {
        return "productPriceId";
    }
}
