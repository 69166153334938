import {Type} from "class-transformer";

export class Uom {
    uomId: string;
    uomTypeEnumId: string;
    abbreviation: string;
    description: string;
    @Type(() => Date)
    lastUpdatedStamp: number;
    name: string;
}
