<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>

<p-card>
    <form #f="ngForm" (ngSubmit)="search(f.invalid)">
        <div class="p-fluid w-50">
            <div class="row">
                <div class="col-12 col-md-5 py-0">
                    <div class="p-field">
                        <app-label for="txtFromDate" value="label.rptLoyaltyInfo.fromDate"></app-label>
                        <p-calendar id="txtFromDate" name="txtFromDate" appendTo="body"
                                    [(ngModel)]="fromDate" #txtFromDate="ngModel" monthNavigator="true"
                                    yearNavigator="true" [yearRange]="getYearRange()" required
                                    [showSeconds]="true" showButtonBar="true"></p-calendar>
                        <app-validator-message [templateVar]="txtFromDate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-5 py-0">
                    <div class="p-field">
                        <app-label for="txtThruDate" value="label.rptLoyaltyInfo.thruDate"></app-label>
                        <p-calendar id="txtThruDate" name="txtThruDate" appendTo="body"
                                    [(ngModel)]="thruDate" #txtThruDate="ngModel" monthNavigator="true"
                                    yearNavigator="true" [yearRange]="getYearRange()" required
                                    [showSeconds]="true" showButtonBar="true"></p-calendar>
                        <app-validator-message [templateVar]="txtThruDate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-2 py-0">
                    <div class="p-field">
                        <label>&nbsp;</label>
                        <button pButton pRipple type="submit"
                                label="{{'common.button.search'|translate}}"
                                icon="{{'common.button.icon.search'|translate}}"
                                class="p-button p-button-info"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-table #dt [value]="listData" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" styleClass="mt-3"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="organizationCode">
                    {{"label.rptLoyaltyInfo.organizationCode" | translate}}
                    <p-sortIcon field="organizationCode"></p-sortIcon>
                </th>
                <th pSortableColumn="organizationName">
                    {{"label.rptLoyaltyInfo.organizationName" | translate}}
                    <p-sortIcon field="organizationName"></p-sortIcon>
                </th>
                <th pSortableColumn="sumEarn">
                    {{"label.rptLoyaltyInfo.sumEarn" | translate}}
                    <p-sortIcon field="sumEarn"></p-sortIcon>
                </th>
                <th pSortableColumn="sumRedeem">
                    {{"label.rptLoyaltyInfo.sumRedeem" | translate}}
                    <p-sortIcon field="sumRedeem"></p-sortIcon>
                </th>
                <th pSortableColumn="tokenCodeCount">
                    {{"label.rptLoyaltyInfo.tokenCodeCount" | translate}}
                    <p-sortIcon field="tokenCodeCount"></p-sortIcon>
                </th>
                <th pSortableColumn="tokenCodeUsedCount">
                    {{"label.rptLoyaltyInfo.tokenCodeUsedCount" | translate}}
                    <p-sortIcon field="tokenCodeUsedCount"></p-sortIcon>
                </th>
                <th pSortableColumn="tokenCodeNotUsedCount">
                    {{"label.rptLoyaltyInfo.tokenCodeNotUsedCount" | translate}}
                    <p-sortIcon field="tokenCodeNotUsedCount"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="organizationCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="sumEarn"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="sumRedeem"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="tokenCodeCount"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="tokenCodeUsedCount"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="tokenCodeNotUsedCount"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-org>
            <tr>
                <td>
                    {{org.organizationCode}}
                </td>
                <td>
                    {{org.organizationName}}
                </td>
                <td>
                    {{org.sumEarn || 0 | number}}
                </td>
                <td>
                    {{org.sumRedeem || 0 | number}}
                </td>
                <td>
                    {{org.tokenCodeCount || 0 | number}}
                </td>
                <td>
                    {{org.tokenCodeUsedCount || 0 | number}}
                </td>
                <td>
                    {{org.tokenCodeNotUsedCount || 0 | number}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
