import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {SimpleCrudLazyComponent} from "../../../../_app-core/customizes/view/simple-crud-lazy-component";
import {ProductPrice} from "../../../models/product/product-price";
import {ProductPriceService} from "../../../services/product/product-price.service";
import {ProductStore} from "../../../models/product-store/product-store";


@Component({
    selector: 'app-product-price',
    templateUrl: './product-price.component.html',
    styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent extends SimpleCrudLazyComponent implements OnInit, OnChanges {
    @ViewChild('dt') table: Table;
    @Input() productId: string;
    selectedEtt: ProductPrice;
    selectedStore: ProductStore;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.crudService.productId = changes.productId?.currentValue;
        this.onLoadList();
    }

    clearCurrentStore() {
        this.selectedEtt.storeName = undefined;
        this.selectedEtt.productStoreId = undefined;
    }

    onChooseStore(store: ProductStore) {
        this.selectedEtt.storeName = store.storeName;
        this.selectedEtt.productStoreId = store.productStoreId;
    }

    getEttClass(): any {
        return ProductPrice;
    }

    getEttKey(): string {
        return "productPriceId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return ProductPriceService;
    }
}
