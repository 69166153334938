import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {AppMainComponent} from './app.main.component';
import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "../../customizes/view/base-component";
import {UserInfoService} from "../../services/user-info/user-info.service";
import {menu} from "../../../app-constant";
import {StringUtils} from "../../common/string-utils";


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent extends BaseComponent implements OnInit {
    results = [];
    selected: any;
    isShowDialogChangePwd: boolean;
    isSubmitted: boolean;
    oldPwd: string;
    newPwd: string;
    rePwd: string;

    constructor(
        public app: AppComponent,
        public appMain: AppMainComponent,
        protected router: Router,
        protected route: ActivatedRoute,
        private userInfoService: UserInfoService,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.executeHttpRequest(this.userInfoService.listAll(), result => {
                this.authService.setUserInfo(result);
            });
        }
    }

    showDialogChangePwd() {
        this.isShowDialogChangePwd = true;
    }

    changePwd(invalidForm: boolean) {
        this.isSubmitted = true;

        if (invalidForm === true) {
            this.messageService.showErrorViaToast("common.alert.pleaseCheckInputData");
            return;
        }
    }

    search(event) {
        const q = StringUtils.removeSign(event.query).toUpperCase().trim();
        this.results = [];

        for (const m of menu) {
            if (StringUtils.removeSign(m.name.toUpperCase()).includes(q)) {
                this.results.push(m);
            }
        }
    }

    select(value) {
        this.router.navigate([value.path]);
    }

    focus() {
        (document.getElementById('topbar-search') as HTMLInputElement).select();
    }
}
