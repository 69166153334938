<div class="layout-footer">
    <div>
        <img src="assets/layout/images/logo-whychain-removebg.png" alt="roma-layout"/>
        <p>WHYCHAIN CORPORATION</p>
    </div>
    <div class="footer-icons">
        <button class="p-link">
            <i class="pi pi-home"></i>
        </button>
        <button class="p-link">
            <i class="pi pi-globe"></i>
        </button>
        <button class="p-link">
            <i class="pi pi-envelope"></i>
        </button>
    </div>
</div>
