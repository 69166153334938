import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {RptTransService} from '../../services/report/rpt-trans.service';
import {BaseComponent} from '../../../_app-core/customizes/view/base-component';
import {getServerApiUrl} from '../../../app-constant';
import {ReportTransService} from './rpt-trans.service';

@Component({
  selector: 'app-rpt-trans',
  templateUrl: './rpt-trans.component.html'
})
export class RptTransComponent extends BaseComponent implements OnInit {

  listData: any[] = [];
  fromDate: Date;
  thruDate: Date;
  ownerPseudoId?: string;
  isSubmitted: boolean;

  displayReconciliation?: boolean;
  reconciliationFile: any;
  reconciliationTarget: any;
  reconciliationRowStart: any;

  displayEarn?: boolean;
  earnFile: any;
  earnTarget: any;
  earnRowStart: any;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected reportTransService: ReportTransService,
    protected service: RptTransService
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.fromDate = new Date();
    this.fromDate.setMonth(new Date().getMonth() - 3);
    this.thruDate = new Date();
  }

  search(isInvalidForm: boolean) {
    if (isInvalidForm) {
      return;
    }

    this.executeHttpRequest(this.service.listByDateRange(this.fromDate, this.thruDate, this.ownerPseudoId), result => {
      this.listData = result.listData;
    });
  }

  export() {
    window.open(getServerApiUrl() + '/' + this.service.getApiName() + '/excel?' +
      this.service.getParams(this.fromDate, this.thruDate, this.ownerPseudoId), '_blank');
  }

  showReconciliation() {
    this.reconciliationFile = null;
    if (this.reconciliationTarget) {
      this.reconciliationTarget.value = '';
    }
    this.reconciliationRowStart = null;
    this.displayReconciliation = true;
  }

  onUploadReconciliation(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.reconciliationFile = event.target.files[0];
    }
    this.reconciliationTarget = event.target;
  }

  downloadReconciliationTemp() {
    window.open(getServerApiUrl() + '/' + this.service.getApiName() + '/reconciliation/template');
  }

  uploadReconciliation() {
    if (this.reconciliationFile) {
      this.reportTransService.uploadReconciliation(this.reconciliationFile, this.reconciliationRowStart).subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.download = `response-doi-soat.xlsx`;
        link.click();
      });
    }
  }

  showEarn() {
    this.earnFile = null;
    if (this.earnTarget) {
      this.earnTarget.value = '';
    }
    this.earnRowStart = null;
    this.displayEarn = true;
  }

  onUploadEarn(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.earnFile = event.target.files[0];
    }
    this.earnTarget = event.target;
  }

  downloadEarnTemp() {
    window.open(getServerApiUrl() + '/iam-customer/customers/earn-excel/template');
  }

  uploadEarn() {
    if (this.earnFile) {
      this.reportTransService.uploadEarn(this.earnFile, this.earnRowStart).subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.download = `response-nap-diem.xlsx`;
        link.click();
      });
    }
  }

}
