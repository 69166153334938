import {Type} from "class-transformer";

export class Promotion {
    storePromotionId: string;
    pseudoId: string;
    itemDescription: string;
    includeAllProductStores: boolean;
    productStores: any[];
    deletedProductStores: string[];
    includeAllProducts: boolean;
    products: any[];
    deletedProducts: string[];
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    thruDate: Date;
    requireTimePeriod: boolean;
    fromTime: number;
    thruTime: number;
    onlyNewCustomer: boolean;
    onlyClassificationCustomer: boolean;
    partyClassifications: any[];
    deletedPartyClassifications: string[];
    requireMinOrderItemTotal: boolean;
    minOrderItemTotal: number;
    loyaltyPointsIncrease: boolean;
    loyaltyPointsIncreaseBy: string;
    loyaltyPointsIncreasePercent: number;
    loyaltyPointsIncreaseValue: number;
    includeGifts: boolean;
    gifts: any[];
    deletedGifts: string[];
}
