import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Utils} from "../../../common/utils";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'app-dialog-advance',
    templateUrl: './dialog-advance.component.html',
})
export class DialogAdvanceComponent {
    @Output() visibleChange = new EventEmitter<boolean>();
    @Input() form: NgForm;
    @Input() visible: boolean;
    @Input() isMaximizable = true;
    @Input() header: string;
    @Input() width = "65vw";
    @Input() msgs: any[] = [];
    @Input() onHide: () => void;

    onHideDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);

        if (!Utils.isNullOrUndefined(this.form)) {
            this.form.control.reset();
        }

        if (!Utils.isNullOrUndefined(this.onHide)) {
            this.onHide();
        }
    }
}
