import {Type} from "class-transformer";

export class Event {
    storePromotionId: string;
    pseudoId: string;
    itemDescription: string;
    includeAllProductStores: boolean;
    productStores: any[];
    deletedProductStores: string[];
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    thruDate: Date;
    loyaltyPointsIncreaseValue: number;
}
