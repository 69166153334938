import {Component, Input} from '@angular/core';
import {NgModel} from "@angular/forms";
import {AppMessageService} from "../../services/app-message.service";

@Component({
    selector: 'app-validator-message',
    templateUrl: './validator-message.component.html',
})
export class ValidatorMessageComponent {
    @Input() templateVar: NgModel;
    @Input() isSubmitted: boolean;
    @Input() isShortMsg: boolean;

    constructor(private appMessageService: AppMessageService) {
    }

    getSuffix() {
        return this.isShortMsg ? "_short" : "";
    }

    getRequiredMsg(): string {
        return this.appMessageService.translate(
            "common.validate.required" + this.getSuffix(),
            this.templateVar.errors?.minlength?.requiredLength
        );
    }

    getMinLengthMsg(): string {
        return this.appMessageService.translate(
            "common.validate.minLength" + this.getSuffix(),
            this.templateVar.errors?.minlength?.requiredLength
        );
    }

    getMaxLengthMsg(): string {
        return this.appMessageService.translate(
            "common.validate.maxLength" + this.getSuffix(),
            this.templateVar.errors?.minlength?.requiredLength
        );
    }

    getEmailMsg(): string {
        return this.appMessageService.translate("common.validate.email" + this.getSuffix());
    }

    getMaskMsg(): string {
        return this.appMessageService.translate("common.validate.mask");
    }
}
