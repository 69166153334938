<div *ngIf="isLov" style="width: 500px">
    <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
             [totalRecords]="tableData.totalRecords" [rows]="5" [paginator]="true" [showCurrentPageReport]="true"
             [rowHover]="true" dataKey="productStoreId" (onFilter)="onTableFilter($event)"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="productStoreCode">
                    {{"label.mngProductStore.productStoreCode" | translate}}
                    <p-sortIcon field="productStoreCode"></p-sortIcon>
                </th>
                <th pSortableColumn="storeName">
                    {{"label.mngProductStore.storeName" | translate}}
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="productStoreCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="storeName" matchMode="contains"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-productStore>
            <tr>
                <td>
                    <p-button label="{{productStore.productStoreCode}}" styleClass="p-button-link p-0"
                              (onClick)="onChooseLovItem(productStore)"></p-button>
                </td>
                <td>
                    <p-button label="{{productStore.storeName}}" styleClass="p-button-link p-0"
                              (onClick)="onChooseLovItem(productStore)"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-telsoft *ngIf="!isLov" [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="productStoreId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngProductStore.listProductStore" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="productStoreCode">
                        {{"label.mngProductStore.productStoreCode" | translate}}
                        <p-sortIcon field="productStoreCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="storeName">
                        {{"label.mngProductStore.storeName" | translate}}
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="toName">
                        {{"label.mngProductStore.toName" | translate}}
                        <p-sortIcon field="toName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="organizationName">
                        {{"label.mngProductStore.organizationName" | translate}}
                        <p-sortIcon field="organizationName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="city">
                        {{"label.mngProductStore.city" | translate}}
                        <p-sortIcon field="city"></p-sortIcon>
                    </th>
                    <th pSortableColumn="address1">
                        {{"label.mngProductStore.address1" | translate}}
                        <p-sortIcon field="address1"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="productStoreCode" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="storeName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="toName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="city" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="address1" matchMode="contains"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-productStore>
                <tr class="data-row" showDelay="700" pTooltip="{{productStore.productStoreCode}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="productStore"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(productStore.productStoreCode)">
                        <div class="row-control">
                            <app-table-control [entity]="productStore" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete"></app-table-control>
                        </div>
                        <div class="row-title">{{productStore.productStoreCode}}</div>
                    </td>
                    <td>
                        {{productStore.storeName}}
                    </td>
                    <td>
                        {{productStore.toName}}
                    </td>
                    <td>
                        {{productStore.organizationName}}
                    </td>
                    <td>
                        {{productStore.city}}
                    </td>
                    <td>
                        {{productStore.address1}}
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="row mt-2 mb-4">
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtProductStoreCode" value="label.mngProductStore.productStoreCode"
                                   required="true"></app-label>
                        <input id="txtProductStoreCode" name="txtProductStoreCode" type="text" pInputText
                               [(ngModel)]="selectedEtt.productStoreCode" [disabled]="isDisable"
                               #txtProductStoreCode="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtProductStoreCode"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtName" value="label.mngProductStore.storeName" required="true"></app-label>
                        <input id="txtName" name="txtName" type="text" pInputText
                               [(ngModel)]="selectedEtt.storeName" [disabled]="isDisable"
                               #txtName="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtToName" value="label.mngProductStore.toName" required="true"></app-label>
                        <input id="txtToName" name="txtToName" type="text" pInputText
                               [(ngModel)]="selectedEtt.toName" [disabled]="isDisable"
                               #txtToName="ngModel" required maxlength="20"/>
                        <app-validator-message [templateVar]="txtToName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtCity" value="label.mngProductStore.city"></app-label>
                        <input id="txtCity" name="txtCity" type="text" pInputText
                               [(ngModel)]="selectedEtt.city" [disabled]="isDisable"
                               #txtCity="ngModel" maxlength="20"/>
                        <app-validator-message [templateVar]="txtCity"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtAddress1" value="label.mngProductStore.address1"></app-label>
                        <input id="txtAddress1" name="txtAddress1" type="text" pInputText
                               [(ngModel)]="selectedEtt.address1" [disabled]="isDisable"
                               #txtAddress1="ngModel" maxlength="200"/>
                        <app-validator-message [templateVar]="txtAddress1"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
            </div>

            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>
</app-telsoft>
