<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>
<p-card>
    <p-table #dt [value]="listData" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" 
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="organizationCode">
                    {{"label.rptOrgInfo.organizationCode" | translate}}
                    <p-sortIcon field="organizationCode"></p-sortIcon>
                </th>
                <th pSortableColumn="organizationName">
                    {{"label.rptOrgInfo.organizationName" | translate}}
                    <p-sortIcon field="organizationName"></p-sortIcon>
                </th>
                <th pSortableColumn="productStoreCount">
                    {{"label.rptOrgInfo.productStoreCount" | translate}}
                    <p-sortIcon field="productStoreCount"></p-sortIcon>
                </th>
                <th pSortableColumn="adminCount">
                    {{"label.rptOrgInfo.adminCount" | translate}}
                    <p-sortIcon field="adminCount"></p-sortIcon>
                </th>
                <th pSortableColumn="employeeCount">
                    {{"label.rptOrgInfo.employeeCount" | translate}}
                    <p-sortIcon field="employeeCount"></p-sortIcon>
                </th>
                <th pSortableColumn="productCount">
                    {{"label.rptOrgInfo.productCount" | translate}}
                    <p-sortIcon field="productCount"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="organizationCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="productStoreCount" ></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="adminCount" ></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="employeeCount" ></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="productCount" ></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-org>
            <tr>
                <td>
                    {{org.organizationCode}}
                </td>
                <td>
                    {{org.organizationName}}
                </td>
                <td>
                    {{org.productStoreCount || 0 | number}}
                </td>
                <td>
                    {{org.adminCount || 0 | number}}
                </td>
                <td>
                    {{org.employeeCount || 0 | number}}
                </td>
                <td>
                    {{org.productCount || 0 | number}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
