<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>
<app-general-toolbar [preCreate]="preCreate" [preDeleteMany]="preDeleteMany" [isAllowDeleteMany]="isAllowDeleteMany()">
    <button *appPermissions="['U']" pButton label="{{'label.mngTokenCode.useCode'|translate}}" type="button"
            (click)="op.toggle($event)" class="p-button-info p-ml-2" pRipple icon="fas fa-donate"></button>
    <p-overlayPanel #op (onHide)="preApplyCode()">
        <ng-template pTemplate>
            <div class="p-fluid" style="width: 400px">
                <p-messages [value]="otherMessage1.msgs"></p-messages>

                <div class="row">
                    <div class="col-12">
                        <div class="p-field">
                            <app-label for="txtConvertCode" value="label.mngTokenCode.code" required="true"></app-label>
                            <input type="text" pInputText [(ngModel)]="tokenCode" #txtUseCode="ngModel" required
                                   maxlength="20"/>
                            <app-validator-message [templateVar]="txtUseCode"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="p-field">
                            <app-label for="txtUsePassword" value="label.mngTokenCode.password"></app-label>
                            <input type="text" pInputText [(ngModel)]="password" #txtUsePassword="ngModel"/>
                            <app-validator-message [templateVar]="txtUsePassword"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12">
                        <button pButton pRipple label="{{'common.button.agree'|translate}}"
                                (click)="useCode()" icon="{{'common.button.icon.save'|translate}}"
                                class="p-button-primary"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>

    <button *appPermissions="['U']" pButton label="{{'label.mngTokenCode.convertPoint'|translate}}" type="button"
            (click)="op1.toggle($event)" class="p-button-info p-ml-2" pRipple icon="fas fa-exchange-alt"></button>
    <p-overlayPanel #op1 (onHide)="preApplyCode()">
        <ng-template pTemplate>
            <div class="p-fluid" style="width: 400px">
                <p-messages [value]="otherMessage1.msgs"></p-messages>

                <div class="row">
                    <div class="col-12">
                        <div class="p-field">
                            <app-label for="txtConvertCode" value="label.mngTokenCode.code" required="true"></app-label>
                            <input type="text" pInputText [(ngModel)]="tokenCode" #txtConvertCode="ngModel" required
                                   maxlength="20"/>
                            <app-validator-message [templateVar]="txtConvertCode"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="p-field">
                            <app-label for="txtConvertCust" value="label.mngTokenCode.customer"
                                       required="true"></app-label>
                            <input type="text" pInputText [(ngModel)]="customerCode" #txtConvertCust="ngModel" required
                                   maxlength="20"/>
                            <app-validator-message [templateVar]="txtConvertCust"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12">
                        <button pButton pRipple label="{{'common.button.agree'|translate}}"
                                (click)="convertCode()" icon="{{'common.button.icon.save'|translate}}"
                                class="p-button-primary w-100"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>

</app-general-toolbar>

<div class="card">
    <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
             [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
             [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
             [rowHover]="true" dataKey="tokenCodeId" (onFilter)="onTableFilter($event)"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="caption">
            <div class="d-flex flex-row-reverse table-header">
                <p-multiSelect [options]="hiddenCols" [(ngModel)]="selectedColumns"
                               optionLabel="header" [style]="{width: '200px'}"
                               placeholder="{{'common.datatable.chooseColumns'|translate}}"></p-multiSelect>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem"></th>
                <th pSortableColumn="code">
                    {{"label.mngTokenCode.code" | translate}}
                    <p-sortIcon field="code"></p-sortIcon>
                </th>
                <th pSortableColumn="createDate">
                    {{"label.mngTokenCode.createDate" | translate}}
                    <p-sortIcon field="createDate"></p-sortIcon>
                </th>
                <th pSortableColumn="useDate">
                    {{"label.mngTokenCode.useDate" | translate}}
                    <p-sortIcon field="useDate"></p-sortIcon>
                </th>
                <th pSortableColumn="amount">
                    {{"label.mngTokenCode.amount" | translate}}
                    <p-sortIcon field="hash"></p-sortIcon>
                </th>
                <th pSortableColumn="customerCode">
                    {{"label.mngTokenCode.customerCode" | translate}}
                    <p-sortIcon field="customerCode"></p-sortIcon>
                </th>
                <th pSortableColumn="status">
                    {{"label.mngTokenCode.status" | translate}}
                    <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th pSortableColumn="createBy" *ngIf="isVisibleCol('createBy')">
                    {{"label.mngTokenCode.createBy" | translate}}
                    <p-sortIcon field="createBy"></p-sortIcon>
                </th>
                <th pSortableColumn="useBy" *ngIf="isVisibleCol('useBy')">
                    {{"label.mngTokenCode.useBy" | translate}}
                    <p-sortIcon field="useBy"></p-sortIcon>
                </th>
                <th pSortableColumn="receptionBy" *ngIf="isVisibleCol('receptionBy')">
                    {{"label.mngTokenCode.receptionBy" | translate}}
                    <p-sortIcon field="receptionBy"></p-sortIcon>
                </th>
                <th pSortableColumn="receptionOrder" *ngIf="isVisibleCol('receptionOrder')">
                    {{"label.mngTokenCode.receptionOrder" | translate}}
                    <p-sortIcon field="receptionOrder"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>
                    <p-columnFilter type="text" field="code" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="createDate" matchMode="dateIs">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-calendar (onSelect)="filter($event)" [monthNavigator]="true" [yearNavigator]="true"
                                        [showButtonBar]="true" [yearRange]="getYearRange()"
                                        appendTo="body"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="useDate" matchMode="dateIs">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-calendar (onSelect)="filter($event)" [monthNavigator]="true" [yearNavigator]="true"
                                        [showButtonBar]="true" [yearRange]="getYearRange()"
                                        appendTo="body"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="amount"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="customerCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="status" matchMode="equals">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown showClear="true" [ngModel]="value" [options]="comboboxTokenStatus"
                                        optionLabel="label"
                                        optionValue="value" placeholder="{{'common.label.cbChoose'|translate}}"
                                        (onChange)="filter($event.value)">
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th *ngIf="isVisibleCol('createBy')">
                    <p-columnFilter type="text" field="createBy" matchMode="contains"></p-columnFilter>
                </th>
                <th *ngIf="isVisibleCol('useBy')">
                    <p-columnFilter type="text" field="useBy" matchMode="contains"></p-columnFilter>
                </th>
                <th *ngIf="isVisibleCol('receptionBy')">
                    <p-columnFilter type="text" field="receptionBy" matchMode="contains"></p-columnFilter>
                </th>
                <th *ngIf="isVisibleCol('receptionOrder')">
                    <p-columnFilter type="text" field="receptionOrder" matchMode="contains"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tokenCode>
            <tr class="data-row" showDelay="700" pTooltip="{{tokenCode.tokenCodeId}}" tooltipPosition="top">
                <td>
                    <p-tableCheckbox [value]="tokenCode"></p-tableCheckbox>
                </td>
                <td (dblclick)="copyMessage(tokenCode.code)">
                    <div class="row-control">
                        <app-table-control [entity]="tokenCode" [preView]="preView" [preCopy]="preCopy"
                                           [preUpdate]="preUpdate" [preDelete]="preDelete"></app-table-control>
                    </div>
                    <div class="row-title">{{tokenCode.code}}</div>
                </td>
                <td>
                    {{tokenCode.createDate | date : getDateTimeFormat()}}
                </td>
                <td>
                    {{tokenCode.useDate | date : getDateTimeFormat()}}
                </td>
                <td>
                    {{tokenCode.amount}}
                </td>
                <td>
                    {{tokenCode.customerCode}}
                </td>
                <td>
                    <span *ngIf="tokenCode.status == '2'"
                          [class]="'status-badge status-info'">{{"label.mngTokenCode.status.new" | translate}}</span>
                    <span *ngIf="tokenCode.status == '1'"
                          [class]="'status-badge status-success'">{{"label.mngTokenCode.status.used" | translate}}</span>
                    <span *ngIf="tokenCode.status == '0'"
                          [class]="'status-badge status-danger'">{{"label.mngTokenCode.status.disable" | translate}}</span>
                </td>
                <td *ngIf="isVisibleCol('createBy')">
                    {{tokenCode.createBy}}
                </td>
                <td *ngIf="isVisibleCol('useBy')">
                    {{tokenCode.useBy}}
                </td>
                <td *ngIf="isVisibleCol('receptionBy')">
                    {{tokenCode.receptionBy}}
                </td>
                <td *ngIf="isVisibleCol('receptionOrder')">
                    {{tokenCode.receptionOrder}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td [colSpan]="11 - unSelectedColumnCount">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-dialog [parentComponent]="this" [form]="simpleForm">
    <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
        <div class="row mt-2 mb-4">
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtCode" value="label.mngTokenCode.code" required="true"></app-label>
                    <input id="txtCode" name="txtCode" type="text" pInputText
                           [(ngModel)]="selectedEtt.code" [disabled]="true"
                           #txtCode="ngModel" required maxlength="20"/>
                    <app-validator-message [templateVar]="txtCode"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtHash" value="label.mngTokenCode.hash" required="true"></app-label>
                    <input id="txtHash" name="txtHash" type="text" pInputText
                           [(ngModel)]="selectedEtt.hash" [disabled]="true"
                           #txtHash="ngModel" required maxlength="200"/>
                    <app-validator-message [templateVar]="txtHash"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtHash" value="label.mngTokenCode.customerCode" required="true"></app-label>
                    <input id="txtcustomerCode" name="txtcustomerCode" type="text" pInputText
                           [(ngModel)]="selectedEtt.customerCode" [disabled]="isDisable || isUpdate"
                           #txtcustomerCode="ngModel" required maxlength="200" style="text-transform: uppercase"/>
                    <app-validator-message [templateVar]="txtcustomerCode"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtCode" value="label.mngTokenCode.amount" required="true"></app-label>
                    <input id="txtAmount" name="txtAmount" type="text" pInputText
                           [(ngModel)]="selectedEtt.amount" [disabled]="isDisable || isUpdate"
                           #txtAmount="ngModel" required maxlength="20"/>
                    <app-validator-message [templateVar]="txtAmount"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtPassword" value="label.mngTokenCode.password"></app-label>
                    <input id="txtPassword" name="txtPassword" type="text" pInputText
                           [(ngModel)]="selectedEtt.password"
                           #txtPassword="ngModel" maxlength="200"/>
                    <app-validator-message [templateVar]="txtPassword"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="cbStatus" value="label.mngTokenCode.status" required="true"></app-label>
                    <p-dropdown showClear="true" id="cbStatus" name="cbStatus"
                                [(ngModel)]="selectedEtt.status"
                                [options]="comboboxTokenStatus" optionLabel="label" optionValue="value"
                                [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                [disabled]="isDisable || isCreate" required #cbStatus="ngModel"></p-dropdown>
                    <app-validator-message [templateVar]="cbStatus"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtCrateDate" value="label.mngTokenCode.createDate"></app-label>
                    <p-calendar id="txtCrateDate" name="txtCrateDate" appendTo="body"
                                [(ngModel)]="selectedEtt.createDate"
                                #txtCrateDate="ngModel" monthNavigator="true" yearNavigator="true"
                                [yearRange]="getYearRange()" [showTime]="true" required
                                [showSeconds]="true" showButtonBar="true"
                                [disabled]="true || isUpdate"></p-calendar>
                    <app-validator-message [templateVar]="txtCrateDate"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtUpdateDate" value="label.mngTokenCode.updateDate"></app-label>
                    <p-calendar id="txtUpdateDate" name="txtUpdateDate" appendTo="body"
                                [(ngModel)]="selectedEtt.lastUpdatedStamp"
                                #txtUpdateDate="ngModel" monthNavigator="true" yearNavigator="true"
                                [yearRange]="getYearRange()" [showTime]="true" required
                                [showSeconds]="true" showButtonBar="true"
                                [disabled]="true"></p-calendar>
                    <app-validator-message [templateVar]="txtUpdateDate"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
        </div>

        <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                class="{{'common.button.class.dialog.save'|translate}}"></button>
    </form>
</app-dialog>
