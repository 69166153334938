import {environment} from "../environments/environment";
import {AmObject} from "./_app-core/models/am-object/am-object";
import {AppRoutingUrl} from "./app-routing-url";

export const API_MAX_RESULT = 1000000;
export const SERVER_API_URL_DEV = 'https://whychain.net/rest/s1';
export const SERVER_API_URL = 'https://whychain.net/rest/s1';

export const ROOT_ADMIN = 'admin.miraway';

export const getServerApiUrl = (): string => {
    return environment.production ? SERVER_API_URL : SERVER_API_URL_DEV;
};

export const sortType: any = {
    ASC: 1,
    DESC: -1
};

export const globalVar: any = {
    AUTHORIZATION: null,
};

export const config: any = {
    JSON_DATA_PATH: 'assets/data',
};

export const apiName: any = {
    ORGANIZATION_STATUS: 'iam-common/organizationStatuses',
    ORGANIZATION_PACKAGE: 'iam-common/organizationPackages',
    AVAILABLE_ACCOUNT: 'iam-common/availableAccounts',
    UOM: 'iam-common/uoms',
    ROLE_TYPE_EMPLOYEE: 'iam-common/role-type-employee',
    LANGUAGE: 'languages',
    USER_INFO: 'iam-common/userInfo',
    TOKEN_CODE: 'iam-tokenCode/tokenCodes',
    REDEEM_TOKEN_CODE: 'iam-customer/customers/redeemTokenCode',
    CONVERT_TOKEN_CODE: 'iam-customer/customers/redeemTokenToPoint',
    CUSTOMER: 'iam-customer/customers',
    SALE_POINT: 'iam-productStore/productStores',
    TOKEN_TRANSACTION: 'iam-customer/tokenTransactions',
    PRODUCT: 'iam-product/products',
    ORGANIZATION: 'iam-organization/organizations',
    USER_ACCOUNT: 'iam-admin/privileges/user-accounts',
    PROMOTION: 'iam-promotion/promotions',
    PROMOTION_STATUS: 'iam-promotion/promotions/statuses',
    AWARD: 'iam-award/awards',
    AWARD_STATUS: 'iam-award/awards/statuses',
    EVENT: 'iam-event/events',
    EVENT_STATUS: 'iam-event/events/statuses',
    AWARD_CLASSIFICATION: 'iam-party-classification/classifications',
    RPT_ORG_STATUS: 'iam-report/orgStatus',
    RPT_ORG_INFO: 'iam-report/orgInfo',
    RPT_LOYALTY_INFO: 'iam-report/loyaltyStatus',
    RPT_PARTNER_GROWTH: 'iam-report/dashboard/partner-period-growth',
    RPT_PAYMENT_TRANS_SUMMARY: 'iam-report/dashboard/payment-trans-summary',
    RPT_USING_VOUCHER_CHART: 'iam-report/dashboard/using-voucher-chart',
    RPT_PARTNER_ACTIVE: 'iam-report/dashboard/partner-active',
    RPT_EXPIRATION_PARTNER: 'iam-report/dashboard/expiration-partner',
    RPT_TOTAL_CUSTOMER_EARN: 'iam-report/dashboard/total-customer-earn',
    RPT_TOTAL_CUSTOMER_REDEEM: 'iam-report/dashboard/total-customer-redeem',
    RPT_CUSTOMER: 'iam-report/new-customers',
    RPT_TRANS: 'iam-payment-trans/histories',
};

export const storageKey: any = {
    APP_VERSION: 'appVersion',
    USER: 'user',
    USER_INFO: 'userInfo',
    TOKEN: 'token',
    MENU: 'menu',
    REFERER: 'referer',
    LOCALE: 'locale',
    LANGUAGES: 'languages',
    AUTHORIZATION: 'authorization',
};

export const menu = [
    new AmObject(1, 0, "G", "Hệ thống", "#", "fa fa-cog"),
    new AmObject(10, 1, "M", "QL Tài khoản NSD", AppRoutingUrl.module.mngAccount, "fas fa-user-cog"),
    new AmObject(11, 1, "M", "QL Đối tác", AppRoutingUrl.module.mngOrganization, "fas fa-handshake"),
    new AmObject(12, 1, "M", "QL Token Code", AppRoutingUrl.module.mngTokenCode, "fas fa-gift"),
    new AmObject(13, 1, "M", "QL Chương trình", AppRoutingUrl.module.mngPromotion, "fas fa-tags"),
    new AmObject(14, 1, "M", "QL Giải thưởng", AppRoutingUrl.module.mngAward, "fas fa-award"),
    new AmObject(14, 1, "M", "QL Tặng điểm sự kiện", AppRoutingUrl.module.mngEvent, "fas fa-map-marker-alt"),

    new AmObject(2, 0, "G", "Bán hàng", "#", "fas fa-shopping-bag"),
    new AmObject(20, 2, "M", "QL Khách hàng", AppRoutingUrl.module.mngCustomer, "fas fa-user"),
    new AmObject(21, 2, "M", "QL Điểm bán", AppRoutingUrl.module.mngProductStore, "fas fa-store"),
    new AmObject(22, 2, "M", "QL Sản phẩm", AppRoutingUrl.module.mngProduct, "fas fa-hamburger"),
    new AmObject(23, 2, "M", "QL Hạng khách hàng", AppRoutingUrl.module.mngClassification, "fab fa-odnoklassniki"),

    new AmObject(3, 0, "G", "Báo cáo", "#", "fa fa-table"),
    new AmObject(30, 3, "M", "BC Trạng thái đối tác", AppRoutingUrl.module.rptOrgStatus, "fas fa-handshake"),
    new AmObject(31, 3, "M", "BC Thông tin đối tác", AppRoutingUrl.module.rptOrgInfo, "fas fa-info-circle"),
    new AmObject(32, 3, "M", "BC Tình trạng Loyalty", AppRoutingUrl.module.rptLoyaltyInfo, "fas fa-money-check-alt"),
    new AmObject(33, 3, "M", "BC Khách hàng mới", AppRoutingUrl.module.rptCustomer, "fas fa-users"),
    new AmObject(34, 3, "M", "Lịch sử giao dịch", AppRoutingUrl.module.rptTrans, "fas fa-history")
];

