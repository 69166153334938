<div style="width: 500px">
    <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
             [totalRecords]="tableData.totalRecords" [rows]="5" [paginator]="true" [showCurrentPageReport]="true"
             [rowHover]="true" dataKey="username" (onFilter)="onTableFilter($event)"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="username">
                    {{"label.account.username" | translate}}
                    <p-sortIcon field="username"></p-sortIcon>
                </th>
                <th pSortableColumn="userFullName">
                    {{"label.account.userFullName" | translate}}
                    <p-sortIcon field="userFullName"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="username" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="userFullName" matchMode="contains"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-account>
            <tr>
                <td>
                    <p-button label="{{account.username}}" styleClass="p-button-link p-0"
                              (onClick)="onChoose(account)"></p-button>
                </td>
                <td>
                    <p-button label="{{account.userFullName}}" styleClass="p-button-link p-0"
                              (onClick)="onChoose(account)"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
