export class AppRoutingUrl {

    public static common: any = {
        error: 'error',
        accessdenied: 'accessdenied',
        notFound: '404',
        login: 'login',
    };

    public static module: any = {
        mngTokenCode: "module/mng-token-code",
        mngCustomer: "module/mng-customer",
        mngProductStore: "module/mng-product-store",
        mngProduct: "module/mng-product",
        mngOrganization: "module/mng-organization",
        mngAccount: "module/mng-account",
        mngPromotion: "module/mng-promotion",
        mngAward: "module/mng-award",
        mngEvent: "module/mng-event",
        mngClassification: "module/mng-classification",
        rptOrgStatus: "module/rpt-organization-status",
        rptOrgInfo: "module/rpt-organization-info",
        rptLoyaltyInfo: "module/rpt-loyalty-status",
        rptCustomer: "module/rpt-customer",
        rptTrans: "module/rpt-trans"
    };
}
