export class ProductPrice {
    productPriceId: string;
    productId: string;
    minQuantity: number;
    price: number;
    currency: string;
    lastUpdatedStamp: number;
    organizationId: string;
    storeName: string;
    productStoreId: string;

    getEttName() {
        return this.price;
    }
}
