import {Component, OnInit, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductService} from "../../services/product/product.service";
import {UomService} from "../../services/common/uom.service";
import {Uom} from "../../models/common/uom";
import {Product} from "../../models/product/product";

@Component({
    selector: 'app-mng-product',
    templateUrl: './mng-product.component.html',
    styleUrls: ['./mng-product.component.scss']
})
export class MngProductComponent extends SimpleCrudLazyComponent implements OnInit {
    uomType = "UT_CURRENCY_MEASURE,UT_TIME_FREQ_MEASURE";

    @ViewChild('dt') table: Table;
    selectedEtt: Product;
    selectedProductId: string;
    listUom: Uom[] = [];
    listType: any[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private uomService: UomService
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.listType = [
            {label: this.messageService.translate("label.mngProduct.asset"), value: 'PtAsset'},
            {label: this.messageService.translate("label.mngProduct.service"), value: 'PtService'},
        ];

        this.executeHttpRequest(this.uomService.getByType(this.uomType), result => {
            this.listUom = result;
            this.listUom.forEach(item => item.name = `${item.abbreviation} - ${item.description}`);
        }, Uom);
    }

    afterPreAction() {
        this.selectedProductId = this.selectedEtt.productId;
        super.afterPreAction();
    }

    getEttClass(): any {
        return Product;
    }

    getEttKey(): string {
        return "productId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return ProductService;
    }
}
