import {Type} from "class-transformer";

/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

export class ApDomainNumber {
    type: string;
    @Type(() => Number)
    code: number;
    name: string;
    value: string;
    status: string;
    reasonType: string;
    groupCode: string;
    description: string;
    orderNo: number;
    id: number;
}
