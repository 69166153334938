import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductStore} from "../../models/product-store/product-store";
import {ProductStoreService} from "../../services/product-store/product-store.service";

@Component({
    selector: 'app-mng-product-store',
    templateUrl: './mng-product-store.component.html',
    styleUrls: ['./mng-product-store.component.scss']
})
export class MngProductStoreComponent extends SimpleCrudLazyComponent implements OnInit {
    @ViewChild('dt') table: Table;
    @Output() selectedLovChange = new EventEmitter<any>();
    @Input() isLov: boolean;
    selectedEtt: ProductStore;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

    onChooseLovItem(store: ProductStore) {
        this.selectedLovChange.emit(store);
    }

    getEttClass(): any {
        return ProductStore;
    }

    getEttKey(): string {
        return "productStoreId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return ProductStoreService;
    }
}
