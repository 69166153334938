import {Injectable} from "@angular/core";

/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

@Injectable({
    providedIn: 'root'
})
export class DateUtils {
    static PATTERN_ISO8601 = "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$";

    static isDateString(str): boolean {
        const regex = new RegExp(this.PATTERN_ISO8601);
        return regex.test(str);
    }

    static getTimeZone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static getRangeThisDay(): any[] {
        const date = new Date();
        const d = date.getDate();
        const y = date.getFullYear();
        const m = date.getMonth();

        const firstDay = new Date(y, m, d);
        const lastDay = new Date(y, m, d, 23, 59, 59);

        return [firstDay.toISOString(), lastDay.toISOString()];
    }

    static getRangeThisMonth(): any[] {
        const date = new Date();
        const d = date.getDay();
        const y = date.getFullYear();
        const m = date.getMonth();

        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        return [firstDay.toISOString(), lastDay.toISOString()];
    }

    static getRangeThisWeek(): any[] {
        const curr = new Date(); // get current date
        const first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        const last = first + 6; // last day is the first day + 6

        const firstDay = new Date(curr.setDate(first));
        const lastDay = new Date(curr.setDate(last));

        return [firstDay.toISOString(), lastDay.toISOString()];
    }
}
