/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 31/08/2021.
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Table} from 'primeng/table/table';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Account} from "../../models/account/account";
import {UserAccountService} from "../../services/user-account/user-account.service";
import {Organization} from "../../models/organization/organization";
import {RoleTypeEmployeeService} from "../../services/common/role-type-employee.service";
import {Utils} from "../../../_app-core/common/utils";
import {AlertMessages} from "../../../_app-core/models/messages/alertMessages";

@Component({
    selector: 'app-mng-account',
    templateUrl: './mng-account.component.html',
    styleUrls: ['./mng-account.component.scss']
})
export class MngAccountComponent extends SimpleCrudLazyComponent implements AfterViewInit, OnInit {
    @ViewChild('dt') table: Table;
    selectedEtt = new Account();
    listRoleType = [];
    isShowPermsDialog = false;
    permsGrant: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private roleTypeEmployeeService: RoleTypeEmployeeService
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.executeHttpRequest(this.roleTypeEmployeeService.listAll(), result => {
            this.listRoleType = result.listData;
        });
    }

    viewPermission(ett: Account) {
        this.permsGrant = null;
        this.selectedEtt = ett;

        this.executeHttpRequest(this.crudService.getPermsGrant(ett.userId), result => {
            this.permsGrant = result;
            this.isShowPermsDialog = true;
        });
    }

    findNormalPerm(permissions, type) {
        if (!Utils.isNullOrUndefined(permissions) && Array.isArray(permissions)) {
            for (const p of permissions) {
                if (p.permissionType === type) {
                    return p;
                }
            }
        }

        return {};
    }

    checkAllNormal(checked) {
        for (const pg of this.permsGrant.normalPerms) {
            for (const p of pg.permissions) {
                p.granted = checked;
            }
        }
    }

    checkAllSpecial(checked) {
        for (const pg of this.permsGrant.specialPerms) {
            pg.granted = checked;
        }
    }

    savePerms() {
        // Build payload
        const userGroups = [];
        for (const pg of this.permsGrant.normalPerms) {
            for (const p of pg.permissions) {
                userGroups.push(p);
            }
        }
        for (const pg of this.permsGrant.specialPerms) {
            userGroups.push(pg);
        }

        // Clear old msg
        this.otherMessage1 = new AlertMessages();

        // Save
        const payload = {"userGroups": userGroups};
        this.executeHttpRequest(this.crudService.savePermsGrant(this.selectedEtt.userId, payload), result => {
            this.messageService.toastUpdateSuccess();
            this.isShowPermsDialog = false;
        }, null, this.otherMessage1);
    }

    clearCurrentOrg() {
        this.selectedEtt.organizationName = undefined;
        this.selectedEtt.organizationId = undefined;
    }

    onChooseOrg(org: Organization) {
        this.selectedEtt.organizationName = org.organizationName;
        this.selectedEtt.organizationId = org.organizationId;
    }

    afterPreAction() {
        if (this.isCreate || this.isCopy) {
            this.selectedEtt.disabled = "N";
        }

        super.afterPreAction();
    }

    ngAfterViewInit(): void {
    }

    getServiceClass() {
        return UserAccountService;
    }

    getEttClass(): any {
        return Account;
    }

    getTableControl(): any {
        return this.table;
    }

    getEttKey(): string {
        return "userId";
    }
}
