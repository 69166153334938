import {Type} from "class-transformer";

export class TokenCode {
    tokenCodeId: string;
    code: string;
    hash: string;
    password: string;
    @Type(() => Date)
    createDate: Date;
    createBy: string;
    @Type(() => Date)
    useDate: Date;
    useBy: string;
    receptionBy: string;
    receptionOrder: string;
    amount: number;
    status: string;
    customerCode: string;
    @Type(() => Date)
    lastUpdatedStamp: Date;

    getEttName() {
        return this.code;
    }
}
