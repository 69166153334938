/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AuthService} from './services/auth/auth.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        HttpClientModule
    ],
    declarations: [],
    providers: [
        AuthService
    ]
})

export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        ThrowIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}

export function ThrowIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
        throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
    }
}
