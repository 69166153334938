import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-general-messages',
    templateUrl: './general-messages.component.html',
})
export class GeneralMessagesComponent implements OnInit {
    @Input() alertMessages: () => void;
    @Input() msgs: [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
