import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";

@Injectable({
    providedIn: 'root'
})
export class EventService extends AbstractCrudService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.EVENT;
    }

    getDefaultSortField(): string {
        return "fromDate";
    }

    approve(id: string) {
        return this.put(`${id}/approve`, {}, this.getBaseUrl());
    }

}
