import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiName, sortType} from "../../../app-constant";
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";

@Injectable({
    providedIn: 'root'
})
export class AwardStatusService extends AbstractCrudService {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.AWARD_STATUS;
    }

    getDefaultSortField(): string {
        return "";
    }

    getDefaultSortType(): string {
        return sortType.ASC;
    }
}
