export class AmObject {
    objectId: number;
    parentId: number;
    objectType: string;
    name: string;
    description: string;
    status: number;
    ord: number;
    path: string;
    isRender: string;
    icon: string;


    constructor(objectId: number, parentId: number, objectType: string, name: string, path: string, icon: string) {
        this.objectId = objectId;
        this.parentId = parentId;
        this.objectType = objectType;
        this.name = name;
        this.path = path;
        this.icon = icon;
        this.isRender = "1";
        this.status = 1;
    }
}
