/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 31/08/2021.
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TokenCodeService} from "../../services/token-code/token-code.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Table} from 'primeng/table/table';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {TokenCode} from "../../models/token-code/token-code";
import {StringUtils} from "../../../_app-core/common/string-utils";

@Component({
    selector: 'app-mng-token-code',
    templateUrl: './mng-token-code.component.html',
    styleUrls: ['./mng-token-code.component.scss']
})
export class MngTokenCodeComponent extends SimpleCrudLazyComponent implements AfterViewInit, OnInit {
    @ViewChild('dt') table: Table;
    selectedEtt = new TokenCode();
    comboboxTokenStatus: any[] = [];
    tokenCode: string;
    password: string;
    customerCode: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.hiddenCols = this.buildTableColumns("createBy,useBy,receptionBy,receptionOrder", "label.mngTokenCode");

        this.comboboxTokenStatus = [
            {label: this.messageService.translate("label.mngTokenCode.status.new"), value: "2"},
            {label: this.messageService.translate("label.mngTokenCode.status.used"), value: "1"},
            {label: this.messageService.translate("label.mngTokenCode.status.disable"), value: "0"},
        ];
    }

    afterPreAction() {
        if (this.isCreate || this.isCopy) {
            const now = new Date();
            this.selectedEtt.createDate = now;
            this.selectedEtt.status = "2";
        }

        super.afterPreAction();
    }

    preApplyCode() {
        this.tokenCode = null;
        this.password = null;
        this.isSubmitted = false;
        this.otherMessage1.msgs = [];
    }

    useCode() {
        this.isSubmitted = true;

        if (!StringUtils.isNullOrEmpty(this.tokenCode)) {
            this.executeHttpRequest(this.crudService.useCode(this.tokenCode, this.password, "Test redeem from Web Admin"), result => {
                if (result.status === "SUCCESS") {
                    this.messageService.toastUpdateSuccess();
                }
            }, null, this.otherMessage1);
        }
    }

    convertCode() {
        this.isSubmitted = true;

        if (!StringUtils.isNullOrEmpty(this.tokenCode)) {
            // tslint:disable-next-line:max-line-length
            this.executeHttpRequest(this.crudService.convertCode(this.tokenCode, this.customerCode, "Test convert from Web Admin"), result => {
                if (result.status === "SUCCESS") {
                    this.messageService.toastUpdateSuccess();
                }
            }, null, this.otherMessage1);
        }
    }

    ngAfterViewInit(): void {
    }

    getServiceClass() {
        return TokenCodeService;
    }

    getEttClass(): any {
        return TokenCode;
    }

    getTableControl(): any {
        return this.table;
    }

    getEttKey(): string {
        return "tokenCodeId";
    }
}
