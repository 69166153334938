import {Component, OnInit, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {Customer} from "../../models/customer/customer";
import {CustomerService} from "../../services/customer/customer.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-customer',
    templateUrl: './mng-customer.component.html',
    styleUrls: ['./mng-customer.component.scss']
})
export class MngCustomerComponent extends SimpleCrudLazyComponent implements OnInit {
    @ViewChild('dt') table: Table;
    selectedEtt: Customer;
    isShowTokenListDialog = false;
    isShowTransListDialog = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private translate: TranslateService
    ) {
        super(router, route);

        this.exportExcel = this.exportExcel.bind(this);
    }

    ngOnInit(): void {
    }

    afterPreAction() {
        if (this.isCreate) {
            this.selectedEtt.disabled = "N";
            this.selectedEtt.fromDate = new Date();
        }
        super.afterPreAction();
    }

    viewTokenList(ett) {
        this.selectedEtt = ett;
        this.isShowTokenListDialog = true;
    }

    viewTransList(ett) {
        this.selectedEtt = ett;
        this.isShowTransListDialog = true;
    }

    getEttClass(): any {
        return Customer;
    }

    getEttKey(): string {
        return "customerId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return CustomerService;
    }

    exportExcel() {
        super.lazyLoadTable({}, this.crudService, this.getEttClass(), result => {
            if (result.listEtt?.length) {
                console.log(result.listEtt);

                const aoa = [];
                aoa.push([
                  this.translate.instant('label.mngCustomer.customerCode'),
                  this.translate.instant('label.mngCustomer.availableBalance'),
                  this.translate.instant('label.mngCustomer.fullName'),
                  this.translate.instant('label.mngCustomer.phone'),
                  this.translate.instant('label.mngCustomer.email'),
                  this.translate.instant('label.mngCustomer.disabled')
                ]);
                result.listEtt.forEach(customer => {
                    aoa.push([customer.customerCode, customer.availableBalance, customer.fullName, customer.phone,
                        customer.email, customer.disabled]);
                });

                import('xlsx').then(xlsx => {
                  const worksheet = xlsx.utils.aoa_to_sheet(aoa);
                  const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
                  const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
                  this.saveAsExcelFile(excelBuffer, this.translate.instant('label.mngCustomer.listCustomer'));
                });
            } else {
                alert('Empty');
            }
        }).then();
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                  type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
            }
        });
    }
}
