export class MenuAuthorization {
    screenId: string;
    path: string;
    name: string;
    description: string;
    artifacts: Artifact[];
}

export interface Artifact {
    artifactId: string;
    artifactName: string;
    permissions: string[];
}
