/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 31/08/2021.
 */

import {MessageService} from "primeng/api";
import {Injectable} from "@angular/core";
import {AlertMessages} from "../../models/messages/alertMessages";
import {StringUtils} from "../../common/string-utils";
import {Utils} from "../../common/utils";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class AppMessageService {
    constructor(private service: MessageService, private translateService: TranslateService) {
    }

    static transform(translateService: TranslateService, resultCode: any, errorDetail: any): any {
        let result = '';

        if (errorDetail) {
            let errors = [];
            if (Array.isArray(errorDetail)) {
                errors = errorDetail;
            } else {
                errors.push(errorDetail);
            }

            for (const err of errors) {
                const match = /^#(\w+)#(.+)$/g.exec(err?.trim());

                if (match?.length === 3) {
                    const code = match[1];
                    const detail = match[2];

                    const internalMsg = translateService.instant("errors." + code);
                    if (internalMsg !== "errors." + code) {
                        result += internalMsg + ", ";
                    } else {
                        result += detail + ", ";
                    }

                } else {
                    if (err?.trim()) {
                        result += err?.trim() + ", ";
                    }
                }

                if (result !== "") {
                    result = StringUtils.cutString(result, 2);
                }
            }

        } else if (resultCode) {
            result = translateService.instant("errors." + resultCode);
        }

        return result === "" ? "Server Error" : result;
    }

    translate(key: string, ...val: string[]) {
        if (StringUtils.isNullOrEmpty(key)) {
            return "";
        }

        return StringUtils.formatString(this.translateService.instant(key), ...val);
    }

    _buildMessage(key: string, ett?: any): string {
        let ettName = null;
        if (!Utils.isNullOrUndefined(ett) && typeof ett.getEttName === "function") {
            ettName = ett.getEttName();
        }

        let str = this.translateService.instant(key);
        if (!StringUtils.isNullOrEmpty(ettName)) {
            str += " => " + ettName;
        }

        return str;
    }

    toastCreateSuccess(ett?: any) {
        this.showSuccessViaToast(this._buildMessage("common.alert.createSuccess", ett));
    }

    toastCreateUnSuccess(ett?: any) {
        this.showErrorViaToast(this._buildMessage("common.alert.createUnsuccessful", ett));
    }

    toastUpdateSuccess(ett?: any) {
        this.showSuccessViaToast(this._buildMessage("common.alert.updateSuccess", ett));
    }

    toastUpdateUnSuccess(ett?: any) {
        this.showErrorViaToast(this._buildMessage("common.alert.updateUnsuccessful", ett));
    }

    toastDeleteSuccess(ett?: any) {
        this.showSuccessViaToast(this._buildMessage("common.alert.delSuccess", ett));
    }

    toastDeleteUnSuccess(ett?: any) {
        this.showErrorViaToast(this._buildMessage("common.alert.delUnsuccessful", ett));
    }

    showInfoViaToast(str: string, ...val: string[]) {
        this.service.add({
            key: 'tst',
            severity: 'info',
            summary: this.translateService.instant("common.alert.info"),
            detail: this.translate(str, ...val)
        });
    }

    showWarnViaToast(str: string, ...val: string[]) {
        this.service.add({
            key: 'tst',
            severity: 'warn',
            summary: this.translateService.instant("common.alert.warn"),
            detail: this.translate(str, ...val)
        });
    }

    showErrorViaToast(str: string, ...val: string[]) {
        this.service.add({
            key: 'tst',
            severity: 'error',
            summary: this.translateService.instant("common.alert.error"),
            detail: this.translate(str, ...val)
        });
    }

    showSuccessViaToast(str: string, ...val: string[]) {
        this.service.add({
            key: 'tst',
            severity: 'success',
            summary: this.translateService.instant("common.alert.success"),
            detail: this.translate(str, ...val)
        });
    }

    showInfoViaMessages(str: string, alertMessages: AlertMessages, ...val: string[]) {
        alertMessages.msgs = [];
        alertMessages.msgs.push({
            severity: 'info',
            summary: this.translateService.instant("common.alert.info"),
            detail: this.translate(str, ...val)
        });
    }

    showWarnViaMessages(str: string, alertMessages: AlertMessages, ...val: string[]) {
        alertMessages.msgs = [];
        alertMessages.msgs.push({
            severity: 'warn',
            summary: this.translateService.instant("common.alert.warn"),
            detail: this.translate(str, ...val)
        });
    }

    showErrorViaMessages(str: string, alertMessages: AlertMessages, ...val: string[]) {
        alertMessages.msgs = [];
        alertMessages.msgs.push({
            severity: 'error',
            summary: this.translateService.instant("common.alert.error"),
            detail: this.translate(str, ...val)
        });
    }

    showHttpErrorViaMessages(resultCode: string, detailError: any, alertMessages: AlertMessages) {
        alertMessages.msgs = JSON.parse(JSON.stringify(alertMessages.msgs));

        const newMsg = {
            severity: 'error',
            summary: this.translateService.instant("common.alert.error"),
            detail: AppMessageService.transform(this.translateService, resultCode, detailError)
        };

        for (const item of alertMessages.msgs) {
            if (item.detail === newMsg.detail) {
                return;
            }
        }

        alertMessages.msgs.push(newMsg);

    }

    showSuccessViaMessages(str: string, alertMessages: AlertMessages, ...val: string[]) {
        alertMessages.msgs = [];
        alertMessages.msgs.push({
            severity: 'success',
            summary: this.translateService.instant("common.alert.success"),
            detail: this.translate(str, ...val)
        });
    }
}
