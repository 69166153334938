<div *ngIf="isLov" style="width: 500px">
    <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
             [totalRecords]="tableData.totalRecords" [rows]="5" [paginator]="true" [showCurrentPageReport]="true"
             [rowHover]="true" dataKey="organizationId" (onFilter)="onTableFilter($event)"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="organizationCode">
                    {{"label.mngOrganization.organizationCode" | translate}}
                    <p-sortIcon field="organizationCode"></p-sortIcon>
                </th>
                <th pSortableColumn="organizationName">
                    {{"label.mngOrganization.organizationName" | translate}}
                    <p-sortIcon field="organizationName"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="organizationCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-organization>
            <tr>
                <td>
                    <p-button label="{{organization.organizationCode}}" styleClass="p-button-link p-0"
                              (onClick)="onChooseLovItem(organization)"></p-button>
                </td>
                <td>
                    <p-button label="{{organization.organizationName}}" styleClass="p-button-link p-0"
                              (onClick)="onChooseLovItem(organization)"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-telsoft *ngIf="!isLov" [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="organizationId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngOrganization.listOrganization" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="organizationCode">
                        {{"label.mngOrganization.organizationCode" | translate}}
                        <p-sortIcon field="organizationCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="organizationName">
                        {{"label.mngOrganization.organizationName" | translate}}
                        <p-sortIcon field="organizationName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="adminUser">
                        {{"label.mngOrganization.adminUser" | translate}}
                        <p-sortIcon field="adminUser"></p-sortIcon>
                    </th>
                    <th pSortableColumn="exchangeRate">
                        {{"label.mngOrganization.exchangeRate" | translate}}
                        <p-sortIcon field="exchangeRate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="packageName">
                        {{"label.mngOrganization.packageName" | translate}}
                        <p-sortIcon field="packageName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="statusName">
                        {{"label.mngOrganization.statusName" | translate}}
                        <p-sortIcon field="statusName"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="organizationCode" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="adminUser" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="exchangeRate" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="packageName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="status" matchMode="equals">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown showClear="true" [ngModel]="value" [options]="listStatus"
                                            optionLabel="description" optionValue="statusId"
                                            placeholder="{{'common.label.cbChoose'|translate}}"
                                            (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-org>
                <tr class="data-row" showDelay="700" pTooltip="{{org.organizationCode}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="org"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(org.organizationCode)">
                        <div class="row-control">
                            <app-table-control [entity]="org" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                            </app-table-control>
                        </div>
                        <div class="row-title">{{org.organizationCode}}</div>
                    </td>
                    <td>
                        {{org.organizationName}}
                    </td>
                    <td>
                        {{org.adminUser}}
                    </td>
                    <td>
                        {{org.exchangeRate}}
                    </td>
                    <td>
                        {{org.packageName}}
                    </td>
                    <td>
                        {{org.statusName}}
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="row mt-2 mb-4">
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtOrganizationCode" value="label.mngOrganization.organizationCode"
                                   required="true"></app-label>
                        <input id="txtOrganizationCode" name="txtOrganizationCode" type="text" pInputText
                               [(ngModel)]="selectedEtt.organizationCode" [disabled]="isDisable || !isCreate"
                               #txtOrganizationCode="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtOrganizationCode"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtOrganizationName" value="label.mngOrganization.organizationName"
                                   required="true"></app-label>
                        <input id="txtOrganizationName" name="txtOrganizationName" type="text" pInputText
                               [(ngModel)]="selectedEtt.organizationName" [disabled]="isDisable"
                               #txtOrganizationName="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtOrganizationName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtExchangeRate" value="label.mngOrganization.exchangeRate"
                                   required="true"></app-label>
                        <input id="txtExchangeRate" name="txtExchangeRate" type="text" pInputText
                               [(ngModel)]="selectedEtt.exchangeRate" [disabled]="isDisable"
                               #txtExchangeRate="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtExchangeRate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbStatus" value="label.mngOrganization.statusName" required="true"></app-label>
                        <p-dropdown showClear="true" id="cbStatus" name="cbStatus" [(ngModel)]="selectedEtt.status"
                                    [options]="listStatus" optionLabel="description" optionValue="statusId"
                                    [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                    [disabled]="isDisable" required #cbStatus="ngModel"></p-dropdown>
                        <app-validator-message [templateVar]="cbStatus"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbAdmin" value="label.mngOrganization.adminUser" required="true"></app-label>
                        <input id="cbAdmin" name="cbAdmin" type="text" pInputText
                               [(ngModel)]="selectedEtt.adminUser" [disabled]="isDisable" readonly
                               #cbAdmin="ngModel" (click)="op.toggle($event)" required maxlength="50"/>
                        <app-validator-message [templateVar]="cbAdmin"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                    <p-overlayPanel #op appendTo="body">
                        <ng-template pTemplate>
                            <app-account-list
                                    (selectedEttChange)="onChooseAdmin($event); op.hide()"></app-account-list>
                        </ng-template>
                    </p-overlayPanel>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtFromDate" value="label.mngOrganization.fromDate"></app-label>
                        <p-calendar id="txtFromDate" name="txtFromDate" appendTo="body"
                                    [(ngModel)]="selectedEtt.fromDate" #txtFromDate="ngModel" monthNavigator="true"
                                    yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true"
                                    [showSeconds]="true" showButtonBar="true"
                                    [disabled]="isDisable || !isCreate"></p-calendar>
                        <app-validator-message [templateVar]="txtFromDate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtThruDate" value="label.mngOrganization.thruDate"></app-label>
                        <p-calendar id="txtThruDate" name="txtThruDate" appendTo="body"
                                    [(ngModel)]="selectedEtt.thruDate" #txtThruDate="ngModel" monthNavigator="true"
                                    yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true" required
                                    [showSeconds]="true" showButtonBar="true" [disabled]="true"></p-calendar>
                        <app-validator-message [templateVar]="txtThruDate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbPackage" value="label.mngOrganization.packageName"
                                   required="true"></app-label>
                        <p-dropdown showClear="true" id="cbPackage" name="cbPackage" [(ngModel)]="selectedEtt.packageId"
                                    [options]="listPackage" optionLabel="packageName" optionValue="packageId"
                                    [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                    [disabled]="isDisable" required #cbPackage="ngModel"></p-dropdown>
                        <app-validator-message [templateVar]="cbPackage"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
            </div>

            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>
</app-telsoft>
