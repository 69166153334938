<p-dialog [(visible)]="parentComponent.isShowEttDialog" [header]="parentComponent.getDialogTitle()" [modal]="true"
          (onHide)="onDialogHide()" (onShow)="onDialogShow()" styleClass="p-fluid" [style]="{width: width}"
          [contentStyle]="{'overflow': 'visible'}" [maximizable]="isMaximizable" [responsive]="true" [draggable]="false"
          (onMaximize)="onMaximize()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [resizable]="false"
          appendTo="body">

    <ng-template pTemplate="content">
        <p-messages [value]="parentComponent.getDialogMsgs()"></p-messages>
        <ng-content></ng-content>
    </ng-template>
</p-dialog>
