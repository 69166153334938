<p-dialog header="{{'common.label.warning' | translate}}" [(visible)]="isShowDialog" [style]="{width: '30vw'}"
          [modal]="true">
    <p>
        {{'common.alert.timeLeft' | translate}}:
        <countdown #cdMain *ngIf="config" [config]="config" (event)="handleEvent($event)"></countdown>
    </p>
    <p>{{'common.alert.timeConfirm' | translate}}</p>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="keepalive()" label="{{'common.button.agree' | translate}}"
                  styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
