export class ProductStore {
    productStoreId: string;
    productStoreCode: string;
    storeName: string;
    toName: string;
    organizationId: string;
    organizationName: string;
    city: string;
    address2: string;
    address1: string;
    fullName: string;
    unitNumber: string;
}
