<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true"
                 dataKey="productId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngProduct.listProduct" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="productName">
                        {{"label.mngProduct.productName" | translate}}
                        <p-sortIcon field="productName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="productCode">
                        {{"label.mngProduct.productCode" | translate}}
                        <p-sortIcon field="productCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="amountUomName">
                        {{"label.mngProduct.amountUomName" | translate}}
                        <p-sortIcon field="amountUomName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="amountFixed">
                        {{"label.mngProduct.amountFixed" | translate}}
                        <p-sortIcon field="amountFixed"></p-sortIcon>
                    </th>
                    <th pSortableColumn="description">
                        {{"label.mngProduct.description" | translate}}
                        <p-sortIcon field="description"></p-sortIcon>
                    </th>
                    <th pSortableColumn="comments">
                        {{"label.mngProduct.comments" | translate}}
                        <p-sortIcon field="comments"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="productName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="productCode" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="amountUomName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="amountFixed" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="description" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="comments" matchMode="contains"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-product>
                <tr class="data-row" showDelay="700" pTooltip="{{product.productName}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(product.productName)">
                        <div class="row-control">
                            <app-table-control [entity]="product" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete"></app-table-control>
                        </div>
                        <div class="row-title">{{product.productName}}</div>
                    </td>
                    <td>{{product.productCode}}</td>
                    <td>{{product.amountUomName}}</td>
                    <td>{{product.amountFixed}}</td>
                    <td>{{product.description}}</td>
                    <td>{{product.comments}}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <p-tabView>
            <p-tabPanel header="{{'label.mngProduct.productDetail' | translate}}">
                <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
                    <div class="row mt-2 mb-4">
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="txtProductName" value="label.mngProduct.productName"
                                           required="true"></app-label>
                                <input id="txtProductName" name="txtProductName" type="text" pInputText
                                       [(ngModel)]="selectedEtt.productName" [disabled]="isDisable"
                                       #txtProductName="ngModel" required maxlength="50"/>
                                <app-validator-message [templateVar]="txtProductName"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="txtProductCode" value="label.mngProduct.productCode"
                                           required="true"></app-label>
                                <input id="txtProductCode" name="txtProductCode" type="text" pInputText
                                       [(ngModel)]="selectedEtt.productCode" [disabled]="isDisable"
                                       #txtProductCode="ngModel" required maxlength="50"/>
                                <app-validator-message [templateVar]="txtProductCode"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="txtType" value="label.mngProduct.type"
                                           required="true"></app-label>
                                <p-dropdown showClear="true" id="cbType" name="cbType"
                                            [(ngModel)]="selectedEtt.productTypeEnumId"
                                            [options]="listType" optionLabel="label" optionValue="value"
                                            [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                            [disabled]="isDisable" #cbType="ngModel" ></p-dropdown>
                                <app-validator-message [templateVar]="cbType"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="cbUom" value="label.mngProduct.amountUomName"
                                           required="true"></app-label>
                                <p-dropdown showClear="true" id="cbUom" name="cbUom"
                                            [(ngModel)]="selectedEtt.amountUomId"
                                            [options]="listUom" optionLabel="name" optionValue="uomId"
                                            [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                            [disabled]="isDisable" #cbUom="ngModel"></p-dropdown>
                                <app-validator-message [templateVar]="cbUom"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="cbDisabled" value="label.mngProduct.amountFixed"
                                           required="true"></app-label>
                                <input #txtAmountFixed="ngModel" [(ngModel)]="selectedEtt.amountFixed" [disabled]="isDisable"
                                       id="txtAmountFixed" maxlength="20" name="txtAmountFixed" pInputText pKeyFilter="pnum"
                                       type="number" [min]="0">
                                <app-validator-message [templateVar]="txtAmountFixed"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="txtDescription" value="label.mngProduct.description"></app-label>
                                <input id="txtDescription" name="txtDescription" type="text" pInputText
                                       [(ngModel)]="selectedEtt.description" [disabled]="isDisable"
                                       #txtDescription="ngModel" maxlength="500"/>
                                <app-validator-message [templateVar]="txtDescription"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="txtComments" value="label.mngProduct.comments"></app-label>
                                <input id="txtComments" name="txtComments" type="text" pInputText
                                       [(ngModel)]="selectedEtt.comments" [disabled]="isDisable"
                                       #txtComments="ngModel" maxlength="500"/>
                                <app-validator-message [templateVar]="txtComments"
                                                       [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                    </div>

                    <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                            *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                            class="{{'common.button.class.dialog.save'|translate}}"></button>
                </form>
            </p-tabPanel>
            <p-tabPanel header="{{'label.mngProduct.productPrice' | translate}}">
                <app-product-price [productId]="selectedProductId"></app-product-price>
            </p-tabPanel>
        </p-tabView>
    </app-dialog>
</app-telsoft>
