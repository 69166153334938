import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() type = "text";
    value: boolean;
    onChange: (chkData: any) => {};
    onTouched: () => void;

    constructor() {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return undefined;
    }

    writeValue(obj: any): void {
        // tslint:disable-next-line:triple-equals
        this.value = obj == 1;

        if (this.onChange !== undefined) {
            this.onChange(this.convertValue(this.value));
        }
    }

    handleOnCheckboxChange(e) {
        const convertedValue = this.convertValue(e);
        this.writeValue(convertedValue);
        this.onChange(convertedValue);
    }

    convertValue(bl): any {
        if (this.type === "text") {
            return bl ? "1" : "0";
        }

        if (this.type === "number") {
            return bl ? 1 : 0;
        }

        if (this.type === "boolean") {
            return bl === true;
        }

        return null;
    }
}
