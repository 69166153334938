import {Component, OnInit, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";

import {ClassificationService} from "../../services/classification/classification.service";
import {Classification} from "../../models/classification/classification";

@Component({
    selector: 'app-classification',
    templateUrl: './mng-classification.component.html',
    styleUrls: ['./mng-classification.component.scss']
})
export class MngClassificationComponent extends SimpleCrudLazyComponent implements OnInit {

    @ViewChild('dt') table: Table;
    selectedEtt: Classification;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

    afterPreAction() {
        super.afterPreAction();
    }

    getEttClass(): any {
        return Classification;
    }

    getEttKey(): string {
        return "partyClassificationId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return ClassificationService;
    }

}
