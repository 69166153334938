/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {ApiFilter} from "../view/api-filter";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {getServerApiUrl, sortType} from 'src/app/app-constant';
import {LazyLoadEvent} from "primeng/api";
import {DateUtils} from "../../common/date-utils";
import {Utils} from "../../common/utils";
import {StringUtils} from "../../common/string-utils";

export abstract class AbstractCrudService implements CurdService {
    static httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
    });
    static options = {headers: AbstractCrudService.httpHeaders};

    protected constructor(protected httpClient: HttpClient) {
    }

    abstract getApiName(): string;

    getDefaultSortField(): string {
        return null;
    }

    getDefaultSortType(): string {
        return sortType.DESC;
    }

    listAll<T>() {
        return this.list<T>(null);
    }

    listLazy<T>(event: LazyLoadEvent) {
        if (Utils.isNullOrUndefined(event.sortField) && !StringUtils.isNullOrEmpty(this.getDefaultSortField())) {
            event.sortField = this.getDefaultSortField();
            event.sortOrder = -1;
        }
        return this.list<T>(`lazyLoadEvent=` + JSON.stringify(event));
    }

    list<T>(data: any, url?: string) {
        if (!url) {
            url = this.getBaseUrl();
        }

        if (data) {
            if (url.includes('?')) {
                url += '&' + data;
            } else {
                url += '?' + data;
            }
        }
        return this.httpClient.get<T>(url + '', AbstractCrudService.options);
    }

    filter<T>(data: any, url: string, filters: ApiFilter[]) {
        if (data) {
            url += '?' + data;
        }
        const f = {"filters": filters};
        return this.httpClient.post<T>(url + '', JSON.stringify(f), AbstractCrudService.options);
    }

    get<T>(id: any, url) {
        return this.httpClient.get<T>(url + '/' + id, AbstractCrudService.options);
    }

    put<T>(id: any, data: any, url) {
        return this.httpClient.put<T>(url + '/' + id, JSON.stringify(data), AbstractCrudService.options);
    }

    del<T>(id: any, url) {
        return this.httpClient.delete<T>(url + '/' + id, AbstractCrudService.options);
    }

    post<T>(data: any, url) {
        return this.httpClient.post<T>(url, JSON.stringify(data), AbstractCrudService.options);
    }

    postFormData<T>(data: any, url) {
        return this.httpClient.post<T>(url, data, AbstractCrudService.options);
    }

    getBaseUrl(): string {
        return getServerApiUrl() + '/' + this.getApiName();
    }

    getFilterUrl(): string {
        return getServerApiUrl() + '/' + this.getApiName() + '/filters';
    }

    view<T>(id: any) {
        return this.get<T>(id, this.getBaseUrl());
    }

    create<T>(data: any) {
        return this.post<T>(data, this.getBaseUrl());
    }

    update<T>(id: any, data: any) {
        return this.put<T>(id, data, this.getBaseUrl());
    }

    delete<T>(data: any) {
        return this.del<T>(data, this.getBaseUrl());
    }
}

export interface CurdService {
    getApiName(): string;

    view<T>(id: any);

    create<T>(data: any);

    update<T>(id: any, data: any);

    delete<T>(id: any);

    listAll<T>();

    listAll<T>(pageSize: number, pageNumber: number);

    list<T>(data: any, url: string);

    put<T>(condition, data: any, url: string);

    del<T>(data: any, url: string);

    post<T>(data: any, url: string);
}
