import {Component, Input, OnInit} from '@angular/core';
import {SimpleCrudComponent} from "../../view/simple-crud-component";

@Component({
    selector: 'app-telsoft',
    templateUrl: './telsoft.component.html',
})
export class TelsoftComponent implements OnInit {
    @Input() parentComponent: any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
