<div class="exception-body error">
    <div class="exception-panel">
        <div class="exception-content">
            <img src="assets/layout/images/pages/icon-error.svg">
            <h1>Error Occured</h1>
            <p>Something went wrong.</p>
            <button [routerLink]="['/']" type="button" pButton icon="pi pi-arrow-left" label="Dashboard"></button>
            <button (click)="forceLogout()" type="button" pButton
                    icon="pi pi-lock" label="Login"
                    class="ml-1 p-button-danger"></button>
        </div>
    </div>
</div>
