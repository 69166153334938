import {Type} from "class-transformer";

export class Organization {
    organizationId: string;
    organizationCode: string;
    organizationName: string;
    status: string;
    statusName: string;
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    thruDate: Date;
    adminId: string;
    adminUser: string;
    exchangeRate: string;
    packageOrderId: string;
    packageId: string;
    packageName: string;
}
