import {FilterMetadata} from "primeng/api/filtermetadata";

/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 08/09/2021.
 */

export class ApiSearch {
    searchTerm: string;

    static getSearchTerm(field: string, data: FilterMetadata) {
        let value = data.value;
        if(value instanceof  Date) {
            value = (value as Date).toISOString();
        }

        return field + this._build(data.matchMode, value);
    }

    static _build(matchMode: string, value: string) {
        switch (matchMode) {
            case "startsWith":
                return `:*${value}`;
            case "contains":
                return `:*${value}*`;
            case "endsWith":
                return `:${value}*`;
            case "is":
            case "equals":
            case "dateIs":
                return `:${value}`;
            case "isNot":
            case "notEquals":
                return `!${value}`;
            case "in":
                return `:*${value}*`;
            case "lt":
                return `<${value}`;
            case "lte":
                return `<=${value}`;
            case "gt":
                return `>${value}`;
            case "gte":
                return `>=${value}`;
            case "before":
            case "dateBefore":
                return `<${value}`;
            case "dateAfter":
                return `>${value}`;
        }
    }
}
