<p-dialog [(visible)]="visible" [header]="header" [modal]="true" appendTo="body"
          (onHide)="onHideDialog();" styleClass="p-fluid" [style]="{width: width}"
          [contentStyle]="{'overflow': 'visible'}" [maximizable]="isMaximizable" [responsive]="true"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [resizable]="false" [draggable]="false">

    <ng-template pTemplate="content">
        <p-messages [value]="msgs"></p-messages>
        <ng-content></ng-content>
    </ng-template>
</p-dialog>
