<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="userId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="username">
                        {{"label.mngUserAccount.username" | translate}}
                        <p-sortIcon field="username"></p-sortIcon>
                    </th>
                    <th pSortableColumn="userFullName">
                        {{"label.mngUserAccount.userFullName" | translate}}
                        <p-sortIcon field="userFullName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="emailAddress">
                        {{"label.mngUserAccount.emailAddress" | translate}}
                        <p-sortIcon field="emailAddress"></p-sortIcon>
                    </th>
                    <th pSortableColumn="roleType">
                        {{"label.mngUserAccount.roleType" | translate}}
                        <p-sortIcon field="roleType"></p-sortIcon>
                    </th>
                    <th pSortableColumn="organizationName">
                        {{"label.mngUserAccount.organization" | translate}}
                        <p-sortIcon field="organizationName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="disabled">
                        {{"label.mngUserAccount.disabled" | translate}}
                        <p-sortIcon field="disabled"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="username" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="userFullName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="emailAddress" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="roleType" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="disabled" matchMode="equals">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown showClear="true" [ngModel]="value" [options]="comboboxStatusYes"
                                            optionLabel="label"
                                            optionValue="value" placeholder="{{'common.label.cbChoose'|translate}}"
                                            (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-userAccount>
                <tr class="data-row" showDelay="700" pTooltip="{{userAccount.username}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="userAccount"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(userAccount.username)">
                        <div class="row-control">
                            <app-table-control [entity]="userAccount" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                                <button pButton pRipple icon="fa fa-bars" type="button"
                                        class="p-button-rounded p-button-help p-mr-2"
                                        pTooltip="{{'label.mngUserAccount.viewPermission'|translate}}"
                                        tooltipPosition="top" *appPermissions="['U']"
                                        (click)="viewPermission(userAccount)"></button>
                            </app-table-control>
                        </div>
                        <div class="row-title">{{userAccount.username}}</div>
                    </td>
                    <td>
                        {{userAccount.userFullName}}
                    </td>
                    <td>
                        {{userAccount.emailAddress}}
                    </td>
                    <td>
                        {{userAccount.roleType}}
                    </td>
                    <td>
                        {{userAccount.organizationName}}
                    </td>
                    <td>
                        {{getLabelYes(userAccount.disabled)}}
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="row mt-2 mb-4">
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtUsername" value="label.mngUserAccount.username" required="true"></app-label>
                        <input id="txtUsername" name="txtUsername" type="text" pInputText
                               [(ngModel)]="selectedEtt.username" [disabled]="isDisable"
                               #txtUsername="ngModel" required maxlength="20"/>
                        <app-validator-message [templateVar]="txtUsername"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtUserFullName" value="label.mngUserAccount.userFullName"
                                   required="true"></app-label>
                        <input id="txtUserFullName" name="txtUserFullName" type="text" pInputText
                               [(ngModel)]="selectedEtt.userFullName" [disabled]="isDisable"
                               #txtUserFullName="ngModel" required maxlength="20"/>
                        <app-validator-message [templateVar]="txtUserFullName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0" *ngIf="isCreate">
                    <div class="p-field">
                        <app-label for="txtPassword" value="label.mngUserAccount.password" required="true"></app-label>
                        <p-password id="txtPassword" name="txtPassword" [toggleMask]="true" [feedback]="false"
                                    [(ngModel)]="selectedEtt.password" [disabled]="isDisable"
                                    #txtPassword="ngModel" required maxlength="20"></p-password>
                        <app-validator-message [templateVar]="txtPassword"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbRoleType" value="label.mngUserAccount.cbRoleType"
                                   required="true"></app-label>
                        <p-dropdown showClear="true" id="cbPackage" name="cbPackage"
                                    [(ngModel)]="selectedEtt.roleTypeId"
                                    [options]="listRoleType" optionLabel="description" optionValue="roleTypeId"
                                    [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                    [disabled]="isDisable" required #cbRoleType="ngModel"></p-dropdown>
                        <app-validator-message [templateVar]="cbRoleType"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0" *ngIf="authService.isRootAdmin()">
                    <div class="p-field">
                        <app-label for="txtOrganization" value="label.mngUserAccount.organization"
                                   required="true"></app-label>
                        <div class="p-inputgroup">
                            <input id="txtOrganization" name="txtOrganization" type="text" pInputText required
                                   [(ngModel)]="selectedEtt.organizationName" [disabled]="isDisable || !isCreate"
                                   #txtOrganization="ngModel" (click)="op.toggle($event)" readonly maxlength="50"/>
                            <button type="button" pButton pRipple icon="pi pi-times" styleClass="p-button-danger"
                                    (click)="clearCurrentOrg()" *ngIf="isCreate"></button>
                        </div>
                        <app-validator-message [templateVar]="txtOrganization"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>

                    <p-overlayPanel #op appendTo="body">
                        <ng-template pTemplate>
                            <app-mng-organization isLov="true"
                                                  (selectedLovChange)="onChooseOrg($event); op.hide()">
                            </app-mng-organization>
                        </ng-template>
                    </p-overlayPanel>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtUserFullName" value="label.mngUserAccount.emailAddress"></app-label>
                        <input id="txtEmailAddress" name="txtEmailAddress" type="text" pInputText
                               [(ngModel)]="selectedEtt.emailAddress" [disabled]="isDisable"
                               #txtEmailAddress="ngModel" maxlength="20"/>
                        <app-validator-message [templateVar]="txtEmailAddress"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbDisabled" value="label.mngUserAccount.disabled"
                                   required="true"></app-label>
                        <p-dropdown showClear="true" id="cbDisabled" name="cbDisabled"
                                    [(ngModel)]="selectedEtt.disabled"
                                    [options]="comboboxStatusYes" optionLabel="label" optionValue="value"
                                    [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                    [disabled]="isDisable" required #cbDisabled="ngModel"></p-dropdown>
                        <app-validator-message [templateVar]="cbDisabled"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
            </div>

            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>

    <app-dialog-advance [(visible)]="isShowPermsDialog" header="{{'label.mngUserAccount.viewPermission' | translate}}"
                        [form]="permsForm" [msgs]="otherMessage1.msgs" width="75vw">
        <form #permsForm="ngForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="text-right my-1">
                        <button pButton pRipple type="button" label="{{'label.mngUserAccount.checkAll'|translate}}"
                                (click)="checkAllSpecial(true)" icon="pi pi-check"
                                class="p-button-success w-auto"></button>
                        <button pButton pRipple type="button" label="{{'label.mngUserAccount.uncheckAll'|translate}}"
                                (click)="checkAllSpecial(false)" icon="pi pi-times"
                                class="p-button-danger w-auto ml-1"></button>
                    </div>

                    <p-table [value]="permsGrant?.specialPerms" [rowHover]="true" [scrollable]="true" scrollHeight="flex">
                        <ng-template pTemplate="caption">
                            <div class="text-center">{{'label.mngUserAccount.specialPerms' | translate}}</div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 4rem">#</th>
                                <th style="min-width:400px">
                                    {{'label.mngUserAccount.display' | translate}}
                                </th>
                                <th style="width: 10rem" class="text-center">
                                    {{'label.mngUserAccount.allow' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 4rem">{{rowIndex + 1}}</td>
                                <td style="min-width:400px">{{item.description}}</td>
                                <td style="width: 10rem" class="text-center">
                                    <app-checkbox [(ngModel)]="item.granted" type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="col-12 col-md-6">
                    <div class="text-right my-1">
                        <button pButton pRipple type="button" label="{{'label.mngUserAccount.checkAll'|translate}}"
                                (click)="checkAllNormal(true)" icon="pi pi-check"
                                class="p-button-success w-auto"></button>
                        <button pButton pRipple type="button" label="{{'label.mngUserAccount.uncheckAll'|translate}}"
                                (click)="checkAllNormal(false)" icon="pi pi-times"
                                class="p-button-danger w-auto ml-1"></button>
                    </div>

                    <p-table [value]="permsGrant?.normalPerms" [rowHover]="true" [scrollable]="true" scrollHeight="500px">
                        <ng-template pTemplate="caption">
                            <div class="text-center">{{'label.mngUserAccount.normalPerms' | translate}}</div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 4rem">#</th>
                                <th style="min-width:400px">
                                    {{'label.mngUserAccount.display' | translate}}
                                </th>
                                <th style="width: 5rem" class="text-center">
                                    {{'label.mngUserAccount.all' | translate}}
                                </th>
                                <th style="width: 5rem" class="text-center">
                                    {{'label.mngUserAccount.view' | translate}}
                                </th>
                                <th style="width: 5rem" class="text-center">
                                    {{'label.mngUserAccount.create' | translate}}
                                </th>
                                <th style="width: 5rem" class="text-center">
                                    {{'label.mngUserAccount.update' | translate}}
                                </th>
                                <th style="width: 5rem" class="text-center">
                                    {{'label.mngUserAccount.delete' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 5rem">{{rowIndex + 1}}</td>
                                <td style="min-width:400px">
                                    <span *ngFor="let item of item.artifacts">
                                        {{item.artifactName}}<br/>
                                    </span>
                                </td>
                                <td style="width: 5rem" class="text-center">
                                    <app-checkbox *ngIf="findNormalPerm(item.permissions, 'ALL').granted !== undefined"
                                                  [(ngModel)]="findNormalPerm(item.permissions, 'ALL').granted"
                                                  type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                                <td style="width: 5rem" class="text-center">
                                    <app-checkbox *ngIf="findNormalPerm(item.permissions, 'VIEW').granted !== undefined"
                                                  [(ngModel)]="findNormalPerm(item.permissions, 'VIEW').granted"
                                                  type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                                <td style="width: 5rem" class="text-center">
                                    <app-checkbox *ngIf="findNormalPerm(item.permissions, 'CREATE').granted !== undefined"
                                                  [(ngModel)]="findNormalPerm(item.permissions, 'CREATE').granted"
                                                  type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                                <td style="width: 5rem" class="text-center">
                                    <app-checkbox *ngIf="findNormalPerm(item.permissions, 'UPDATE').granted !== undefined"
                                                  [(ngModel)]="findNormalPerm(item.permissions, 'UPDATE').granted"
                                                  type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                                <td style="width: 5rem" class="text-center">
                                    <app-checkbox *ngIf="findNormalPerm(item.permissions, 'DELETE').granted !== undefined"
                                                  [(ngModel)]="findNormalPerm(item.permissions, 'DELETE').granted"
                                                  type="boolean"
                                                  [ngModelOptions]="{standalone: true}"></app-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <button pButton pRipple type="button" label="{{'common.button.save'|translate}}"
                    (click)="savePerms()" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog-advance>
</app-telsoft>
