/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 07/10/2021.
 */

export class AlertMessages {
    type: string;
    msgs: any[] = [];
}
