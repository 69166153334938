import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from "./_app-core/shared/layout/app.main.component";
import {DashboardDemoComponent} from "./app-demo/pages/dashboard/dashboarddemo.component";
import {MngTokenCodeComponent} from "./app-demo/pages/mng-token-code/mng-token-code.component";
import {AuthGuard} from "./_app-core/services/auth/auth.guard";
import {AppErrorComponent} from "./_app-core/shared/pages/app.error.component";
import {AppAccessdeniedComponent} from "./_app-core/shared/pages/app.accessdenied.component";
import {AppNotfoundComponent} from "./_app-core/shared/pages/app.notfound.component";
import {AppLoginComponent} from "./_app-core/shared/pages/app.login.component";
import {AppRoutingUrl} from "./app-routing-url";
import {MngCustomerComponent} from "./app-demo/pages/mng-customer/mng-customer.component";
import {MngProductStoreComponent} from "./app-demo/pages/mng-product-store/mng-product-store.component";
import {MngProductComponent} from "./app-demo/pages/mng-product/mng-product.component";
import {MngOrganizationComponent} from "./app-demo/pages/mng-organizaion/mng-organization.component";
import {MngAccountComponent} from "./app-demo/pages/mng-account/mng-account.component";
import {MngPromotionComponent} from "./app-demo/pages/mng-promotion/mng-promotion.component";
import {MngAwardComponent} from "./app-demo/pages/mng-award/mng-award.component";
import {MngEventComponent} from "./app-demo/pages/mng-event/mng-event.component";
import {MngClassificationComponent} from "./app-demo/pages/mng-classification/mng-classification.component";
import {RptOrgStatusComponent} from "./app-demo/pages/rpt-org-status/rpt-org-status.component";
import {RptOrgInfoComponent} from "./app-demo/pages/rpt-org-info/rpt-org-info.component";
import {RptLoyaltyInfoComponent} from "./app-demo/pages/rpt-loyalty-info/rpt-loyalty-info.component";
import {RptCustomerComponent} from './app-demo/pages/rpt-customer/rpt-customer.component';
import {RptTransComponent} from './app-demo/pages/rpt-trans/rpt-trans.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', component: DashboardDemoComponent},
                    {path: AppRoutingUrl.module.mngTokenCode, component: MngTokenCodeComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngCustomer, component: MngCustomerComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngProductStore, component: MngProductStoreComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngProduct, component: MngProductComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngOrganization, component: MngOrganizationComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngPromotion, component: MngPromotionComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngAward, component: MngAwardComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngEvent, component: MngEventComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngAccount, component: MngAccountComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.mngClassification, component: MngClassificationComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.rptOrgStatus, component: RptOrgStatusComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.rptOrgInfo, component: RptOrgInfoComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.rptLoyaltyInfo, component: RptLoyaltyInfoComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.rptCustomer, component: RptCustomerComponent, canActivate: [AuthGuard]},
                    {path: AppRoutingUrl.module.rptTrans, component: RptTransComponent, canActivate: [AuthGuard]},
                ],
                canActivate: [AuthGuard]
            },
            {path: AppRoutingUrl.common.error, component: AppErrorComponent},
            {path: AppRoutingUrl.common.accessdenied, component: AppAccessdeniedComponent},
            {path: AppRoutingUrl.common.notFound, component: AppNotfoundComponent},
            {path: AppRoutingUrl.common.login, component: AppLoginComponent},
            {path: '**', redirectTo: AppRoutingUrl.common.notFound},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
