import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from "../../customizes/view/base-component";
import {AuthService} from "../../services/auth/auth.service";
import {menu} from "../../../app-constant";
import {AlertMessages} from "../../models/messages/alertMessages";
import {MenuAuthorization} from "../../models/menu-autho/menu-authorization";

@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    providers: []
})
export class AppLoginComponent extends BaseComponent implements OnInit {
    username = '';
    password = '';
    isSubmitted = false;
    isLogged = false;
    isShowChangePwdDialog = false;
    referer;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.authService.clearStorage();
        this.referer = this.authService.getRedirectUrl();
    }

    getServiceClass() {
        return AuthService;
    }

    async login(formInvalid: boolean) {
        this.isSubmitted = true;
        this.isLogged = false;

        if (formInvalid) {
            return;
        }

        // Clear old msg
        this.mainMessage = new AlertMessages();

        // Login
        await this.executeHttpRequest(this.authService.authenticate(this.username, this.password), result => {
            this.authService.setToken(result.apiKey);
            this.authService.setUser(this.username);
            this.isLogged = true;
        });

        if (this.isLogged) {
            await this.executeHttpRequest(this.authService.getListAuthorization(), result => {
                this.authService.setAuthorization(result);
            }, MenuAuthorization);

            this.authService.setMenu(menu);
            const returnUrl = this.referer || '';
            await this.router.navigate([returnUrl]);
        } else {
            this.authService.clearStorage();
        }

        this.isSubmitted = false;
    }

    onError(errorCode, errorMsg) {
        if (errorMsg?.includes("PWDCHG")) {
            this.isShowChangePwdDialog = true;
        }
    }
}
