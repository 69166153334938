/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {getServerApiUrl, globalVar, storageKey} from '../../../app-constant';
import {Router} from '@angular/router';
import {AbstractCrudService} from "../../customizes/services/crud-service";
import {MenuAuthorization} from "../../models/menu-autho/menu-authorization";
import {Utils} from "../../common/utils";
import {Language} from "../../models/language/language";
import {StringUtils} from "../../common/string-utils";
import {SecureStorageService} from "../storage/secure-storage.service";
import {AmObject} from "../../models/am-object/am-object";
import {AppRoutingUrl} from "../../../app-routing-url";

@Injectable({
    providedIn: 'root'
})
export class AuthService extends AbstractCrudService {
    public changePwdUrl = getServerApiUrl() + '/iam-auth/change-password';
    public loginUrl = getServerApiUrl() + '/iam-auth/login';
    public logoutUrl = getServerApiUrl() + '/iam-auth/logout';
    public keepaliveUrl = getServerApiUrl() + '/keepalive';
    public authorizationsUrl = getServerApiUrl() + '/iam-auth/grant-privilege';

    constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        private secureStorageService: SecureStorageService,
    ) {
        super(httpClient);
    }

    public isAuthenticated(): boolean {
        try {
            const token = this.secureStorageService.getItem(storageKey.TOKEN);
            return !StringUtils.isNullOrEmpty(token);

        } catch (e) {
            return false;
        }
    }

    public isRootAdmin(): boolean {
        return this.getUserInfo().roleTypeId === 'Administrator';
    }

    public getApiName(): string {
        return "";
    }

    getRedirectUrl(): string {
        return sessionStorage.getItem(storageKey.REFERER);
    }

    getUser(): any {
        return JSON.parse(localStorage.getItem(storageKey.USER));
    }

    getUserInfo(): any {
        return JSON.parse(localStorage.getItem(storageKey.USER_INFO));
    }

    getToken(): string {
        return StringUtils.fixNull(this.secureStorageService.getItem(storageKey.TOKEN));
    }

    getTokenExpirationDate(): Date {
        return new Date((new Date()).getTime() + (1000 * 60 * 60 * 24));
    }

    getMenu(): any {
        return JSON.parse(localStorage.getItem(storageKey.MENU));
    }

    getLocale(): Language {
        return JSON.parse(localStorage.getItem(storageKey.LOCALE));
    }

    setRedirectUrl(url: string) {
        url = url === "/" ? "" : url;
        sessionStorage.setItem(storageKey.REFERER, url);
    }

    setToken(token: string) {
        this.secureStorageService.setItem(storageKey.TOKEN, token);
    }

    setUser(user: any) {
        localStorage.setItem(storageKey.USER, JSON.stringify(user));
    }

    setUserInfo(userInfo: any) {
        localStorage.setItem(storageKey.USER_INFO, JSON.stringify(userInfo));
    }

    _decodePermission(perm: string): string {
        switch (perm) {
            case "CREATE":
                return "C";
            case "VIEW":
                return "R";
            case "UPDATE":
                return "U";
            case "DELETE":
                return "D";
        }
    }

    setAuthorization(list: MenuAuthorization[]) {
        const mapAuthorization = new Map<string, string>();
        for (const item of list) {
            if (item.artifacts?.length > 0) {
                let rightCode = "";
                for (const perm of item.artifacts[0].permissions) {
                    if (perm === "ALL") {
                        rightCode = "C,R,U,D";
                        break;
                    } else {
                        rightCode += "," + this._decodePermission(perm);
                    }
                }

                if (StringUtils.isNullOrEmpty(mapAuthorization[item.path])) {
                    mapAuthorization[item.path] = "," + rightCode + ",";
                } else {
                    mapAuthorization[item.path] = mapAuthorization[item.path] + rightCode + ",";
                }
            }
        }

        localStorage.setItem(storageKey.AUTHORIZATION, JSON.stringify(mapAuthorization));
    }

    setMenu(list: AmObject[]) {
        const treeMenu = this.buildTreeMenu(list);
        localStorage.setItem(storageKey.MENU, JSON.stringify(treeMenu));
    }

    changePwd(username, oldPassword, newPassword, newPasswordVerify) {
        return this.post({username, oldPassword, newPassword, newPasswordVerify}, this.changePwdUrl);
    }

    authenticate(username, password) {
        return this.post({username, password}, this.loginUrl);
    }

    checkPermission(rightCode: string, path: string) {
        path = path.replace(/^\//g, "");

        if (globalVar.AUTHORIZATION == null) {
            globalVar.AUTHORIZATION = JSON.parse(localStorage.getItem(storageKey.AUTHORIZATION));
        }

        return globalVar.AUTHORIZATION[path]?.includes(`,${rightCode},`);
    }

    getListAuthorization() {
        return this.httpClient.get(this.authorizationsUrl);
    }

    keepalive() {
        return this.post({}, this.keepaliveUrl);
    }

    logout() {
        return this.post({}, this.logoutUrl);
    }

    doLogout() {
        this.logout().toPromise().then(result => {
        }, error => {
            console.log(error);
        });

        this.forceLogout();
    }

    forceLogout() {
        this.clearStorage();
        this.router.navigate([AppRoutingUrl.common.login]);
    }

    clearStorage() {
        globalVar.AUTHORIZATION = null;
        localStorage.removeItem(storageKey.USER);
        localStorage.removeItem(storageKey.MENU);
        localStorage.removeItem(storageKey.AUTHORIZATION);
        this.secureStorageService.removeItem(storageKey.TOKEN);
    }

    filterMenu(list: AmObject[]) {
        const listFiltered: AmObject[] = [];

        list.forEach((item, index) => {
            if (item.objectType === "G" || this.checkPermission("R", item.path)) {
                listFiltered.push(item);
            }
        });

        return listFiltered;
    }

    buildTreeMenu(list: AmObject[]) {
        const menu: any[] = [];
        const childrenOf = {};
        let id;
        let label;
        let icon;
        let parentId;

        list = this.filterMenu(list);

        for (const data of list) {
            if (data.parentId === undefined) {
                continue;
            }

            const item: any = {};

            id = data.objectId;
            label = data.name;
            icon = data.icon;
            parentId = data.parentId;
            childrenOf[id] = childrenOf[id] || [];

            item.label = label;
            item.objectType = data.objectType;
            item.routerLink = [data.path];
            item.items = childrenOf[id];

            if (Utils.isNullOrUndefined(icon)) {
                item.icon = "fa fa-cubes";
            } else {
                item.icon = icon;
            }

            if (parentId !== 0) {
                // init its parent's children object
                childrenOf[parentId] = childrenOf[parentId] || [];
                // push it into its parent's children object
                childrenOf[parentId].push(item);
            } else {
                menu.push(item);
            }
        }

        return menu;
    }
}
