/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth/auth.service";

@Directive({
    selector: '[appPermissions]'
})
export class HasPermissionDirective {
    requirePermission: string[];
    rejectTemplate: TemplateRef<any>;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
        protected router: Router,
    ) {
    }

    checkPermission(rightCode: string) {
        return this.authService.checkPermission(rightCode, this.router.url);
    }

    @Input("appPermissions")
    set tsPermissionsOnly(requirePermission: string[]) {
        this.requirePermission = requirePermission;
        this.updateView();
    }

    @Input("appPermissionsReject")
    set tsPermissionsOnlyReject(rejectTemplate: TemplateRef<any>) {
        this.rejectTemplate = rejectTemplate;
        this.updateView();
    }

    hasPermissions(requirePermissions: string[]): boolean {
        for (const requireP of requirePermissions) {
            if (this.checkPermission(requireP)) {
                return true;
            }
        }
        return false;
    }

    updateView() {
        this.viewContainer.clear();
        if (this.hasPermissions(this.requirePermission)) {
            this.viewContainer.createEmbeddedView(this.templateRef);

        } else {
            if (this.rejectTemplate) {
                this.viewContainer.createEmbeddedView(this.rejectTemplate);
            } else {
                this.viewContainer.clear();
            }
        }
    }

}
