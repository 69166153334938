import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../_app-core/customizes/view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {RptOrgStatusService} from "../../services/report/rpt-org-status.service";
import {OrganizationStatusService} from "../../services/common/organization-status.service";

@Component({
    selector: 'app-rpt-org-status',
    templateUrl: './rpt-org-status.component.html',
    styleUrls: ['./rpt-org-status.component.scss']
})
export class RptOrgStatusComponent extends BaseComponent implements OnInit {
    listData: any[] = [];
    listStatus: any[] = [];
    statusOptions: any[];
    statusSelected;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private orgStatusService: OrganizationStatusService,
        protected service: RptOrgStatusService,
    ) {
        super(router, route);

        this.statusOptions = [
            {label: this.messageService.translate("label.rptOrgStatus.enable"), value: 'ORG_NEW,ORG_ENABLE'},
            {label: this.messageService.translate("label.rptOrgStatus.disable"), value: 'ORG_DISABLE,ORG_DELETED'},
            {label: this.messageService.translate("label.rptOrgStatus.expiring"), value: 'ORG_EXPIRING'},
            {label: this.messageService.translate("label.rptOrgStatus.expired"), value: 'ORG_EXPIRED'},
        ];
    }

    ngOnInit(): void {
        this.executeHttpRequest(this.orgStatusService.listAll(), result => {
            this.listStatus = result.listData;
        });

        this.viewReport();
    }

    viewReport(status?: string) {
        this.executeHttpRequest(this.service.listByStatus(status), result => {
            this.listData = result.listData;
            this.listData.forEach(item => {
                const diff = item.thruDate - new Date().getTime();

                if (diff > 0) {
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    item.daysLeft = diffDays;
                } else {
                    item.daysLeft = 0;
                }
            });
        });
    }
}
