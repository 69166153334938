/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Utils {
    static isNullOrUndefined<T>(...objs: any): boolean {
        for (const item of objs) {
            return typeof item === 'undefined' || item === null;
        }
    }
}
