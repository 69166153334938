<p-table #dt *ngIf="productId" [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
         [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
         [rowHover]="true" dataKey="productId" (onFilter)="onTableFilter($event)"
         currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

    <ng-template pTemplate="caption">
        <app-general-toolbar [isTableToolbar]="true" [preCreate]="preCreate" [preDeleteMany]="preDeleteMany"
                             [isAllowDeleteMany]="isAllowDeleteMany()"></app-general-toolbar>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="price">
                {{"label.mngProduct.price" | translate}}
                <p-sortIcon field="price"></p-sortIcon>
            </th>
            <th pSortableColumn="currency">
                {{"label.mngProduct.currency" | translate}}
                <p-sortIcon field="currency"></p-sortIcon>
            </th>
            <th pSortableColumn="minQuantity">
                {{"label.mngProduct.minQuantity" | translate}}
                <p-sortIcon field="minQuantity"></p-sortIcon>
            </th>
            <th pSortableColumn="storeName">
                {{"label.mngProduct.storeName" | translate}}
                <p-sortIcon field="storeName"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
                <p-columnFilter type="numeric" field="price"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="currency" matchMode="contains"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="numeric" field="minQuantity"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="storeName" matchMode="contains"></p-columnFilter>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-price>
        <tr class="data-row" showDelay="700" pTooltip="{{price.price}}" tooltipPosition="top">
            <td>
                <p-tableCheckbox [value]="price"></p-tableCheckbox>
            </td>
            <td (dblclick)="copyMessage(price.price)">
                <div class="row-control">
                    <app-table-control [entity]="price" [preView]="preView" [preCopy]="preCopy"
                                       [preUpdate]="preUpdate" [preDelete]="preDelete"></app-table-control>
                </div>
                <div class="row-title">{{price.price}}</div>
            </td>
            <td>{{price.currency}}</td>
            <td>{{price.minQuantity}}</td>
            <td>{{price.storeName}}</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3">{{'common.datatable.emptyMessage'|translate}}</td>
        </tr>
    </ng-template>
</p-table>

<app-dialog [parentComponent]="this" [form]="simpleForm" width="50vw">
    <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
        <div class="row mt-2 mb-4">
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtPrice" value="label.mngProduct.price"
                               required="true"></app-label>
                    <p-inputNumber id="txtPrice" name="txtPrice" [(ngModel)]="selectedEtt.price" [disabled]="isDisable"
                                   #txtPrice="ngModel" pKeyFilter="pint" required maxlength="50"></p-inputNumber>
                    <app-validator-message [templateVar]="txtPrice"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtCurrency" value="label.mngProduct.currency"
                               required="true"></app-label>
                    <input id="txtCurrency" name="txtCurrency" type="numeric" pInputText
                           [(ngModel)]="selectedEtt.currency" [disabled]="isDisable"
                           #txtCurrency="ngModel" required maxlength="50"/>
                    <app-validator-message [templateVar]="txtCurrency"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtMinQuantity" value="label.mngProduct.minQuantity"
                               required="true"></app-label>
                    <p-inputNumber id="txtMinQuantity" name="txtMinQuantity" [(ngModel)]="selectedEtt.minQuantity"
                                   [disabled]="isDisable" #txtMinQuantity="ngModel" pKeyFilter="pint" required
                                   maxlength="50"></p-inputNumber>
                    <app-validator-message [templateVar]="txtMinQuantity"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>
            </div>
            <div class="col-12 col-md-6 py-0">
                <div class="p-field">
                    <app-label for="txtStoreName" value="label.mngProduct.storeName"></app-label>
                    <div class="p-inputgroup">
                        <input id="txtStoreName" name="txtStoreName" type="text" pInputText
                               [(ngModel)]="selectedEtt.storeName" [disabled]="isDisable"
                               #txtStoreName="ngModel" (click)="op.toggle($event)" readonly maxlength="50"/>
                        <button type="button" pButton pRipple icon="pi pi-times" styleClass="p-button-danger"
                                (click)="clearCurrentStore()"></button>
                    </div>
                    <app-validator-message [templateVar]="txtStoreName"
                                           [isSubmitted]="isSubmitted"></app-validator-message>
                </div>

                <p-overlayPanel #op appendTo="body">
                    <ng-template pTemplate>
                        <app-mng-product-store isLov="true"
                                               (selectedLovChange)="onChooseStore($event); op.hide()">
                        </app-mng-product-store>
                    </ng-template>
                </p-overlayPanel>
            </div>
        </div>

        <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                class="{{'common.button.class.dialog.save'|translate}}"></button>
    </form>
</app-dialog>
