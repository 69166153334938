import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../_app-core/customizes/view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {RptOrgStatusService} from "../../services/report/rpt-org-status.service";
import {OrganizationStatusService} from "../../services/common/organization-status.service";
import {RptOrgInfoService} from "../../services/report/rpt-org-info.service";

@Component({
    selector: 'app-rpt-org-info',
    templateUrl: './rpt-org-info.component.html',
    styleUrls: ['./rpt-org-info.component.scss']
})
export class RptOrgInfoComponent extends BaseComponent implements OnInit {
    listData: any[] = [];
    listStatus: any[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected service: RptOrgInfoService,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.executeHttpRequest(this.service.listAll(), result => {
            this.listData = result.listData;
        });
    }
}
