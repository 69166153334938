import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {StringUtils} from "../../../common/string-utils";
import {storageKey} from "../../../../app-constant";
import {Router} from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
    @Input() menuName: string;
    home: MenuItem;
    items: MenuItem[];

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.home = {icon: 'pi pi-home', routerLink: '/'};
        if (StringUtils.isNullOrEmpty(this.menuName)) {
            this.items = [{label: this.extractMenuName()}];
        } else {
            this.items = [{label: this.menuName}];
        }
    }

    extractMenuName() {
        let menus = localStorage.getItem(storageKey.MENU);
        menus = menus.substr(0, menus.indexOf("\"" + this.router.url.substr(1) + "\""));
        menus = menus.substr(menus.lastIndexOf("\"label\":\"") + 9);
        menus = menus.substr(0, menus.indexOf("\""));
        return menus;
    }

}
