import {Component, Input, OnInit} from '@angular/core';
import {StringUtils} from "../../../common/string-utils";

@Component({
    selector: 'app-scroll-panel',
    templateUrl: './scroll-panel.component.html',
    styleUrls: ['./scroll-panel.component.scss']
})
export class ScrollPanelComponent implements OnInit {
    @Input() scrollY = true;
    @Input() scrollX = true;
    @Input() width: string;
    @Input() height: string;


    constructor() {
    }

    ngOnInit(): void {
        this.scrollX = !StringUtils.isNullOrEmpty(this.width);
        this.scrollY = !StringUtils.isNullOrEmpty(this.height);
    }

}
