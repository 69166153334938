import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {AvailableAccountService} from "../../../services/common/available-account.service";
import {Account} from "../../../models/account/account";
import {ProductStore} from "../../../models/product-store/product-store";

@Component({
    selector: 'app-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent extends SimpleCrudLazyComponent implements OnInit {
    @Output() selectedEttChange = new EventEmitter<Account>();
    @ViewChild('dt') table: Table;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
    }

    onChoose(ett) {
        this.selectedEttChange.emit(ett);
    }

    getEttClass(): any {
        return Account;
    }

    getEttKey(): string {
        return "userId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return AvailableAccountService;
    }
}
