import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";
import {Observable} from "rxjs";
import {LazyLoadEvent} from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class UserTokenCodeService extends AbstractCrudService {
    customerId: string;

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.CUSTOMER + `/${this.customerId}/tokenCodes`;
    }

    getDefaultSortField(): string {
        return "tokenCodeId";
    }
}
