import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import 'reflect-metadata';
import {Calendar} from "primeng/calendar";
import {environment} from "../environments/environment";
import {storageKey} from "./app-constant";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    layoutMode = 'static';
    lightMenu = true;
    topbarColor = 'layout-topbar-deeporange';
    inlineUser = false;
    isRTL = false;
    inputStyle = 'outlined';
    ripple = true;

    constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
    }

    ngOnInit() {
        this.config.ripple = true;
        this.config.setTranslation(this.translateService.instant("primeng"));
        Calendar.prototype.getDateFormat = () => this.translateService.instant("config.calendarDateFormat");

        // Set app version
        if (environment.appVersion !== localStorage.getItem(storageKey.APP_VERSION)) {
            localStorage.setItem(storageKey.APP_VERSION, environment.appVersion);
        }
    }
}
