/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

export class Language {
    languageId: number;
    languageCode: string;
    languageName: string;


    constructor(languageId: number, languageCode: string, languageName: string) {
        this.languageId = languageId;
        this.languageCode = languageCode;
        this.languageName = languageName;
    }
}

