/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 06/09/2021.
 */

import {SimpleCrudComponent} from "./simple-crud-component";

export abstract class SimpleCrudLazyComponent extends SimpleCrudComponent {
    abstract getTableControl(): any;

    isLazyTable(): boolean {
        return this.getTableControl()?.lazy;
    }

    getGlobalFilterFields(): any[] {
        return this.getTableControl()?.globalFilterFields;
    }
}
