/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 07/09/2021.
 */

import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

@Directive({
    selector: '[appValidateRegex]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidateRegexDirective, multi: true}]
})
export class ValidateRegexDirective implements Validator {
    @Input('appValidateRegex') pattern = '';
    @Input('appValidateRegexMsg') msg = '';

    validate(control: AbstractControl): ValidationErrors | null {
        return this.pattern ? regexValidator(this.msg, new RegExp(this.pattern, 'i'))(control) : null;
    }
}

export function regexValidator(msg: string, nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valid = nameRe.test(control.value);
        return !valid ? {regex: {message: msg, value: control.value}} : null;
    };
}
