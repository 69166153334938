import {LazyLoadEvent} from "primeng/api";

export class TableData {
    lastEvent: LazyLoadEvent;
    listEtt: any[] = [];
    listFiltered: any[] = [];
    totalRecords: number;

    constructor(lastEvent?: LazyLoadEvent, listObject?: any[], totalRecords?: number) {
        this.listEtt = listObject;
        this.lastEvent = lastEvent;
        this.totalRecords = totalRecords;
    }
}
