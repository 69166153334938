<p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
         [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
         [rowHover]="true"
         dataKey="tokenCodeId" (onFilter)="onTableFilter($event)"
         currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="code">
                {{"label.mngTokenCode.code" | translate}}
                <p-sortIcon field="code"></p-sortIcon>
            </th>
            <th pSortableColumn="createDate">
                {{"label.mngTokenCode.createDate" | translate}}
                <p-sortIcon field="createDate"></p-sortIcon>
            </th>
            <th pSortableColumn="amount">
                {{"label.mngTokenCode.amount" | translate}}
                <p-sortIcon field="hash"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
                {{"label.mngTokenCode.status" | translate}}
                <p-sortIcon field="status"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th>
                <p-columnFilter type="text" field="code" matchMode="contains"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="date" field="createDate" matchMode="dateIs">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-calendar (onSelect)="filter($event)" [monthNavigator]="true" [yearNavigator]="true"
                                    [showButtonBar]="true" [yearRange]="getYearRange()" appendTo="body"></p-calendar>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="numeric" field="amount"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter field="status" matchMode="equals">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown showClear="true" [ngModel]="value" [options]="comboboxTokenStatus" optionLabel="label"
                                    optionValue="value" placeholder="{{'common.label.cbChoose'|translate}}"
                                    (onChange)="filter($event.value)">
                        </p-dropdown>
                    </ng-template>
                </p-columnFilter>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-tokenCode>
        <tr>
            <td>
                {{tokenCode.code}}
            </td>
            <td>
                {{tokenCode.createDate | date : getDateTimeFormat()}}
            </td>
            <td>
                {{tokenCode.amount}}
            </td>
            <td>
                <span *ngIf="tokenCode.status == '2'"
                      [class]="'status-badge status-info'">{{"label.mngTokenCode.status.new" | translate}}</span>
                <span *ngIf="tokenCode.status == '1'"
                      [class]="'status-badge status-success'">{{"label.mngTokenCode.status.used" | translate}}</span>
                <span *ngIf="tokenCode.status == '0'"
                      [class]="'status-badge status-danger'">{{"label.mngTokenCode.status.disable" | translate}}</span>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4">{{'common.datatable.emptyMessage'|translate}}</td>
        </tr>
    </ng-template>
</p-table>
