import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {CountdownComponent} from "ngx-countdown";

@Component({
    selector: 'app-keepalive',
    templateUrl: './keepalive.component.html',
    styleUrls: ['./keepalive.component.scss']
})
export class KeepaliveComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChild('cdMain', {static: false})
    cdMain: CountdownComponent;
    dialogTime = 2 * 60;
    toleranceTime = 5 * 60;
    isShowDialog = false;
    tokenRemain: number;
    countdown: number;
    config: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.initCd();
    }

    ngAfterViewInit(): void {

    }

    keepalive() {
        this.executeHttpRequest(this.authService.keepalive(), result => {
            this.authService.setToken(result.data.token);
            this.isShowDialog = false;
            this.initCd();
        });
    }

    initCd() {
        this.tokenRemain = (this.authService.getTokenExpirationDate().getTime() - (new Date()).getTime()) / 1000;
        console.log("tokenRemain => " + this.tokenRemain);

        const checkerTimeLeft = this.tokenRemain - this.dialogTime;
        if (checkerTimeLeft > 0) {
            this.config = {
                leftTime: this.tokenRemain - this.dialogTime,
            };
        } else {
            this.config = {
                leftTime: this.tokenRemain - this.toleranceTime,
            };
            this.isShowDialog = true;
        }
    }

    handleEvent(event) {
        console.log(event);

        if (event.action === "done") {
            if (!this.isShowDialog) {
                this.config = {
                    leftTime: this.tokenRemain - this.toleranceTime,
                };
                this.isShowDialog = true;
                this.cdMain.restart();

            } else {
                this.authService.forceLogout();
            }
        }
    }

}
