import {Component, OnInit, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";

import {PromotionService} from "../../services/promotion/promotion.service";
import {ProductStoreService} from "../../services/product-store/product-store.service";
import {ProductService} from "../../services/product/product.service";
import {ClassificationService} from "../../services/classification/classification.service";
import {Promotion} from "../../models/promotion/promotion";
import {PromotionStatusService} from "../../services/promotion/promotion-status.service";

@Component({
    selector: 'app-promotion',
    templateUrl: './mng-promotion.component.html',
    styleUrls: ['./mng-promotion.component.scss']
})
export class MngPromotionComponent extends SimpleCrudLazyComponent implements OnInit {

    @ViewChild('dt') table: Table;
    selectedEtt: Promotion;
    listStatus: any[] = [];

    windowHeight: number;

    displayDialogAddProductStore: boolean;
    selectedProductStores: any[] = [];
    productStores: any[] = [];
    totalProductStores: number;

    displayDialogAddProduct: boolean;
    selectedProducts: any[] = [];
    products: any[] = [];
    totalProducts: number;

    displayDialogAddClassification: boolean;
    selectedClassifications: any[] = [];
    classifications: any[] = [];
    totalClassifications: number;

    displayDialogAddGift: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private promotionService: PromotionService,
        private promotionStatusService: PromotionStatusService,
        private productStoreService: ProductStoreService,
        private productService: ProductService,
        private classificationService: ClassificationService
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.windowHeight = window.innerHeight;
        this.executeHttpRequest(this.promotionStatusService.listAll(), result => {
            this.listStatus = [];
            result.listData.forEach((item) => {
                this.listStatus.push({value: item.statusId, label: item.description});
            });
        });
    }

    afterPreAction() {
        if (this.isCreate) {
            this.selectedEtt.productStores = [];
            this.selectedEtt.products = [];
            this.selectedEtt.partyClassifications = [];
            this.selectedEtt.loyaltyPointsIncreaseBy = 'loyaltyPointsIncreasePercent';
            this.selectedEtt.gifts = [];
        } else {
            this.executeHttpRequest(
                this.promotionService.view(this.selectedEtt.storePromotionId), result => {
                    if (result.fromDate) {
                        result.fromDate = new Date(result.fromDate);
                    }
                    if (result.thruDate) {
                        result.thruDate = new Date(result.thruDate);
                    }
                    this.selectedEtt = result;
                    this.selectedEtt.deletedProductStores = [];
                    this.selectedEtt.deletedProducts = [];
                    this.selectedEtt.deletedPartyClassifications = [];
                    this.selectedEtt.deletedGifts = [];
                }
            );
        }
        super.afterPreAction();
    }

    getEttClass(): any {
        return Promotion;
    }

    getEttKey(): string {
        return "storePromotionId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return PromotionService;
    }

    showDialogAddProductStore(): void {
        this.displayDialogAddProductStore = true;
        this.selectedProductStores = this.selectedEtt.productStores;
    }

    loadProductStoresLazy(event: any): void {
        this.executeHttpRequest(this.productStoreService.listLazy(event), result => {
            this.productStores = result?.listData;
            this.totalProductStores = result?.totalRecords;
        });
    }

    addProductStore(): void {
        if (this.selectedProductStores && this.selectedProductStores.length) {
            this.selectedProductStores.forEach((store) => {
                let exists = false;
                for (const s of this.selectedEtt.productStores) {
                    if (s.productStoreId === store.productStoreId) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    this.selectedEtt.productStores.push(store);
                }
            });
        }
        this.displayDialogAddProductStore = false;
    }

    deleteProductStore(rowData: any): void {
        this.selectedEtt.productStores = this.selectedEtt.productStores.filter((item: any) => {
            return item.productStoreId !== rowData.productStoreId;
        });
        if (rowData.parameterName) {
            this.selectedEtt.deletedProductStores.push(rowData.parameterName);
        }
    }

    showDialogAddProduct(): void {
        this.displayDialogAddProduct = true;
        this.selectedProducts = this.selectedEtt.products;
    }

    showDialogAddGift(): void {
        this.displayDialogAddGift = true;
        this.selectedProducts = this.selectedEtt.gifts;
    }

    loadProductsLazy(event: any): void {
        this.executeHttpRequest(this.productService.listLazy(event), result => {
            this.products = result?.listData;
            this.totalProducts = result?.totalRecords;
        });
    }

    addProduct(): void {
        if (this.selectedProducts && this.selectedProducts.length) {
            this.selectedProducts.forEach((product) => {
                if (this.displayDialogAddProduct) {
                    let exists = false;
                    for (const p of this.selectedEtt.products) {
                        if (p.productId === product.productId) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        product.minQuantity = 1;
                        this.selectedEtt.products.push(product);
                    }
                }
                if (this.displayDialogAddGift) {
                    let exists = false;
                    for (const g of this.selectedEtt.gifts) {
                        if (g.productId === product.productId) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        product.giftQuantity = 1;
                        this.selectedEtt.gifts.push(product);
                    }
                }
            });
        }
        this.displayDialogAddProduct = false;
        this.displayDialogAddGift = false;
    }

    deleteProduct(rowData: any): void {
        this.selectedEtt.products = this.selectedEtt.products.filter((item: any) => {
            return item.productId !== rowData.productId;
        });
        if (rowData.storePromotionId) {
            this.selectedEtt.deletedProducts.push(rowData.productId);
        }
    }

    deleteGift(rowData: any): void {
        this.selectedEtt.gifts = this.selectedEtt.gifts.filter((item: any) => {
            return item.productId !== rowData.productId;
        });
        if (rowData.parameterName) {
            this.selectedEtt.deletedGifts.push(rowData.parameterName);
        }
    }

    showDialogAddClassification(): void {
        this.displayDialogAddClassification = true;
        this.selectedClassifications = this.selectedEtt.partyClassifications;
    }

    loadClassificationsLazy(event: any): void {
        this.executeHttpRequest(this.classificationService.listLazy(event), result => {
            this.classifications = result?.listData;
            this.totalClassifications = result?.totalRecords;
        });
    }

    addClassification(): void {
        if (this.selectedClassifications && this.selectedClassifications.length) {
            this.selectedClassifications.forEach((classification) => {
                let exists = false;
                for (const c of this.selectedEtt.partyClassifications) {
                    if (c.partyClassificationId === classification.partyClassificationId) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    this.selectedEtt.partyClassifications.push(classification);
                }
            });
        }
        this.displayDialogAddClassification = false;
    }

    deleteClassification(rowData: any): void {
        this.selectedEtt.partyClassifications = this.selectedEtt.partyClassifications.filter((item: any) => {
            return item.partyClassificationId !== rowData.partyClassificationId;
        });
        if (rowData.parameterName) {
            this.selectedEtt.deletedPartyClassifications.push(rowData.parameterName);
        }
    }

    approve(): void {
        if (this.selectedEtt && this.selectedEtt.storePromotionId) {
            this.promotionService.approve(this.selectedEtt.storePromotionId).subscribe(() => {
                this.onLoadList().then();
            });
        }
    }

}
