<div class="row layout-dashboard">
    <div class="col-12 col-md-3" *ngIf="isMiraAdmin()">
        <div class="overview-box card">
            <div class="overview-box-value"><h1>{{partnerActiveData}}</h1></div>
            <div class="overview-box-title">ĐỐI TÁC ĐANG SỬ DỤNG</div>
        </div>
    </div>
    <div class="col-12 col-md-3" *ngIf="isMiraAdmin()">
        <div class="overview-box card">
            <div class="overview-box-value"><h1>{{expirationPartnerData}}</h1></div>
            <div class="overview-box-title">ĐỐI TÁC HẾT HẠN SAU 3 THÁNG</div>
        </div>
    </div>
    <div class="col-12 col-md-3" *ngIf="isMiraAdmin()"></div>
    <div class="col-12 col-md-3" *ngIf="isMiraAdmin()"></div>


    <div class="col-12 {{isMiraAdmin() ? 'col-md-6' : 'col-md-12'}} pt-4" *ngIf="isMiraAdmin()">
        <div class="card card-w-title" >
            <p-chart type="line" [data]="partnerGrowthData" [options]="chartOptions" height="300"></p-chart>
        </div>
    </div>

    <div class="col-12 {{isMiraAdmin() ? 'col-md-3' : 'col-md-6'}} pt-4" *ngIf="isMiraAdmin() || isPartnerAdmin()">
        <div class="card card-w-title" >
            <p-chart type="polarArea" [data]="totalCustEarnData" [options]="totalCustEarnData?.chartOptions || chartOptions" height="300" ></p-chart>
        </div>
    </div>

    <div class="col-12 {{isMiraAdmin() ? 'col-md-3' : 'col-md-6'}} pt-4" *ngIf="isMiraAdmin() || isPartnerAdmin()">
        <div class="card card-w-title" >
            <p-chart type="polarArea" [data]="totalCustRedeemData" [options]="totalCustRedeemData?.chartOptions || chartOptions" height="300" ></p-chart>
        </div>
    </div>

    <div class="col-12 col-md-6 pt-4" *ngIf="isMiraAdmin() || isPartnerAdmin()">
        <div class="card card-w-title" >
            <p-chart type="line" [data]="paymentTransSummaryData" [options]="chartOptions" height="300"></p-chart>
        </div>
    </div>

    <div class="col-12 col-md-6 pt-4" *ngIf="isMiraAdmin() || isPartnerAdmin()">
        <div class="card card-w-title" >
            <p-chart type="line" [data]="usingVoucherData" [options]="chartOptions" height="300"></p-chart>
        </div>
    </div>

</div>
