/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'}
)
export class LoaderService {
    isLoading = new Subject<boolean>();

    show() {
        this.isLoading.next(true);
    }

    hide() {
        this.isLoading.next(false);
    }
}
