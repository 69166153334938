<div class="row mt-2 mb-4">
  <div class="p-col-fixed py-0" style="width: 200px;">
    <p-button styleClass="p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="earn()"
              label="Earn"></p-button>
  </div>
  <div class="p-col-fixed py-0" style="width: 200px;">
    <p-button styleClass="p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="redeem()"
              label="Redeem"></p-button>
  </div>
  <div class="p-col-fixed py-0" style="width: 200px;">
    <p-button styleClass="p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="issueTokenCode()"
              label="Issue Token Code"></p-button>
  </div>
</div>
<div class="row mt-2 mb-4">
  <div class="p-col-fixed pt-2 pb-0" style="width: 75px;">
    <label for="fromDate">{{"label.rptLoyaltyInfo.fromDate" | translate}}</label>
  </div>
  <div class="p-col-fixed py-0" style="width: 190px;">
    <div class="p-field">
      <p-calendar id="fromDate" name="fromDate" appendTo="body"
                  [(ngModel)]="crudService.fromDate" monthNavigator="true"
                  yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true" [showSeconds]="true" showButtonBar="true"></p-calendar>
    </div>
  </div>
  <div class="p-col-fixed pt-2 pb-0" style="width: 86px;">
    <label for="thruDate">{{"label.rptLoyaltyInfo.thruDate" | translate}}</label>
  </div>
  <div class="p-col-fixed py-0" style="width: 190px;">
    <div class="p-field">
      <p-calendar id="thruDate" name="thruDate" appendTo="body"
                  [(ngModel)]="crudService.thruDate" monthNavigator="true"
                  yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true" [showSeconds]="true" showButtonBar="true"></p-calendar>
    </div>
  </div>

  <div class="p-col-fixed py-0" style="width: 100px;">
    <p-button icon="pi pi-search" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="onLoadList()"
              title="{{ 'common.button.search'|translate }}"></p-button>
  </div>
</div>

<p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
         [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
         [rowHover]="true"
         dataKey="tokenCodeId" (onFilter)="onTableFilter($event)"
         currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="transactionId">
        {{"label.mngTransaction.transactionId" | translate}}
        <p-sortIcon field="transactionId"></p-sortIcon>
      </th>
      <th pSortableColumn="transType">
        {{"label.mngTransaction.transType" | translate}}
        <p-sortIcon field="transType"></p-sortIcon>
      </th>
      <th pSortableColumn="postedDate">
        {{"label.mngTransaction.postedDate" | translate}}
        <p-sortIcon field="postedDate"></p-sortIcon>
      </th>
      <th pSortableColumn="transAmount">
        {{"label.mngTransaction.transAmount" | translate}}
        <p-sortIcon field="transAmount"></p-sortIcon>
      </th>
      <th pSortableColumn="balance">
        {{"label.mngTransaction.balance" | translate}}
        <p-sortIcon field="balance"></p-sortIcon>
      </th>
      <th pSortableColumn="description">
        {{"label.mngTransaction.description" | translate}}
        <p-sortIcon field="description"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th>
        <p-columnFilter type="text" field="transactionId" matchMode="contains"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="transType" matchMode="contains"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="postedDate" matchMode="dateIs">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar (onSelect)="filter($event)" [monthNavigator]="true" [yearNavigator]="true"
                        [showButtonBar]="true" [yearRange]="getYearRange()" appendTo="body"></p-calendar>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="transAmount"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="balance"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="description"></p-columnFilter>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-trans>
    <tr>
      <td>
        {{trans.transactionId}}
      </td>
      <td>
        {{trans.transType}}
      </td>
      <td>
        {{trans.postedDate | date : getDateTimeFormat()}}
      </td>
      <td>
        {{trans.transAmount}}
      </td>
      <td>
        {{trans.balance}}
      </td>
      <td>
        {{trans.description}}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">{{'common.datatable.emptyMessage'|translate}}</td>
    </tr>
  </ng-template>
</p-table>

<div class="row mt-2 mb-2">
  <div class="p-col-fixed pt-2 pb-0" style="width: 75px;">
    <label for="amount"></label>
  </div>
  <div class="p-col-fixed py-0" style="width: 190px;">
    <div class="p-field">

    </div>
  </div>
</div>
<app-dialog [parentComponent]="this" [form]="simpleForm">
  <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">

    <div class="row mt-2 mb-4">
      <div class="col-12 col-md-4 py-0">
        <div class="p-field">
          <app-label for="amount" value="Amount" required="true"></app-label>
          <p-inputNumber id="amount" name="amount"
                         [(ngModel)]="selectedEtt.amount"
												 #amount="ngModel" required></p-inputNumber>
          <app-validator-message [templateVar]="amount" [isSubmitted]="isSubmitted"></app-validator-message>
        </div>
      </div>
      <div class="col-12 col-md-4 py-0">
        <div class="p-field">
          <app-label for="description" value="Description" required="true"></app-label>
          <input id="description" name="description" type="text" pInputText
                 [(ngModel)]="selectedEtt.description" [disabled]="isDisable"
								 #description="ngModel" required/>
          <app-validator-message [templateVar]="description" [isSubmitted]="isSubmitted"></app-validator-message>
        </div>
      </div>
      <div class="col-12 col-md-4 py-0">
        <div class="p-field">
          <app-label for="checkNumber" value="Check Number"></app-label>
          <input id="checkNumber" name="checkNumber" type="text" pInputText
                 [(ngModel)]="selectedEtt.checkNumber" [disabled]="isDisable"
								 #checkNumber="ngModel"/>
        </div>
      </div>
    </div>

    <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
            *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
            class="{{'common.button.class.dialog.save'|translate}}"></button>
  </form>
</app-dialog>