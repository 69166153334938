<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="organizationId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"Hạng khách hàng" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="description">
                        {{"Hạng khách hàng" | translate}}
                        <p-sortIcon field="description"></p-sortIcon>
                    </th>
                    <th pSortableColumn="requireMinPoint">
                        {{"Điểm yêu cầu" | translate}}
                        <p-sortIcon field="requireMinPoint"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="description" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="requireMinPoint" matchMode="numeric"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr class="data-row" showDelay="700" pTooltip="{{item.partyClassificationId}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(item.description)">
                        <div class="row-control">
                            <app-table-control [entity]="item" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                            </app-table-control>
                        </div>
                        <div class="row-title">{{item.description}}</div>
                    </td>
                    <td>{{ item.requireMinPoint|number }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">

            <div class="row mt-2 mb-4">
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="description" value="Hạng khách hàng"
                                   required="true"></app-label>
                        <input id="description" name="description" type="text" pInputText
                               [(ngModel)]="selectedEtt.description" [disabled]="isDisable"
															 #description="ngModel" required/>
                        <app-validator-message [templateVar]="description" [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="requireMinPoint" value="Điểm yêu cầu" required="true"></app-label>
                        <p-inputNumber id="requireMinPoint" name="requireMinPoint"
                                       [(ngModel)]="selectedEtt.requireMinPoint" [disabled]="isDisable"
																			 #requireMinPoint="ngModel" required></p-inputNumber>
                        <app-validator-message [templateVar]="requireMinPoint"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
            </div>

            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>
</app-telsoft>
