/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 06/09/2021.
 */

import {CrudComponent} from "./crud-component";
import {TableData} from "../../models/lazy-load-data/table-data";
import {LazyLoadEvent} from 'primeng/api';
import {Utils} from "../../common/utils";
import {AlertMessages} from "../../models/messages/alertMessages";


export abstract class SimpleCrudComponent extends CrudComponent {
    hiddenCols = [];
    listSelectedEtt = [];
    selectedEtt: any = {};
    oldEntity: string;
    tableData = new TableData();
    // tslint:disable-next-line:variable-name
    _selectedColumns: any[] = [];

    abstract getEttKey(): string;

    abstract getEttClass(): any;

    abstract getServiceClass(): any;

    isReloadList(): boolean {
        return false;
    }

    isLazyTable(): boolean {
        return false;
    }

    isLocalSave(): boolean {
        return false;
    }

    isVisibleCol(name: string): boolean {
        const col = this._selectedColumns?.find(x => x.field === name);
        return !Utils.isNullOrUndefined(col);
    }

    get unSelectedColumnCount(): number {
        return this.hiddenCols.length - this._selectedColumns?.length;
    }

    get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        this._selectedColumns = this.hiddenCols.filter(col => val.includes(col));
    }

    isAllowDeleteMany(): boolean {
        return super.isAllowDeleteMany(this.listSelectedEtt);
    }

    async loadLazyTable(event: LazyLoadEvent) {
        await super.lazyLoadTable(event, this.crudService, this.getEttClass(), result => {
            this.tableData = result;
        });
    }

    onTableFilter(event) {
        if (event.filteredValue?.length !== this.tableData.totalRecords) {
            this.tableData.listFiltered = event.filteredValue;
        }
    }

    async onLoadEtt(itemId) {
        let ett;
        await this.executeHttpRequest(this.crudService.view(itemId), result => {
            ett = result;
        }, this.getEttClass());
        return ett;
    }

    async onLoadList() {
        this.tableData.listEtt = [];
        this.tableData.totalRecords = 0;

        if (this.isLazyTable()) {
            await this.loadLazyTable(this.tableData.lastEvent);
        } else {
            await this.executeHttpRequest(this.crudService.listAll(), result => {
                this.tableData.listEtt = result;
                this.tableData.totalRecords = this.tableData.listEtt.length;
            }, this.getEttClass());
        }
    }

    async onCreate(alertMessages?: AlertMessages, reloadList?: boolean): Promise<any> {
        let ett = null;

        if (!this.isLocalSave()) {
            await this.executeHttpRequest(this.crudService.create(this.selectedEtt), result => {
                ett = result;
                this.messageService.toastCreateSuccess();
            }, this.getEttClass(), Utils.isNullOrUndefined(alertMessages) ? this.otherMessage1 : alertMessages);

        } else {
            ett = this.selectedEtt;
            reloadList = false;
        }

        if (!Utils.isNullOrUndefined(ett)) {
            if (reloadList || this.isReloadList()) {
                await this.onLoadList();
            } else {
                if (Utils.isNullOrUndefined(this.tableData.listEtt)) {
                    this.tableData.listEtt = [];
                }
                this.tableData.listEtt.unshift(ett);
                this.tableData.totalRecords = this.tableData.listEtt.length;

                if (this.tableData.listFiltered) {
                    this.tableData.listFiltered.unshift(ett);
                }
            }
            this.selectedEtt = this.cloneEtt(ett, this.getEttClass());
        }
    }

    async onUpdate(alertMessages?: AlertMessages, reloadList?: boolean): Promise<any> {
        let ett = null;

        if (!this.isLocalSave()) {
            await this.executeHttpRequest(this.crudService.update(this.selectedEtt[this.getEttKey()], this.selectedEtt), result => {
                ett = result;
                this.messageService.toastUpdateSuccess();
            }, this.getEttClass(), Utils.isNullOrUndefined(alertMessages) ? this.otherMessage1 : alertMessages);

        } else {
            ett = this.selectedEtt;
            reloadList = false;
        }

        if (!Utils.isNullOrUndefined(ett)) {
            if (reloadList || this.isReloadList()) {
                await this.onLoadList();
            } else {
                for (const index in this.tableData.listEtt) {
                    if (this.tableData.listEtt[index][this.getEttKey()] === this.selectedEtt[this.getEttKey()]) {
                        this.tableData.listEtt[index] = ett;
                        this.selectedEtt = this.cloneEtt(ett, this.getEttClass());
                        break;
                    }
                }

                if (this.tableData.listFiltered) {
                    for (const index in this.tableData.listFiltered) {
                        if (this.tableData.listFiltered[index][this.getEttKey()] === this.selectedEtt[this.getEttKey()]) {
                            this.tableData.listFiltered[index] = ett;
                            break;
                        }
                    }
                }
            }
        }
    }

    async onDelete(alertMessages?: AlertMessages, reloadList?: boolean): Promise<any> {
        let isSuccess;

        if (!this.isLocalSave()) {
            isSuccess = await this.callHttpRequest(
                this.crudService.delete(this.selectedEtt[this.getEttKey()]),
                Utils.isNullOrUndefined(alertMessages) ? this.mainMessage : alertMessages
            );
            if (isSuccess) {
                this.messageService.toastDeleteSuccess(this.selectedEtt);
            }

        } else {
            isSuccess = true;
        }

        if (isSuccess) {
            if (reloadList || this.isReloadList() || this.isLazyTable()) {
                await this.onLoadList();
            } else {
                for (const index in this.tableData.listEtt) {
                    if (this.tableData.listEtt[index][this.getEttKey()] === this.selectedEtt[this.getEttKey()]) {
                        this.tableData.listEtt.splice(Number(index), 1);
                        this.tableData.totalRecords = this.tableData.listEtt.length;
                        break;
                    }
                }

                if (this.tableData.listFiltered) {
                    for (const index in this.tableData.listFiltered) {
                        if (this.tableData.listFiltered[index][this.getEttKey()] === this.selectedEtt[this.getEttKey()]) {
                            this.tableData.listFiltered.splice(Number(index), 1);
                            break;
                        }
                    }
                }
            }
            this.listSelectedEtt = [];
        }

        await this.afterDelete();
    }

    async onDeleteMany(alertMessages?: AlertMessages, reloadList?: boolean): Promise<any> {
        let listDeleted = [];

        if (!this.isLocalSave()) {
            for (const item of this.listSelectedEtt) {
                // tslint:disable-next-line:max-line-length
                if (await this.callHttpRequest(this.crudService.delete(item[this.getEttKey()]), Utils.isNullOrUndefined(alertMessages) ? this.mainMessage : alertMessages)) {
                    listDeleted.push(item);
                    this.messageService.toastDeleteSuccess(item);
                }
            }
        } else {
            listDeleted = this.listSelectedEtt;
        }

        if (reloadList || this.isReloadList() || this.isLazyTable()) {
            await this.onLoadList();

        } else {
            for (let i = 0; i < this.tableData.listEtt.length; i++) {
                if (listDeleted.includes(this.tableData.listEtt[i])) {
                    this.tableData.listEtt.splice(i--, 1);
                }
            }
            this.tableData.totalRecords = this.tableData.listEtt.length;

            if (this.tableData.listFiltered) {
                for (let i = 0; i < this.tableData.listFiltered.length; i++) {
                    if (listDeleted.includes(this.tableData.listFiltered[i])) {
                        this.tableData.listFiltered.splice(i--, 1);
                    }
                }
            }
        }
        this.listSelectedEtt = [];

        await this.afterDelete();
    }

    onPreCreate() {
        this.selectedEtt = this.parseToClass({}, this.getEttClass());
        this.afterPreAction();
    }

    onPreCopy(ett: any) {
        this.selectedEtt = this.cloneEtt(ett, this.getEttClass());
        this.afterPreAction();
    }

    onPreDelete(ett: any) {
        this.selectedEtt = ett;
        this.afterPreAction();
    }

    onPreUpdate(ett: any) {
        this.selectedEtt = this.cloneEtt(ett, this.getEttClass());
        this.afterPreAction();
    }

    onPreView(ett: any) {
        this.selectedEtt = this.cloneEtt(ett, this.getEttClass());
        this.afterPreAction();
    }

    afterPreAction() {
        this.oldEntity = JSON.stringify(this.selectedEtt);
    }
}
