/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 20/09/2021.
 */

import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()
export class AppCookie {
    constructor() { }

    getToken(): string {
        return Cookie.get('token');
    }

    setToken(value: string): void {
        Cookie.set('token', value, 0.0138889);
    }

    getUser(): string {
        return Cookie.get('user');
    }

    setUser(value: string): void {
        Cookie.set('user', value, 0.0138889);
    }

    deleteAuth(): void {
        Cookie.delete('token');
        Cookie.delete('user');
    }
}
