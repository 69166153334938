<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="customerId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngCustomer.listCustomer" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="customerCode">
                        {{"label.mngCustomer.customerCode" | translate}}
                        <p-sortIcon field="customerCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="availableBalance">
                        {{"label.mngCustomer.availableBalance" | translate}}
                        <p-sortIcon field="availableBalance"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fullName">
                        {{"label.mngCustomer.fullName" | translate}}
                        <p-sortIcon field="fullName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="phone">
                        {{"label.mngCustomer.phone" | translate}}
                        <p-sortIcon field="phone"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">
                        {{"label.mngCustomer.email" | translate}}
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="disabled">
                        {{"label.mngCustomer.disabled" | translate}}
                        <p-sortIcon field="disabled"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="customerCode" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="availableBalance"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="fullName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="phone" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="email" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="disabled" matchMode="equals">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown showClear="true" [ngModel]="value" [options]="comboboxStatusYes" optionLabel="label"
                                            optionValue="value" placeholder="{{'common.label.cbChoose'|translate}}"
                                            (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-customer>
                <tr class="data-row" showDelay="700" pTooltip="{{customer.customerCode}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(customer.customerCode)">
                        <div class="row-control">
                            <app-table-control [entity]="customer" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                                <button pButton pRipple icon="fa fa-gift" type="button"
                                        class="p-button-rounded p-button-help p-mr-2"
                                        pTooltip="{{'label.mngCustomer.viewListToken'|translate}}" tooltipPosition="top"
                                        (click)="viewTokenList(customer)"></button>
                                <button pButton pRipple icon="fa fa-coins" type="button"
                                        class="p-button-rounded p-button-help p-mr-2"
                                        pTooltip="{{'label.mngCustomer.viewTokenTransaction'|translate}}"
                                        tooltipPosition="top"
                                        (click)="viewTransList(customer)"></button>
                            </app-table-control>
                        </div>
                        <div class="row-title">{{customer.customerCode}}</div>
                    </td>
                    <td>
                        {{customer.availableBalance}}
                    </td>
                    <td>
                        {{customer.fullName}}
                    </td>
                    <td>
                        {{customer.phone}}
                    </td>
                    <td>
                        {{customer.email}}
                    </td>
                    <td>
                        <span *ngIf="customer.disabled == 'Y'"
                              [class]="'status-badge status-danger'">{{"common.label.yes" | translate}}</span>
                        <span *ngIf="customer.disabled != 'Y'"
                              [class]="'status-badge status-success'">{{"common.label.no" | translate}}</span>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="row mt-2 mb-4">
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtCustomerCode" value="label.mngCustomer.customerCode"
                                   required="true"></app-label>
                        <input id="txtCustomerCode" name="txtCustomerCode" type="text" pInputText
                               [(ngModel)]="selectedEtt.customerCode" [disabled]="isDisable || !isCreate"
                               #txtCustomerCode="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtCustomerCode"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtFirstName" value="label.mngCustomer.firstName" required="true"></app-label>
                        <input id="txtFirstName" name="txtFullName" type="text" pInputText
                               [(ngModel)]="selectedEtt.firstName" [disabled]="isDisable"
                               #txtFirstName="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtFirstName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtFirstName" value="label.mngCustomer.middleName"></app-label>
                        <input id="txtMiddleName" name="txtMiddleName" type="text" pInputText
                               [(ngModel)]="selectedEtt.middleName" [disabled]="isDisable"
                               #txtMiddleName="ngModel" maxlength="50"/>
                        <app-validator-message [templateVar]="txtMiddleName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtFirstName" value="label.mngCustomer.lastName" required="true"></app-label>
                        <input id="txtLastName" name="txtLastName" type="text" pInputText
                               [(ngModel)]="selectedEtt.lastName" [disabled]="isDisable"
                               #txtLastName="ngModel" required maxlength="50"/>
                        <app-validator-message [templateVar]="txtLastName"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtPhone" value="label.mngCustomer.phone" required="true"></app-label>
                        <input id="txtPhone" name="txtPhone" type="text" pInputText
                               [(ngModel)]="selectedEtt.phone" [disabled]="isDisable"
                               #txtPhone="ngModel" required maxlength="20"/>
                        <app-validator-message [templateVar]="txtPhone"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtCity" value="label.mngCustomer.city"></app-label>
                        <input id="txtCity" name="txtCity" type="text" pInputText
                               [(ngModel)]="selectedEtt.city" [disabled]="isDisable"
                               #txtCity="ngModel" maxlength="20"/>
                        <app-validator-message [templateVar]="txtCity"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtAddress1" value="label.mngCustomer.address1"></app-label>
                        <input id="txtAddress1" name="txtAddress1" type="text" pInputText
                               [(ngModel)]="selectedEtt.address1" [disabled]="isDisable"
                               #txtAddress1="ngModel" maxlength="200"/>
                        <app-validator-message [templateVar]="txtAddress1"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtEmail" value="label.mngCustomer.email"></app-label>
                        <input id="txtEmail" name="txtEmail" type="text" pInputText [(ngModel)]="selectedEtt.email"
                               [disabled]="isDisable" #txtEmail="ngModel" maxlength="100"/>
                        <app-validator-message [templateVar]="txtEmail"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="cbDisabled" value="label.mngCustomer.disabled" required="true"></app-label>
                        <p-dropdown showClear="true" id="cbDisabled" name="cbDisabled" [(ngModel)]="selectedEtt.disabled"
                                    [options]="comboboxStatusYes" optionLabel="label" optionValue="value"
                                    [filter]="true" placeholder="{{'common.label.cbChoose'|translate}}"
                                    [disabled]="isDisable" required #cbDisabled="ngModel"></p-dropdown>
                        <app-validator-message [templateVar]="cbDisabled"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                    <div class="p-field">
                        <app-label for="txtFromDate" value="label.mngCustomer.fromDate"></app-label>
                        <p-calendar id="txtFromDate" name="txtFromDate" appendTo="body"
                                    [(ngModel)]="selectedEtt.fromDate" #txtFromDate="ngModel" monthNavigator="true"
                                    yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true" required
                                    [showSeconds]="true" showButtonBar="true" [disabled]="true"></p-calendar>
                        <app-validator-message [templateVar]="txtFromDate"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
            </div>

            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>

    <app-dialog-advance [(visible)]="isShowTokenListDialog"
                        header="{{'label.mngCustomer.listToken'|translate}} {{selectedEtt.customerCode}}">
        <app-token-list *ngIf="isShowTokenListDialog" [customerId]="selectedEtt.customerId"></app-token-list>
    </app-dialog-advance>

    <app-dialog-advance [(visible)]="isShowTransListDialog"
                        header="{{'label.mngCustomer.listTransaction'|translate}} {{selectedEtt.customerCode}}">
        <app-transaction-list *ngIf="isShowTransListDialog"
                              [customerId]="selectedEtt.customerId" [customerCode]="selectedEtt.customerCode"></app-transaction-list>
    </app-dialog-advance>
</app-telsoft>
