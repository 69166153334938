import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName, getServerApiUrl} from "../../../app-constant";

@Injectable({
  providedIn: 'root'
})
export class RptTransService extends AbstractCrudService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getParams(fromDate: Date, thruDate: Date, ownerPseudoId: string): string {
    const params: any = {};
    if (fromDate) {
      params.fromDate = new Date(fromDate).getTime();
    }
    if (thruDate) {
      params.thruDate = new Date(thruDate).getTime();
    }
    if (ownerPseudoId) {
      params.ownerPseudoId = ownerPseudoId;
    }
    return new URLSearchParams(params).toString();
  }

  listByDateRange(fromDate: Date, thruDate: Date, ownerPseudoId: string) {
    return this.list(this.getParams(fromDate, thruDate, ownerPseudoId), getServerApiUrl() + '/' + this.getApiName() + '/list');
  }

  uploadReconciliation(contentFile: any, rowStart: any) {
    const formData = new FormData();
    formData.append('contentFile', contentFile);
    if (rowStart) {
      formData.append('rowStart', rowStart);
    }
    return this.postFormData(formData, getServerApiUrl() + '/' + this.getApiName() + '/reconciliation');
  }

  uploadEarn(contentFile: any, rowStart: any) {
    const formData = new FormData();
    formData.append('contentFile', contentFile);
    if (rowStart) {
      formData.append('rowStart', rowStart);
    }
    return this.postFormData(formData, getServerApiUrl() + '/iam-customer/customers/earn-excel');
  }

  getBaseUrl(): string {
    return getServerApiUrl() + '/' + this.getApiName();
  }

  getApiName() {
    return apiName.RPT_TRANS;
  }
}
