import {Component} from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";

@Component({
    selector: 'app-error',
    templateUrl: './app.error.component.html',
})
export class AppErrorComponent {

    constructor(private authService: AuthService) {
    }

    async forceLogout() {
        await this.authService.forceLogout();
    }
}
