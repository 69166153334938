<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>

<p-card>
    <form #f="ngForm" (ngSubmit)="search(f.invalid)">
        <div class="p-fluid">
            <div class="row">
                <div class="col-12 col-md-2 py-0">
                    <div class="p-field">
                        <app-label for="fromMonth" value="From Month" required></app-label>
                        <p-inputNumber id="fromMonth" name="fromMonth" [min]="1" [max]="12"
                                       [(ngModel)]="fromMonth" [format]="false"
                                       #txtfromMonth="ngModel" required></p-inputNumber>
                        <app-validator-message [templateVar]="txtfromMonth"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-2 py-0">
                    <div class="p-field">
                        <app-label for="toMonth" value="To Month" required></app-label>
                        <p-inputNumber id="toMonth" name="toMonth" [min]="1" [max]="12"
                                       [(ngModel)]="toMonth" [format]="false"
                                       #txttoMonth="ngModel" required></p-inputNumber>
                        <app-validator-message [templateVar]="txttoMonth"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-2 py-0">
                    <div class="p-field">
                        <app-label for="year" value="Year" required></app-label>
                        <p-inputNumber id="year" name="year"
                                       [(ngModel)]="year" [format]="false"
                                       #txtyear="ngModel" required></p-inputNumber>
                        <app-validator-message [templateVar]="txtyear"
                                               [isSubmitted]="isSubmitted"></app-validator-message>
                    </div>
                </div>
                <div class="col-12 col-md-1 py-0">
                    <div class="p-field">
                        <label>&nbsp;</label>
                        <button pButton pRipple type="submit"
                                label="{{'common.button.search'|translate}}"
                                icon="{{'common.button.icon.search'|translate}}"
                                class="p-button p-button-info"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <p-table [value]="listData" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" styleClass="mt-3"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="caption">
            <h5 class="p-m-0">Customer</h5>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="customerCode">
                    {{"label.mngCustomer.customerCode" | translate}}
                    <p-sortIcon field="customerCode"></p-sortIcon>
                </th>
                <th pSortableColumn="availableBalance">
                    {{"label.mngCustomer.availableBalance" | translate}}
                    <p-sortIcon field="availableBalance"></p-sortIcon>
                </th>
                <th pSortableColumn="fullName">
                    {{"label.mngCustomer.fullName" | translate}}
                    <p-sortIcon field="fullName"></p-sortIcon>
                </th>
                <th pSortableColumn="phone">
                    {{"label.mngCustomer.phone" | translate}}
                    <p-sortIcon field="phone"></p-sortIcon>
                </th>
                <th pSortableColumn="email">
                    {{"label.mngCustomer.email" | translate}}
                    <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th pSortableColumn="disabled">
                    {{"label.mngCustomer.disabled" | translate}}
                    <p-sortIcon field="disabled"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="customerCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="availableBalance"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="fullName" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="phone" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="email" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="disabled" matchMode="equals">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown showClear="true" [ngModel]="value" [options]="comboboxStatusYes" optionLabel="label"
                                        optionValue="value" placeholder="{{'common.label.cbChoose'|translate}}"
                                        (onChange)="filter($event.value)">
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-customer>
            <tr class="data-row" showDelay="700" pTooltip="{{customer.customerCode}}" tooltipPosition="top">
                <td>
                    {{customer.customerCode}}
                </td>
                <td>
                    {{customer.availableBalance}}
                </td>
                <td>
                    {{customer.fullName}}
                </td>
                <td>
                    {{customer.phone}}
                </td>
                <td>
                    {{customer.email}}
                </td>
                <td>
                        <span *ngIf="customer.disabled == 'Y'"
                              [class]="'status-badge status-danger'">{{"common.label.yes" | translate}}</span>
                    <span *ngIf="customer.disabled != 'Y'"
                          [class]="'status-badge status-success'">{{"common.label.no" | translate}}</span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-card>