<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>
<p-card>
    <p-selectButton [options]="statusOptions" [(ngModel)]="statusSelected" optionLabel="label"
                    optionValue="value" (onChange)="viewReport(statusSelected)" ></p-selectButton>

    <p-table #dt [value]="listData" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" styleClass="mt-3"
             currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="organizationCode">
                    {{"label.rptOrgStatus.organizationCode" | translate}}
                    <p-sortIcon field="organizationCode"></p-sortIcon>
                </th>
                <th pSortableColumn="organizationName">
                    {{"label.rptOrgStatus.organizationName" | translate}}
                    <p-sortIcon field="organizationName"></p-sortIcon>
                </th>
                <th pSortableColumn="packageName">
                    {{"label.rptOrgStatus.packageName" | translate}}
                    <p-sortIcon field="packageName"></p-sortIcon>
                </th>
                <th pSortableColumn="statusName">
                    {{"label.rptOrgStatus.statusName" | translate}}
                    <p-sortIcon field="statusName"></p-sortIcon>
                </th>
                <th pSortableColumn="daysLeft">
                    {{"label.rptOrgStatus.daysLeft" | translate}}
                    <p-sortIcon field="daysLeft"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="organizationCode" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="organizationName" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="packageName" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="status" matchMode="equals">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown showClear="true" [ngModel]="value" [options]="listStatus"
                                        optionLabel="description" optionValue="statusId"
                                        placeholder="{{'common.label.cbChoose'|translate}}"
                                        (onChange)="filter($event.value)">
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="daysLeft" matchMode="contains"></p-columnFilter>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-org>
            <tr>
                <td>
                    {{org.organizationCode}}
                </td>
                <td>
                    {{org.organizationName}}
                </td>
                <td>
                    {{org.packageName}}
                </td>
                <td>
                    {{org.statusName}}
                </td>
                <td>
                    {{org.daysLeft}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'common.datatable.emptyMessage'|translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
