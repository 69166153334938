<div class="layout-topbar">

    <a class="layout-right-panel-button layout-topbar-icon" (click)="appMain.onRightMenuClick($event)" href="#">
        <i class="pi pi-ellipsis-v"></i>
    </a>

    <a href="#" class="layout-menu-button layout-topbar-icon" (click)="appMain.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
    </a>

    <a href="#" class="layout-topbar-logo">
        <img id="topbar-logo" src="assets/layout/images/logo-whychain-removebg.png">
    </a>

    <span class="layout-topbar-search">
        <i class="pi pi-search"></i>
        <p-autoComplete inputId="topbar-search" [(ngModel)]="selected" [suggestions]="results"
                        (completeMethod)="search($event)" field="name" (onSelect)="select($event)" (onFocus)="focus()"
                        [inputStyle]="{'background-color': 'transparent'}"
                        placeholder="{{'common.label.enterSearchTerm' | translate}}"></p-autoComplete>
    </span>

    <ul class="topbar-menu">
        <li class="user-profile" [ngClass]="{'active-topmenuitem fadeInDown': appMain.topbarUserMenuActive}">
            <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)" *ngIf="!app.inlineUser">
                <img src="assets/layout/images/avatar.png" alt="roma-layout"/>
                <div class="layout-profile-userinfo">
                    <span class="layout-profile-name">{{authService.getUser()}}</span>
                    <span class="layout-profile-role">{{authService.getUserInfo().roleTypeName}}</span>
                </div>
            </a>
            <ul class="fadeInDown">
                <li>
                    <a href="javascript:void(0)" (click)="showDialogChangePwd()">
                        <i class="pi pi-fw pi-cog"></i>
                        <span> {{'common.label.changePwd' | translate}}</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="authService.doLogout()">
                        <i class="pi pi-fw pi-sign-out"></i>
                        <span> {{'common.label.logout' | translate}}</span>
                    </a>
                </li>
            </ul>
        </li>
        <li [ngClass]="{'active-topmenuitem fadeInDown': appMain.topbarNotificationMenuActive}">
            <a href="#" class="layout-topbar-icon" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">
                <i class="topbar-icon pi pi-fw pi-bell"></i>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-1.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Lan Ngọc</span>
                            <span class="topbar-menu-role">Đã duyệt thiết kế giao diện</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-2.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Thuý Ngân</span>
                            <span class="topbar-menu-role">Uh, chúng tôi mắc một vấn đề ở đây</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-3.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Khả Ngân</span>
                            <span class="topbar-menu-role">Bạn dường như đã đưa ra thiếu cái gì đó</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-4.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Hà My</span>
                            <span class="topbar-menu-role">Kiểm tra file đính kèm nhé</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-5.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Puka</span>
                            <span class="topbar-menu-role">Biên bản họp đã được đính kèm</span>
                        </div>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>

<app-dialog-advance [(visible)]="isShowDialogChangePwd"
                    header="{{'common.label.changePwd' | translate}}" width="30wv" [isMaximizable]="false" >
    <app-form-change-pwd [(showDialog)]="isShowDialogChangePwd"></app-form-change-pwd>
</app-dialog-advance>
