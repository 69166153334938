import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../services/organization/organization.service";
import {Organization} from "../../models/organization/organization";
import {OrganizationStatusService} from "../../services/common/organization-status.service";
import {OrganizationPackageService} from "../../services/common/organization-package.service";
import {AvailableAccountService} from "../../services/common/available-account.service";
import {Account} from "../../models/account/account";

@Component({
    selector: 'app-mng-organization',
    templateUrl: './mng-organization.component.html',
    styleUrls: ['./mng-organization.component.scss']
})
export class MngOrganizationComponent extends SimpleCrudLazyComponent implements OnInit {
    @ViewChild('dt') table: Table;
    @Output() selectedLovChange = new EventEmitter<any>();
    @Input() isLov: boolean;
    selectedEtt: Organization;
    listStatus: any[] = [];
    listPackage: any[] = [];
    listAccount: any[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private orgStatusService: OrganizationStatusService,
        private orgPackageService: OrganizationPackageService,
        private availableAccountService: AvailableAccountService
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        if (!this.isLov) {
            this.executeHttpRequest(this.orgStatusService.listAll(), result => {
                this.listStatus = result.listData;
            });

            this.executeHttpRequest(this.orgPackageService.listAll(), result => {
                this.listPackage = result.listData;
            });

            this.executeHttpRequest(this.availableAccountService.listAll(), result => {
                this.listAccount = result.listData;
            });
        }
    }

    afterPreAction() {
        if (this.isCreate) {
            this.selectedEtt.status = "ORG_NEW";
            this.selectedEtt.fromDate = new Date();
        }
        super.afterPreAction();
    }

    onChooseLovItem(org: Organization) {
        this.selectedLovChange.emit(org);
    }

    onChooseAdmin(ett: Account) {
        this.selectedEtt.adminId = ett.personId;
        this.selectedEtt.adminUser = ett.username;
    }

    getEttClass(): any {
        return Organization;
    }

    getEttKey(): string {
        return "organizationId";
    }

    getTableControl(): any {
        return this.table;
    }

    getServiceClass() {
        return OrganizationService;
    }
}
