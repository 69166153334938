/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Component, Inject, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Spinkit} from './spinkits';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Subject} from 'rxjs';
import {LoaderService} from "../../../services/common/loader.service";

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss',
        './spinkit-css/sk-line-material.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
    public Spinkit = Spinkit;
    @Input() public backgroundColor = 'rgba(255, 255, 255, 0.8)';
    @Input() public spinner = Spinkit.skLine;
    isSpinnerVisible: Subject<boolean> = this.loaderService.isLoading;

    constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private loaderService: LoaderService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible.next(true);
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible.next(false);
            }
        }, () => {
            this.isSpinnerVisible.next(false);
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible.next(false);
    }
}
