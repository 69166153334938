import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NgModel} from "@angular/forms";

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
    @Input() templateVar: NgModel;
    @Input() for: string;
    @Input() value: string;
    @Input() required = false;
    text: string;

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.text = this.translate.instant(this.value);
    }
}
