export class Account {
    personId: string;
    superAdmin: string;
    roleType: string;
    roleTypeId: string;
    userId: string;
    username: string;
    userFullName: string;
    password: string;
    organizationId: string;
    organizationName: string;
    emailAddress: string;
    disabled: string;
}
