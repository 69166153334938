import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";

@Injectable({
    providedIn: 'root'
})
export class RptLoyaltyInfoService extends AbstractCrudService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    listByDateRange(fromDate: Date, thruDate: Date) {
        return this.list(`fromDate=${fromDate.getTime()}&thruDate=${thruDate.getTime()}`);
    }

    getApiName() {
        return apiName.RPT_LOYALTY_INFO;
    }
}
