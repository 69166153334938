import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {TokenTransactionService} from "../../../services/token-transaction/transaction.service";
import {TokenTransaction} from "../../../models/token-transaction/token-transaction";
import {ActivatedRoute, Router} from "@angular/router";

class Point {
  customerCode: string;
  amount: string;
  description: string;
  checkNumber: number;
  url: string;
}

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent extends SimpleCrudLazyComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('dt') table: Table;
  @Input() customerId;
  @Input() customerCode;

  selectedEtt: Point;

  url?: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    super(router, route);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerId?.currentValue !== changes.customerId?.previousValue) {
      this.crudService.customerId = changes.customerId?.currentValue;
      this.crudService.fromDate = null;
      this.crudService.thruDate = null;
      this.onLoadList();
    }
  }

  async preSave(): Promise<void> {
    this.selectedEtt.customerCode = this.customerCode;
    this.selectedEtt.url = this.url;
  }

  async afterSave(): Promise<void> {
    this.onLoadList();
  }

  getEttClass(): any {
    return TokenTransaction;
  }

  getEttKey(): string {
    return "transactionId";
  }

  getTableControl(): any {
    return this.table;
  }

  getServiceClass() {
    return TokenTransactionService;
  }

  earn(): void {
    this.url = 'earn';
    this.preCreate();
  }

  redeem(): void {
    this.url = 'redeem';
    this.preCreate();
  }

  issueTokenCode(): void {
    this.url = 'issueTokenCode';
    this.preCreate();
  }

}
