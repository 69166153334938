<p-toast key="tst"></p-toast>

<div class="login-body">
    <div class="card login-panel p-fluid">
        <div class="login-panel-content">
            <form #loginForm="ngForm" (ngSubmit)="login(loginForm.invalid)">
                <div class="p-grid">
                    <div class="p-col-12 p-sm-6 p-md-6 logo-container">
                        <img src="assets/layout/images/logo-whychain-removebg.png" height="40"/>
                        <span class="guest-sign-in">WHYCHAIN CORPORATION</span>
                    </div>
                    <div class="p-col-12 username-container">
                        <!-- <h:panelGroup> -->
                        <label>{{'label.login.username'|translate}}</label>
                        <div class="login-input">
                            <input id="txtUsername" name="txtUsername" #txtUsername="ngModel" type="text" pInputText
                                   [(ngModel)]="username" required minlength="3"/>
                            <app-validator-message [templateVar]="txtUsername"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                        <!-- </h:panelGroup> -->
                    </div>
                    <div class="p-col-12 password-container">
                        <!-- <h:panelGroup> -->
                        <label>{{'label.login.password'|translate}}</label>
                        <div class="login-input">
                            <input id="txtPassword" name="txtPassword" #txtPassword="ngModel" pPassword type="password"
                                   [(ngModel)]="password" required minlength="3" [feedback]="false"/>
                            <app-validator-message [templateVar]="txtPassword"
                                                   [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                        <!-- </h:panelGroup> -->
                    </div>
                    <div class="p-col-12 p-sm-6 p-md-6 rememberme-container">
                        <p-checkbox id="chkRemember" name="chkRemember"></p-checkbox>
                        <label> {{'label.login.rememberMe'|translate}}</label>
                    </div>

                    <div class="p-col-12 p-sm-6 p-md-6 forgetpassword-container">
                        <a href="#" class="forget-password">{{'label.login.forgotPassword'|translate}}</a>
                    </div>

                    <div *ngIf="mainMessage.msgs.length !== 0" class="p-col-12 py-0">
                        <small class="p-invalid invalid-msg">{{mainMessage.msgs[0].detail}}</small>
                    </div>

                    <div class="p-col-12 p-sm-6 p-md-6">
                        <button [routerLink]="['/']" pButton type="submit" icon="pi pi-check"
                                label="{{'label.login.signIn'|translate}}"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-dialog-advance [(visible)]="isShowChangePwdDialog" width="30vw" header="{{'label.login.changePwd' | translate}}"
                    [isMaximizable]="false" [msgs]="this.otherMessage1.msgs" >
    <p>{{'label.login.changePwdNote' | translate}}</p>

    <app-form-change-pwd [(showDialog)]="isShowChangePwdDialog"></app-form-change-pwd>
</app-dialog-advance>
