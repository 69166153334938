<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="organizationId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngEvent.listEvent" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="pseudoId">
                        {{"label.mngEvent.eventPseudoId" | translate}}
                        <p-sortIcon field="pseudoId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="itemDescription">
                        {{"label.mngEvent.eventName" | translate}}
                        <p-sortIcon field="itemDescription"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fromDate">
                        {{"common.label.fromDate" | translate}}
                        <p-sortIcon field="fromDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="thruDate">
                        {{"common.label.thruDate" | translate}}
                        <p-sortIcon field="thruDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">
                        {{"common.label.status" | translate}}
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="pseudoId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="itemDescription"></p-columnFilter>
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-columnFilter field="status" matchMode="equals">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown showClear="true" [ngModel]="value" [options]="listStatus" placeholder="{{'common.label.cbChoose'|translate}}"
                                            (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr class="data-row" showDelay="700" pTooltip="{{item.storePromotionId}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(item.pseudoId)">
                        <div class="row-control">
                            <app-table-control [entity]="item" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                            </app-table-control>
                        </div>
                        <div class="row-title">{{item.pseudoId}}</div>
                    </td>
                    <td>{{ item.itemDescription }}</td>
                    <td>{{ item.fromDate | date : getDateTimeFormat() }}</td>
                    <td>{{ item.thruDate | date : getDateTimeFormat() }}</td>
                    <td>{{ item.status }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="iam-scrollable-y" [style]="{maxHeight: (windowHeight - 300) + 'px'}">
                <div class="row mt-2 mb-2">
                    <div class="col-12 col-md-6 py-0">
                        <div class="p-field">
                            <app-label for="pseudoId" value="label.mngEvent.eventPseudoId" required="true"></app-label>
                            <input id="pseudoId" name="pseudoId" type="text" pInputText
                                   [(ngModel)]="selectedEtt.pseudoId" [disabled]="isDisable"
																	 #pseudoId="ngModel" required maxlength="50"/>
                            <app-validator-message [templateVar]="pseudoId" [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 py-0">
                        <div class="p-field">
                            <app-label for="itemDescription" value="label.mngEvent.eventName" required="true"></app-label>
                            <input id="itemDescription" name="itemDescription" type="text" pInputText
                                   [(ngModel)]="selectedEtt.itemDescription" [disabled]="isDisable"
																	 #itemDescription="ngModel" required/>
                            <app-validator-message [templateVar]="itemDescription" [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                </div>
                <p-fieldset legend="{{ 'label.mngPromotion.condition'|translate }}">
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <b>1. Điểm bán áp dụng</b>
                        </div>
                        <div class="p-col-12">
                            <div class="p-field-checkbox mb-0">
                                <p-checkbox name="includeAllProductStores" [(ngModel)]="selectedEtt.includeAllProductStores" [disabled]="isDisable"
                                            binary="true" inputId="includeAllProductStores"></p-checkbox>
                                <label for="includeAllProductStores" class="mb-0">Áp dụng tất cả điểm bán</label>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0 {{ selectedEtt.includeAllProductStores ? 'p-disabled' : '' }}">
                            <p-table [value]="selectedEtt.productStores"
                                     styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines p-fluid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [width]="40" class="p-text-center">STT</th>
                                        <th [width]="220">{{ 'label.mngProductStore.productStoreCode'|translate }}</th>
                                        <th>{{ 'label.mngProductStore.storeName'|translate }}</th>
                                        <th [width]="45" class="p-text-center action-column">
                                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="showDialogAddProductStore()"
                                                      title="{{ 'Add'|translate }}"></p-button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <tr>
                                        <td class="p-text-center">{{ (index + 1)|number }}</td>
                                        <td>{{ rowData['productStoreCode'] }}</td>
                                        <td>{{ rowData['storeName'] }}</td>
                                        <td class="p-text-center action-column">
                                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm" (onClick)="deleteProductStore(rowData)"
                                                      title="{{ 'Delete'|translate }}"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <b>2. Thời gian</b>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="fromDate" value="common.label.fromDate"></app-label>
                                <p-calendar id="fromDate" name="fromDate" appendTo="body"
                                            [(ngModel)]="selectedEtt.fromDate" monthNavigator="true"
                                            yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true"
                                            [showSeconds]="true" showButtonBar="true"
                                            [disabled]="isDisable"></p-calendar>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="thruDate" value="common.label.thruDate"></app-label>
                                <p-calendar id="thruDate" name="thruDate" appendTo="body"
                                            [(ngModel)]="selectedEtt.thruDate" monthNavigator="true"
                                            yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true"
                                            [showSeconds]="true" showButtonBar="true"
                                            [disabled]="isDisable"></p-calendar>
                            </div>
                        </div>
                    </div>
                </p-fieldset>
                <p-fieldset legend="{{ 'common.label.result'|translate }}" styleClass="mt-3">
                    <div class="p-grid">
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="loyaltyPointsIncreaseValue" value="Điểm được nhận" required="true"></app-label>
                                <p-inputNumber name="loyaltyPointsIncreaseValue" [(ngModel)]="selectedEtt.loyaltyPointsIncreaseValue" [disabled]="isDisable"
                                               [min]="0" #loyaltyPointsIncreaseValue="ngModel" required></p-inputNumber>
                                <app-validator-message [templateVar]="loyaltyPointsIncreaseValue" [isSubmitted]="isSubmitted"></app-validator-message>
                            </div>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
            <button pButton pRipple label="{{'Duyệt'|translate}}" (click)="approve()"
                    *ngIf="isView && selectedEtt['statusId'] === 'EventCreated'" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>
</app-telsoft>

<p-dialog header="{{ 'label.mngProductStore.listProductStore'|translate }}" [(visible)]="displayDialogAddProductStore" [style]="{width: '80rem'}">
    <p-table [value]="productStores" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalProductStores"
             [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
             (onLazyLoad)="loadProductStoresLazy($event)" dataKey="productStoreId" [(selection)]="selectedProductStores"
             styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="productStoreCode" [width]="180">{{ 'label.mngProductStore.productStoreCode'|translate }}
                    <p-sortIcon field="productStoreCode"></p-sortIcon>
                </th>
                <th pSortableColumn="storeName">{{ 'label.mngProductStore.storeName'|translate }}
                    <p-sortIcon field="storeName"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <p-columnFilter type="text" field="productStoreCode" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="storeName" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{ rowData['productStoreCode'] }}</td>
                <td>{{ rowData['storeName'] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <p-button label="{{'common.label.add'|translate }}" icon="pi pi-check" styleClass="p-button-raised" (onClick)="addProductStore()"></p-button>
        <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayDialogAddProductStore=false"></p-button>
    </p-footer>
</p-dialog>
