import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SimpleCrudLazyComponent} from "../../../../_app-core/customizes/view/simple-crud-lazy-component";
import {Table} from "primeng/table/table";
import {TokenCode} from "../../../models/token-code/token-code";
import {ActivatedRoute, Router} from "@angular/router";
import {UserTokenCodeService} from "../../../services/token-code/user-token-code.service";

@Component({
    selector: 'app-token-list',
    templateUrl: './token-list.component.html',
    styleUrls: ['./token-list.component.scss']
})
export class TokenListComponent extends SimpleCrudLazyComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('dt') table: Table;
    @Input() customerId;
    comboboxTokenStatus: any[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        this.comboboxTokenStatus = [
            {label: this.messageService.translate("label.mngTokenCode.status.new"), value: "2"},
            {label: this.messageService.translate("label.mngTokenCode.status.used"), value: "1"},
            {label: this.messageService.translate("label.mngTokenCode.status.disable"), value: "0"},
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerId?.currentValue !== changes.customerId?.previousValue) {
            this.crudService.customerId = changes.customerId?.currentValue;
            this.onLoadList();
        }
    }

    ngAfterViewInit(): void {
    }

    getEttClass(): any {
        return TokenCode;
    }

    getEttKey(): string {
        return "tokenCodeId";
    }

    getServiceClass() {
        return UserTokenCodeService;
    }

    getTableControl(): any {
        return this.table;
    }

}
