import {Type} from "class-transformer";

export class Customer {
    customerId: string;
    customerCode: string;
    organizationId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    directions: string;
    phone: string;
    city: string;
    address2: string;
    address1: string;
    fullName: string;
    unitNumber: string;
    birthDate: number;
    email: string;
    disabled: string;
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    thruDate: Date;
    availableBalance: number;
    @Type(() => Date)
    balanceDate: Date;

    getEttName() {
        return this.customerCode;
    }
}
