<p-messages [value]="otherMessage1.msgs"></p-messages>

<form #simpleForm="ngForm" (ngSubmit)="handleChangePwd(simpleForm.invalid)">
    <div class="row mt-2 mb-4">
        <div class="col-12 py-0">
            <div class="p-field">
                <app-label for="txtOldPassword" value="label.login.oldPassword" required="true"></app-label>
                <p-password id="txtOldPassword" name="txtOldPassword" [toggleMask]="true" [feedback]="false"
                            [(ngModel)]="oldPassword" #txtOldPassword="ngModel" required
                            maxlength="20"></p-password>
                <app-validator-message [templateVar]="txtOldPassword"
                                       [isSubmitted]="isSubmitted"></app-validator-message>
            </div>
        </div>
        <div class="col-12 py-0">
            <div class="p-field">
                <app-label for="txtNewPassword" value="label.login.newPassword"
                           required="true"></app-label>
                <p-password id="txtNewPassword" name="txtNewPassword" [toggleMask]="true" [feedback]="false"
                            [(ngModel)]="newPassword" #txtNewPassword="ngModel" required
                            maxlength="20"></p-password>
                <app-validator-message [templateVar]="txtNewPassword"
                                       [isSubmitted]="isSubmitted"></app-validator-message>
            </div>
        </div>
        <div class="col-12 py-0">
            <div class="p-field">
                <app-label for="txtNewPasswordVerify" value="label.login.newPasswordVerify"
                           required="true"></app-label>
                <p-password id="txtNewPasswordVerify" name="txtNewPasswordVerify" [toggleMask]="true"
                            [feedback]="false"
                            [(ngModel)]="newPasswordVerify" #txtNewPasswordVerify="ngModel" required
                            maxlength="20"></p-password>
                <app-validator-message [templateVar]="txtNewPasswordVerify"
                                       [isSubmitted]="isSubmitted"></app-validator-message>
            </div>
        </div>
    </div>

    <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
            icon="{{'common.button.icon.save'|translate}}"
            class="{{'common.button.class.dialog.save'|translate}}"></button>
</form>
