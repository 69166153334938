import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";

@Injectable({
    providedIn: 'root'
})
export class RptOrgStatusService extends AbstractCrudService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    listByStatus(status?: string) {
        if (status) {
            return this.list("status=" + status);
        } else {
            return this.listAll();
        }
    }

    getApiName() {
        return apiName.RPT_ORG_STATUS;
    }
}
