import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiName, sortType} from "../../../app-constant";
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";

@Injectable({
    providedIn: 'root'
})
export class UserAccountService extends AbstractCrudService {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.USER_ACCOUNT;
    }

    getPermsGrant(userId) {
        return this.view(userId + "/perms-grant");
    }

    savePermsGrant(userId, data) {
        return this.update(userId + "/perms-grant", data);
    }

    getDefaultSortField(): string {
        return "userId";
    }
}
