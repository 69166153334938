import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {getServerApiUrl} from '../../../app-constant';

@Injectable({
  providedIn: 'root'
})
export class ReportTransService {

  constructor(private http: HttpClient) {
  }

  uploadReconciliation(contentFile: any, rowStart: any) {
    const formData = new FormData();
    formData.append('contentFile', contentFile);
    if (rowStart) {
      formData.append('rowStart', rowStart);
    }
    return this.http.post(getServerApiUrl() + '/iam-payment-trans/histories/reconciliation', formData, {responseType: 'blob'});
  }

  uploadEarn(contentFile: any, rowStart: any) {
    const formData = new FormData();
    formData.append('contentFile', contentFile);
    if (rowStart) {
      formData.append('rowStart', rowStart);
    }
    return this.http.post( getServerApiUrl() + '/iam-customer/customers/earn-excel', formData, {responseType: 'blob'});
  }

}
