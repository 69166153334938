<app-telsoft [parentComponent]="this">
    <div class="card">
        <p-table #dt [value]="tableData.listEtt" [lazy]="true" (onLazyLoad)="loadLazyTable($event)"
                 [totalRecords]="tableData.totalRecords" [rows]="10" [paginator]="true"
                 [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [(selection)]="listSelectedEtt"
                 [rowHover]="true" dataKey="organizationId" (onFilter)="onTableFilter($event)"
                 currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

            <ng-template pTemplate="caption">
                <h5 class="p-m-0">{{"label.mngPromotion.listPromotion" | translate}}</h5>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="pseudoId">
                        {{"label.mngPromotion.promotionPseudoId" | translate}}
                        <p-sortIcon field="pseudoId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="itemDescription">
                        {{"label.mngPromotion.promotionName" | translate}}
                        <p-sortIcon field="itemDescription"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fromDate">
                        {{"common.label.fromDate" | translate}}
                        <p-sortIcon field="fromDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="thruDate">
                        {{"common.label.thruDate" | translate}}
                        <p-sortIcon field="thruDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">
                        {{"common.label.status" | translate}}
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="pseudoId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="itemDescription"></p-columnFilter>
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-columnFilter field="status" matchMode="equals">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown showClear="true" [ngModel]="value" [options]="listStatus" placeholder="{{'common.label.cbChoose'|translate}}"
                                            (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr class="data-row" showDelay="700" pTooltip="{{item.storePromotionId}}" tooltipPosition="top">
                    <td>
                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                    </td>
                    <td (dblclick)="copyMessage(item.pseudoId)">
                        <div class="row-control">
                            <app-table-control [entity]="item" [preView]="preView" [preCopy]="preCopy"
                                               [preUpdate]="preUpdate" [preDelete]="preDelete">
                            </app-table-control>
                        </div>
                        <div class="row-title">{{item.pseudoId}}</div>
                    </td>
                    <td>{{ item.itemDescription }}</td>
                    <td>{{ item.fromDate | date : getDateTimeFormat() }}</td>
                    <td>{{ item.thruDate | date : getDateTimeFormat() }}</td>
                    <td>{{ item.status }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">{{'common.datatable.emptyMessage'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <app-dialog [parentComponent]="this" [form]="simpleForm">
        <form #simpleForm="ngForm" (ngSubmit)="handleSave(simpleForm.invalid)">
            <div class="iam-scrollable-y" [style]="{maxHeight: (windowHeight - 300) + 'px'}">
                <div class="row mt-2 mb-2">
                    <div class="col-12 col-md-6 py-0">
                        <div class="p-field">
                            <app-label for="pseudoId" value="label.mngPromotion.promotionPseudoId" required="true"></app-label>
                            <input id="pseudoId" name="pseudoId" type="text" pInputText
                                   [(ngModel)]="selectedEtt.pseudoId" [disabled]="isDisable"
																	 #pseudoId="ngModel" required maxlength="50"/>
                            <app-validator-message [templateVar]="pseudoId" [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 py-0">
                        <div class="p-field">
                            <app-label for="itemDescription" value="label.mngPromotion.promotionName" required="true"></app-label>
                            <input id="itemDescription" name="itemDescription" type="text" pInputText
                                   [(ngModel)]="selectedEtt.itemDescription" [disabled]="isDisable"
																	 #itemDescription="ngModel" required/>
                            <app-validator-message [templateVar]="itemDescription" [isSubmitted]="isSubmitted"></app-validator-message>
                        </div>
                    </div>
                </div>
                <p-fieldset legend="{{ 'label.mngPromotion.condition'|translate }}">
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <b>1. Điểm bán áp dụng</b>
                        </div>
                        <div class="p-col-12">
                            <div class="p-field-checkbox mb-0">
                                <p-checkbox name="includeAllProductStores" [(ngModel)]="selectedEtt.includeAllProductStores" [disabled]="isDisable"
                                            binary="true" inputId="includeAllProductStores"></p-checkbox>
                                <label for="includeAllProductStores" class="mb-0">Áp dụng tất cả điểm bán</label>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0 {{ selectedEtt.includeAllProductStores ? 'p-disabled' : '' }}">
                            <p-table [value]="selectedEtt.productStores"
                                     styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines p-fluid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [width]="40" class="p-text-center">STT</th>
                                        <th [width]="220">{{ 'label.mngProductStore.productStoreCode'|translate }}</th>
                                        <th>{{ 'label.mngProductStore.storeName'|translate }}</th>
                                        <th [width]="45" class="p-text-center action-column">
                                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="showDialogAddProductStore()"
                                                      title="{{ 'Add'|translate }}"></p-button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <tr>
                                        <td class="p-text-center">{{ (index + 1)|number }}</td>
                                        <td>{{ rowData['productStoreCode'] }}</td>
                                        <td>{{ rowData['storeName'] }}</td>
                                        <td class="p-text-center action-column">
                                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm" (onClick)="deleteProductStore(rowData)"
                                                      title="{{ 'Delete'|translate }}"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <b>2. Sản phẩm áp dụng</b>
                        </div>
                        <div class="p-col-12">
                            <div class="p-field-checkbox mb-0">
                                <p-checkbox name="includeAllProducts" [(ngModel)]="selectedEtt.includeAllProducts" [disabled]="isDisable"
                                            binary="true" inputId="includeAllProducts"></p-checkbox>
                                <label for="includeAllProducts" class="mb-0">Áp dụng tất cả sản phẩm</label>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0 {{ selectedEtt.includeAllProducts ? 'p-disabled' : '' }}">
                            <p-table [value]="selectedEtt.products"
                                     styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines p-fluid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [width]="40" class="p-text-center">STT</th>
                                        <th [width]="220">{{ 'label.mngProduct.productCode'|translate }}</th>
                                        <th>{{ 'label.mngProduct.productName'|translate }}</th>
                                        <th [width]="160">{{ 'label.mngProduct.minQuantity'|translate }}</th>
                                        <th [width]="45" class="p-text-center action-column">
                                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="showDialogAddProduct()"
                                                      title="{{ 'Add'|translate }}"></p-button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <tr>
                                        <td class="p-text-center">{{ (index + 1)|number }}</td>
                                        <td>{{ rowData['productCode'] }}</td>
                                        <td>{{ rowData['productName'] }}</td>
                                        <td pEditableColumn class="p-text-right">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-inputNumber [(ngModel)]="rowData['minQuantity']" [ngModelOptions]="{standalone: true}" [min]="1"></p-inputNumber>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData['minQuantity']|number }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="p-text-center action-column">
                                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm" (onClick)="deleteProduct(rowData)"
                                                      title="{{ 'Delete'|translate }}"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <b>3. Thời gian</b>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="fromDate" value="common.label.fromDate"></app-label>
                                <p-calendar id="fromDate" name="fromDate" appendTo="body"
                                            [(ngModel)]="selectedEtt.fromDate" monthNavigator="true"
                                            yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true"
                                            [showSeconds]="true" showButtonBar="true"
                                            [disabled]="isDisable"></p-calendar>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 py-0">
                            <div class="p-field">
                                <app-label for="thruDate" value="common.label.thruDate"></app-label>
                                <p-calendar id="thruDate" name="thruDate" appendTo="body"
                                            [(ngModel)]="selectedEtt.thruDate" monthNavigator="true"
                                            yearNavigator="true" [yearRange]="getYearRange()" [showTime]="true"
                                            [showSeconds]="true" showButtonBar="true"
                                            [disabled]="isDisable"></p-calendar>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0">
                            <div class="p-field-checkbox mb-0">
                                <p-checkbox name="requireTimePeriod" [(ngModel)]="selectedEtt.requireTimePeriod" [disabled]="isDisable"
                                            binary="true" inputId="requireTimePeriod"></p-checkbox>
                                <label for="requireTimePeriod" class="mb-0">Khung giờ</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 py-0">
                            <div class="p-field {{ selectedEtt.requireTimePeriod ? '' : 'p-disabled' }}">
                                <app-label for="fromTime" value="common.label.fromTime"></app-label>
                                <p-inputNumber id="fromTime" name="fromTime" [(ngModel)]="selectedEtt.fromTime" [disabled]="isDisable"
                                               [min]="0" [max]="24"></p-inputNumber>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 py-0">
                            <div class="p-field {{ selectedEtt.requireTimePeriod ? '' : 'p-disabled' }}">
                                <app-label for="thruTime" value="common.label.thruTime"></app-label>
                                <p-inputNumber id="thruTime" name="thruTime" [(ngModel)]="selectedEtt.thruTime" [disabled]="isDisable"
                                               [min]="0" [max]="24"></p-inputNumber>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <b>4. Khách hàng áp dụng</b>
                        </div>
                        <div class="p-col-12 pb-0">
                            <div class="p-field-checkbox {{ selectedEtt.onlyClassificationCustomer ? 'p-disabled' : '' }}">
                                <p-checkbox name="onlyNewCustomer" [(ngModel)]="selectedEtt.onlyNewCustomer" [disabled]="isDisable"
                                            binary="true" inputId="onlyNewCustomer"></p-checkbox>
                                <label for="onlyNewCustomer" class="mb-0">Chỉ áp dụng khách hàng mới</label>
                            </div>
                            <div class="p-field-checkbox {{ selectedEtt.onlyNewCustomer ? 'p-disabled' : '' }}">
                                <p-checkbox name="onlyClassificationCustomer" [(ngModel)]="selectedEtt.onlyClassificationCustomer" [disabled]="isDisable"
                                            binary="true" inputId="onlyClassificationCustomer"></p-checkbox>
                                <label for="onlyClassificationCustomer" class="mb-0">Áp dụng hạng khách hàng</label>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0 {{ selectedEtt.onlyClassificationCustomer ? '' : 'p-disabled' }}">
                            <p-table [value]="selectedEtt.partyClassifications"
                                     styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines p-fluid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [width]="40" class="p-text-center">STT</th>
                                        <th>{{ 'Hạng khách hàng'|translate }}</th>
                                        <th [width]="45" class="p-text-center action-column">
                                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="showDialogAddClassification()"
                                                      title="{{ 'Add'|translate }}"></p-button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <tr>
                                        <td class="p-text-center">{{ (index + 1)|number }}</td>
                                        <td>{{ rowData['description'] }}</td>
                                        <td class="p-text-center action-column">
                                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm" (onClick)="deleteClassification(rowData)"
                                                      title="{{ 'Delete'|translate }}"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <b>5. Đơn hàng</b>
                        </div>
                        <div class="p-col-12 pb-0">
                            <div class="p-field-checkbox">
                                <p-checkbox name="requireMinOrderItemTotal" [(ngModel)]="selectedEtt.requireMinOrderItemTotal" [disabled]="isDisable"
                                            binary="true" inputId="requireMinOrderItemTotal"></p-checkbox>
                                <label for="requireMinOrderItemTotal" class="mb-0">Giá trị đơn hàng tối thiểu</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 py-0">
                            <div class="p-field {{ selectedEtt.requireMinOrderItemTotal ? '' : 'p-disabled' }}">
                                <p-inputNumber name="minOrderItemTotal" [(ngModel)]="selectedEtt.minOrderItemTotal" [disabled]="isDisable"
                                               [min]="0"></p-inputNumber>
                            </div>
                        </div>
                    </div>
                </p-fieldset>
                <p-fieldset legend="{{ 'common.label.result'|translate }}" styleClass="mt-3">
                    <div class="p-grid">
                        <div class="p-col-12 pb-0">
                            <div class="p-field-checkbox">
                                <p-checkbox name="loyaltyPointsIncrease" [(ngModel)]="selectedEtt.loyaltyPointsIncrease" [disabled]="isDisable"
                                            binary="true" inputId="loyaltyPointsIncrease"></p-checkbox>
                                <label for="loyaltyPointsIncrease" class="mb-0">Tăng tỷ lệ điểm được nhận</label>
                            </div>
                        </div>
                        <div class="p-col-11 pt-0 pl-5 {{ selectedEtt.loyaltyPointsIncrease ? '' : 'p-disabled' }}">
                            <div class="p-grid">
                                <div class="p-col-3">
                                    <div class="p-field-radiobutton">
                                        <p-radioButton name="loyaltyPointsIncreaseBy" value="loyaltyPointsIncreasePercent" [(ngModel)]="selectedEtt.loyaltyPointsIncreaseBy" [disabled]="isDisable"
                                                       inputId="loyaltyPointsIncreasePercent"></p-radioButton>
                                        <label for="loyaltyPointsIncreasePercent" class="mb-0">Theo % nhận thêm</label>
                                    </div>
                                </div>
                                <div class="p-col-3 pt-2 {{ selectedEtt.loyaltyPointsIncreaseBy === 'loyaltyPointsIncreasePercent' ? '' : 'p-disabled' }}">
                                    <p-inputNumber name="loyaltyPointsIncreasePercent" [(ngModel)]="selectedEtt.loyaltyPointsIncreasePercent" [disabled]="isDisable"
                                                   [min]="0"></p-inputNumber>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-3">
                                    <p-radioButton name="loyaltyPointsIncreaseBy" value="loyaltyPointsIncreaseValue" [(ngModel)]="selectedEtt.loyaltyPointsIncreaseBy" [disabled]="isDisable"
                                                   inputId="loyaltyPointsIncreaseValue"></p-radioButton>
                                    <label for="loyaltyPointsIncreaseValue" class="mb-0">Theo giá trị nhận thêm</label>
                                </div>
                                <div class="p-col-3 pt-2 {{ selectedEtt.loyaltyPointsIncreaseBy === 'loyaltyPointsIncreaseValue' ? '' : 'p-disabled' }}">
                                    <p-inputNumber name="loyaltyPointsIncreaseValue" [(ngModel)]="selectedEtt.loyaltyPointsIncreaseValue" [disabled]="isDisable"
                                                   [min]="0"></p-inputNumber>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-field-checkbox mb-0">
                                <p-checkbox name="includeGifts" [(ngModel)]="selectedEtt.includeGifts" [disabled]="isDisable"
                                            binary="true" inputId="includeGifts"></p-checkbox>
                                <label for="includeGifts" class="mb-0">Sản phẩm được tặng</label>
                            </div>
                        </div>
                        <div class="p-col-12 pt-0 {{ selectedEtt.includeGifts ? '' : 'p-disabled' }}">
                            <p-table [value]="selectedEtt.gifts"
                                     styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines p-fluid">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [width]="40" class="p-text-center">STT</th>
                                        <th [width]="220">{{ 'label.mngProduct.productCode'|translate }}</th>
                                        <th>{{ 'label.mngProduct.productName'|translate }}</th>
                                        <th [width]="160">{{ 'common.label.quantity'|translate }}</th>
                                        <th [width]="45" class="p-text-center action-column">
                                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-info p-button-outlined p-button-sm p-mr-1" (onClick)="showDialogAddGift()"
                                                      title="{{ 'Add'|translate }}"></p-button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <tr>
                                        <td class="p-text-center">{{ (index + 1)|number }}</td>
                                        <td>{{ rowData['productCode'] }}</td>
                                        <td>{{ rowData['productName'] }}</td>
                                        <td pEditableColumn class="p-text-right">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-inputNumber [(ngModel)]="rowData['giftQuantity']" [ngModelOptions]="{standalone: true}" [min]="1"></p-inputNumber>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData['giftQuantity']|number }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="p-text-center action-column">
                                            <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm" (onClick)="deleteGift(rowData)"
                                                      title="{{ 'Delete'|translate }}"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <button pButton pRipple type="submit" label="{{'common.button.save'|translate}}"
                    *ngIf="isCreate || isUpdate" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
            <button pButton pRipple label="{{'Duyệt'|translate}}" (click)="approve()"
                    *ngIf="isView && selectedEtt['statusId'] === 'PromoCreated'" icon="{{'common.button.icon.save'|translate}}"
                    class="{{'common.button.class.dialog.save'|translate}}"></button>
        </form>
    </app-dialog>
</app-telsoft>

<p-dialog header="{{ 'label.mngProductStore.listProductStore'|translate }}" [(visible)]="displayDialogAddProductStore" [style]="{width: '80rem'}">
    <p-table [value]="productStores" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalProductStores"
             [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
             (onLazyLoad)="loadProductStoresLazy($event)" dataKey="productStoreId" [(selection)]="selectedProductStores"
             styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="productStoreCode" [width]="180">{{ 'label.mngProductStore.productStoreCode'|translate }}
                    <p-sortIcon field="productStoreCode"></p-sortIcon>
                </th>
                <th pSortableColumn="storeName">{{ 'label.mngProductStore.storeName'|translate }}
                    <p-sortIcon field="storeName"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <p-columnFilter type="text" field="productStoreCode" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="storeName" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{ rowData['productStoreCode'] }}</td>
                <td>{{ rowData['storeName'] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <p-button label="{{'common.label.add'|translate }}" icon="pi pi-check" styleClass="p-button-raised" (onClick)="addProductStore()"></p-button>
        <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayDialogAddProductStore=false"></p-button>
    </p-footer>
</p-dialog>

<p-dialog header="{{ 'Hạng khách hàng'|translate }}" [(visible)]="displayDialogAddClassification" [style]="{width: '80rem'}">
    <p-table [value]="classifications" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalClassifications"
             [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
             (onLazyLoad)="loadClassificationsLazy($event)" dataKey="partyClassificationId" [(selection)]="selectedClassifications"
             styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="description">{{ 'Hạng khach hàng'|translate }}
                    <p-sortIcon field="description"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <p-columnFilter type="text" field="description" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{ rowData['description'] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <p-button label="{{'common.label.add'|translate }}" icon="pi pi-check" styleClass="p-button-raised" (onClick)="addClassification()"></p-button>
        <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayDialogAddClassification=false"></p-button>
    </p-footer>
</p-dialog>

<p-dialog header="{{ 'label.mngProduct.listProduct'|translate }}" [(visible)]="displayDialogAddProduct || displayDialogAddGift" [style]="{width: '80rem'}">
    <p-table [value]="products" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalProducts"
             [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
             (onLazyLoad)="loadProductsLazy($event)" dataKey="productId" [(selection)]="selectedProducts"
             styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="productCode" [width]="180">{{ 'label.mngProduct.productCode'|translate }}
                    <p-sortIcon field="productCode"></p-sortIcon>
                </th>
                <th pSortableColumn="productName">{{ 'label.mngProduct.productName'|translate }}
                    <p-sortIcon field="productName"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <p-columnFilter type="text" field="productCode" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="productName" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{ rowData['productCode'] }}</td>
                <td>{{ rowData['productName'] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <p-button label="{{'common.label.add'|translate }}" icon="pi pi-check" styleClass="p-button-raised" (onClick)="addProduct()"></p-button>
        <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayDialogAddProduct=false;displayDialogAddGift=false"></p-button>
    </p-footer>
</p-dialog>
