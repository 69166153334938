import {HttpErrorResponse} from '@angular/common/http';
import {AppMessageService} from "../services/app-message.service";
import {ConfirmationService, ConfirmEventType, LazyLoadEvent, TreeNode} from "primeng/api";
import {Utils} from '../../common/utils';
import {plainToClass} from 'class-transformer';
import {ActivatedRoute, Router} from "@angular/router";
import {AppInjector} from 'src/app/app-injector';
import {LoaderService} from "../../services/common/loader.service";
import {AlertMessages} from '../../models/messages/alertMessages';
import {StringUtils} from "../../common/string-utils";
import {AuthService} from "../../services/auth/auth.service";
import {Language} from "../../models/language/language";
import {ApDomainNumber} from "../../models/ap-domain/ap-domain-number";
import {ApDomainString} from "../../models/ap-domain/ap-domain-string";
import {TableData} from "../../models/lazy-load-data/table-data";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {ApiSearch} from "./api-search";

export abstract class BaseComponent {
    INTERNAL_ERROR = 'ERR-500';
    TIMEOUT_ERROR = 'ERR-100';
    OTHER_ERROR = 'ERR-200';

    errorResponse: any;
    errorMsg: string;
    errorCode: string;
    detailError: string;

    public mainMessage = new AlertMessages();
    otherMessage1 = new AlertMessages();
    otherMessage2 = new AlertMessages();
    otherMessage3 = new AlertMessages();
    otherMessage4 = new AlertMessages();

    messageService: AppMessageService;
    confirmationService: ConfirmationService;
    loaderService: LoaderService;
    authService: AuthService;
    crudService: any;

    comboboxStatusEnable: any[];
    comboboxStatusYes: any[];
    comboboxStatusAllow: any[];

    comboboxNumberStatusEnable: any[];
    comboboxNumberStatusYes: any[];
    comboboxNumberStatusAllow: any[];

    locale: Language;

    constructor(protected router: Router, protected route: ActivatedRoute) {
        this.messageService = AppInjector.get(AppMessageService);
        this.loaderService = AppInjector.get(LoaderService);
        this.authService = AppInjector.get(AuthService);

        this.confirmationService = AppInjector.get(ConfirmationService);
        if (!Utils.isNullOrUndefined(this.getServiceClass())) {
            this.crudService = AppInjector.get(this.getServiceClass());
        }

        this.locale = this.authService.getLocale();

        this.comboboxStatusEnable = [
            {label: this.messageService.translate("common.label.enable"), value: 'Y'},
            {label: this.messageService.translate("common.label.disable"), value: 'n'},
        ];

        this.comboboxStatusYes = [
            {label: this.messageService.translate("common.label.yes"), value: 'Y'},
            {label: this.messageService.translate("common.label.no"), value: 'N'},
        ];

        this.comboboxStatusAllow = [
            {label: this.messageService.translate("common.label.allow"), value: 'Y'},
            {label: this.messageService.translate("common.label.disallow"), value: 'N'},
        ];

        this.comboboxNumberStatusEnable = [
            {label: this.messageService.translate("common.label.enable"), value: 1},
            {label: this.messageService.translate("common.label.disable"), value: 0},
        ];

        this.comboboxNumberStatusYes = [
            {label: this.messageService.translate("common.label.yes"), value: 1},
            {label: this.messageService.translate("common.label.no"), value: 0},
        ];

        this.comboboxNumberStatusAllow = [
            {label: this.messageService.translate("common.label.allow"), value: 1},
            {label: this.messageService.translate("common.label.disallow"), value: 0},
        ];

    }

    getServiceClass() {
    }

    checkPermission(rightCode: string) {
        return this.checkPermissionPath(rightCode, this.router.url);
    }

    checkPermissionPath(rightCode: string, path?: string) {
        return this.authService.checkPermission(rightCode, path);
    }

    allowCreate = () => {
        return this.checkPermission("C");
    }

    allowRead = () => {
        return this.checkPermission("R");
    }

    allowUpdate = () => {
        return this.checkPermission("U");
    }

    allowDelete = () => {
        return this.checkPermission("D");
    }

    getAlertMsgs = () => {
        return this.mainMessage?.msgs;
    }

    getDialogMsgs = () => {
        return this.otherMessage1?.msgs;
    }

    getDialogSecondMsgs = () => {
        return this.otherMessage2?.msgs;
    }

    getDialogThirdMsgs = () => {
        return this.otherMessage3?.msgs;
    }

    getDialogFourthMsgs = () => {
        return this.otherMessage4?.msgs;
    }

    cloneEtt(ett: any, clazz?) {
        const newEtt = JSON.parse(JSON.stringify(ett));
        return Utils.isNullOrUndefined(clazz) ? newEtt : this.parseToClass(newEtt, clazz);
    }

    getDateFormat() {
        return this.messageService.translate("config.displayDateFormat");
    }

    getDateTimeFormat() {
        return this.messageService.translate("config.displayDateTimeFormat");
    }

    getLocaleConfig() {
        return this.messageService.translate("config.locale");
    }

    parseToClass(obj, clazz) {
        return plainToClass(clazz, obj);
    }

    confirmDialog(
        msgText: string, headerText: string, acceptCallback: () => any,
        event?: Event, rejectCallback?: () => any, cancelCallback?: () => any) {

        const cfg: any = {
            message: msgText,
            header: headerText,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                acceptCallback();
            },
            reject: (type) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        if (!Utils.isNullOrUndefined(rejectCallback)) {
                            rejectCallback();
                        }
                        break;
                    case ConfirmEventType.CANCEL:
                        if (!Utils.isNullOrUndefined(cancelCallback)) {
                            cancelCallback();
                        }
                        break;
                }
            }
        };

        if (!Utils.isNullOrUndefined(event)) {
            cfg.target = event.target;
            cfg.key = "confirmPopup";
        } else {
            cfg.key = "confirmDialog";
        }

        this.confirmationService.confirm(cfg);
    }

    async callHttpRequest(o, alertMessages?: AlertMessages): Promise<boolean> {
        return await this.executeHttpRequest(o, null, null, alertMessages);
    }

    async executeHttpRequest(o, success: (result) => void, clazz?, alertMessages?: AlertMessages): Promise<boolean> {
        try {
            this.loaderService.show();
            return await o.toPromise().then(response => {
                if (response.isSuccess !== 'N' && !response.errors && response.status !== "ERROR") {
                    this.errorCode = null;
                    this.errorMsg = null;

                    if (!Utils.isNullOrUndefined(success)) {
                        if (!Utils.isNullOrUndefined(clazz)) {
                            if (response.listData) {
                                success(this.parseToClass(response.listData, clazz));
                            } else {
                                success(this.parseToClass(response, clazz));
                            }

                        } else {
                            success(response);
                        }
                    }

                    return true;

                } else {
                    this._onError(response, alertMessages);
                }

            }, error => {
                this._onError(error, alertMessages);
            });

        } catch (error) {
            this._onError(error, alertMessages);
        }
    }

    onError(errorCode, errorMsg) {
    }

    _onError(response: any, alertMessages?: AlertMessages) {
        if (response == null) {
            return;
        }

        if (Utils.isNullOrUndefined(alertMessages)) {
            alertMessages = this.mainMessage;
        }

        this.errorCode = this.INTERNAL_ERROR;
        this.errorResponse = response;
        this.detailError = response.errors;
        if (Array.isArray(response.errors)) {
            this.errorMsg = response.errors?.join(", ");
        } else {
            this.errorMsg = response.errors;
        }

        if (response instanceof HttpErrorResponse) {
            if (Array.isArray(response.error.errors)) {
                this.errorMsg = response.error.errors?.join(", ");
            } else {
                this.errorMsg = response.error.errors;
            }
            this.errorCode = response.error.errorCode || this.INTERNAL_ERROR;
            this.detailError = response.error.errors;
        }

        if (!this.detailError) {
            this.detailError = response.result;
        }

        this.onError(this.errorCode, this.errorMsg);
        this.messageService.showHttpErrorViaMessages(this.errorCode, this.detailError, alertMessages);
        console.error(response);
    }

    copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        // Alert
        this.messageService.showInfoViaToast("common.alert.clipboardCopied");
    }

    getLabelYes(value: any) {
        // tslint:disable-next-line:triple-equals
        if (value == 1 || value === true) {
            return this.messageService.translate('common.label.yes');
        }
        return this.messageService.translate('common.label.no');
    }

    getLabelAllow(value: any) {
        // tslint:disable-next-line:triple-equals
        if (value == 1 || value === true) {
            return this.messageService.translate('common.label.allow');
        }
        return this.messageService.translate('common.label.disallow');
    }

    getLabelEnable(value: any) {
        // tslint:disable-next-line:triple-equals
        if (value == 1 || value === true) {
            return this.messageService.translate('common.label.enable');
        }
        // tslint:disable-next-line:triple-equals
        if (value == 0 || value === false) {
            return this.messageService.translate('common.label.disable');
        } else {
            return "";
        }
    }

    getValueApDomainByCode(listApDomain: any, code: any): string {
        // tslint:disable-next-line:triple-equals
        const arr = listApDomain.filter(obj => obj.code == code);
        if (arr.length > 0) {
            return arr[0].value;
        }
        return "";
    }

    buildTableColumns(strCols: string, labelPrefix) {
        if (!StringUtils.isNullOrEmpty(strCols) && !StringUtils.isNullOrEmpty(labelPrefix)) {
            const cols = [];
            const ar = strCols.split(",");
            ar.forEach(col => cols.push({
                field: col,
                header: this.messageService.translate(labelPrefix + '.' + col)
            }));
            return cols;
        }

        return [];
    }

    buildTableSearchTooltip(strCols: string, labelPrefix) {
        if (!StringUtils.isNullOrEmpty(strCols) && !StringUtils.isNullOrEmpty(labelPrefix)) {
            let cols = "";
            const ar = strCols.split(",");
            ar.forEach(col => cols += this.messageService.translate(labelPrefix + '.' + col) + ", ");
            return cols !== "" ? cols.replace(/,\s$/gi, "") : "";
        }

        return "";
    }

    // tslint:disable-next-line:max-line-length
    enrichList(listEntity: any[], listSearch: any[], fieldEnrichKey: string, fieldEnrichName?: string, fieldSearchKey?: string, fieldSearchValue?: string) {
        if (Utils.isNullOrUndefined(listEntity) || Utils.isNullOrUndefined(listSearch)
            || listEntity.length === 0 || listSearch.length === 0
            || StringUtils.isNullOrEmpty(fieldEnrichKey)) {
            return listEntity;
        }

        if (StringUtils.isNullOrEmpty(fieldEnrichName)) {
            fieldEnrichName = fieldEnrichKey.replace(/Id$/gi, "") + "Name";
        }

        if (listSearch[0] instanceof ApDomainNumber || listSearch[0] instanceof ApDomainString) {
            fieldSearchKey = "code";
            fieldSearchValue = "value";

        } else {
            if (StringUtils.isNullOrEmpty(fieldSearchKey)) {
                fieldSearchKey = fieldEnrichKey;
            }
            if (StringUtils.isNullOrEmpty(fieldSearchValue)) {
                const fields = [fieldEnrichName, "name"];
                for (const f of fields) {
                    if (listSearch[0][f] !== undefined) {
                        fieldSearchValue = f;
                    }
                }
            }
        }
        const mapSearch = new Map(listSearch.map(i => [i[fieldSearchKey] + "", i[fieldSearchValue]]));

        listEntity.forEach(entity => {
            entity[fieldEnrichName] = mapSearch.get(entity[fieldEnrichKey] + "");
        });
    }

    containsObject(obj: any, list: any): boolean {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }

        return false;
    }

    getYearRange(): string {
        const currentYear = (new Date()).getFullYear();
        return `${currentYear - 10}:${currentYear + 10}`;
    }

    isAllowDeleteMany(selection: any[]): boolean {
        return !Utils.isNullOrUndefined(selection) && selection.length !== 0;
    }

    listToTree(list, idKey, parentKey, labelKey, iconKey = "icon", labelKeyPrefix?: string) {
        const tree: TreeNode[] = [];
        const childrenOf = {};
        const map = {};
        let id;
        let label;
        let labelPrefix;
        let icon;
        let parentId;

        for (const data of list) {
            if (data[parentKey] === undefined) {
                continue;
            }

            const item: TreeNode = {};

            id = data[idKey];
            label = data[labelKey];
            labelPrefix = data[labelKeyPrefix];
            label = Utils.isNullOrUndefined(labelPrefix) ? label : "[" + labelPrefix + "] " + label;
            icon = data[iconKey];
            parentId = data[parentKey];
            childrenOf[id] = childrenOf[id] || [];

            item.key = id;
            item.label = label;
            item.data = data;
            item.children = childrenOf[id];
            item.parent = map[parentId];

            if (icon === undefined) {
                item.expandedIcon = "pi pi-folder-open";
                item.collapsedIcon = "pi pi-folder";
            } else {
                item.icon = icon;
            }

            map[id] = item;
            if (parentId !== 0) {
                // init its parent's children object
                childrenOf[parentId] = childrenOf[parentId] || [];
                // push it into its parent's children object
                childrenOf[parentId].push(item);
            } else {
                tree.push(item);
            }
        }

        if (tree.length !== 0) {
            tree[0].expanded = true;
        }

        return tree;
    }

    getGlobalFilterFields(): any[] {
        return [];
    }

    async lazyLoadTable(event: LazyLoadEvent, service, clazz, success: (result) => void): Promise<any> {
        if (Utils.isNullOrUndefined(event)) {
            return;
        }

        await this.executeHttpRequest(service.listLazy(event), result => {
            success(new TableData(event, plainToClass(clazz, result.listData), result.totalRecords));
        });
    }

    private getApiSorter(event: LazyLoadEvent) {
        if (!StringUtils.isNullOrEmpty(event.sortField)) {
            return "&" + event.sortField + "," + (event.sortOrder === 1 ? "ASC" : "DESC");
        }

        return "";
    }

    private getApiSearchTerm(event: LazyLoadEvent) {
        let searchTerm = "";
        let globalSearch = "";
        let columnSearch = "";

        if (!Utils.isNullOrUndefined(event.globalFilter) && !Utils.isNullOrUndefined(this.getGlobalFilterFields())) {
            let tmp = "";
            for (const field of this.getGlobalFilterFields()) {
                tmp += `${field}:*${event.globalFilter.trim()}* or `;
            }
            globalSearch += `( ${tmp.replace(/\sor\s$/gi, "")} )`;
        }

        for (const key in event.filters) {
            if (key === "global") {
                continue;
            }

            const subFilters = event.filters[key] as FilterMetadata[];
            for (const sub of subFilters) {
                if (!Utils.isNullOrUndefined(sub.value)) {
                    columnSearch += ApiSearch.getSearchTerm(key, sub) + " and ";
                }
            }
        }
        columnSearch = columnSearch.replace(/\sand\s$/gi, "");

        if (globalSearch !== "" && columnSearch !== "") {
            searchTerm = globalSearch + " and " + columnSearch;
        } else {
            searchTerm = globalSearch + columnSearch;
        }

        return searchTerm !== "" ? "&search=" + searchTerm : "";
    }

}
