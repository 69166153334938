import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName, getServerApiUrl} from "../../../app-constant";

@Injectable({
    providedIn: 'root'
})
export class TokenCodeService extends AbstractCrudService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getApiName() {
        return apiName.TOKEN_CODE;
    }

    useCode(code: string, password: string, description: string) {
        return this.post({
            "tokenCode": code,
            "description": description,
            password
        }, getServerApiUrl() + "/" + apiName.REDEEM_TOKEN_CODE);
    }

    convertCode(code: string, customerCode: string, description: string) {
        return this.post({
            "tokenCode": code,
            "customerCode": customerCode,
            "description": description
        }, getServerApiUrl() + "/" + apiName.CONVERT_TOKEN_CODE);
    }

    getDefaultSortField(): string {
        return "createDate";
    }
}
