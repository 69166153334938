import {Injectable} from '@angular/core';
import {AbstractCrudService} from "../../../_app-core/customizes/services/crud-service";
import {HttpClient} from "@angular/common/http";
import {apiName} from "../../../app-constant";

@Injectable({
    providedIn: 'root'
})
export class RptPaymentTransSummaryService extends AbstractCrudService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    listByPeriod(periodType: string, lastPeriodNum: number) {
        return this.list(`periodType=${periodType}&lastPeriodNum=${lastPeriodNum}`);
    }

    getApiName(): string {
        return apiName.RPT_PAYMENT_TRANS_SUMMARY;
    }
}
