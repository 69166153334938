import {Type} from "class-transformer";

export class TokenTransaction {
    transactionId: string;
    customerId: string;
    customerCode: string;
    transType: string;
    @Type(() => Date)
    postedDate: Date;
    transAmount: number;
}
