import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {RptCustomerService} from '../../services/report/rpt-customer.service';
import {BaseComponent} from '../../../_app-core/customizes/view/base-component';

@Component({
  selector: 'app-rpt-customer',
  templateUrl: './rpt-customer.component.html'
})
export class RptCustomerComponent extends BaseComponent implements OnInit {

  listData: any[] = [];
  fromMonth?: number;
  toMonth?: number;
  year?: number;
  isSubmitted: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected service: RptCustomerService
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.fromMonth = new Date().getMonth() - 3;
    this.toMonth = new Date().getMonth();
    this.year = new Date().getFullYear();
  }

  search(isInvalidForm: boolean) {
    if (isInvalidForm) {
      return;
    }

    this.executeHttpRequest(this.service.listRange(this.fromMonth, this.toMonth, this.year), result => {
      this.listData = result.listData;
    });
  }

}
