<app-breadcrumb></app-breadcrumb>
<app-general-messages [alertMessages]="getAlertMsgs"></app-general-messages>

<p-dialog header="Cộng điểm cho khách hàng" [(visible)]="displayEarn" [style]="{width: '50rem'}">
  <div class="p-fluid">
    <div class="row">
      <div class="col-12 col-md-12 py-0">
        <div class="p-field">
          <label>File</label>
          <input type="file" accept=".xlsx, .xls" (change)="onUploadEarn($event)" style="width: 100%;">
        </div>
      </div>
      <div class="col-12 col-md-12 py-0">
        <div class="p-field">
          <label>Dòng bắt đầu dữ liệu</label>
          <p-inputNumber [(ngModel)]="earnRowStart"></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-download" (click)="downloadEarnTemp()" label="Tải file mẫu" styleClass="p-button-info mr-2"></p-button>
    <p-button icon="pi pi-check" (click)="uploadEarn()" label="Upload" styleClass="mr-2"></p-button>
    <p-button icon="pi pi-times" (click)="displayEarn=false" label="Đóng" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<p-dialog header="Đối soát theo mã giao dịch" [(visible)]="displayReconciliation" [style]="{width: '50rem'}">
  <div class="p-fluid">
    <div class="row">
      <div class="col-12 col-md-12 py-0">
        <div class="p-field">
          <label>File</label>
          <input type="file" accept=".xlsx, .xls" (change)="onUploadReconciliation($event)" style="width: 100%;">
        </div>
      </div>
      <div class="col-12 col-md-12 py-0">
        <div class="p-field">
          <label>Dòng bắt đầu dữ liệu</label>
          <p-inputNumber [(ngModel)]="reconciliationRowStart"></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-download" (click)="downloadReconciliationTemp()" label="Tải file mẫu" styleClass="p-button-info mr-2"></p-button>
    <p-button icon="pi pi-check" (click)="uploadReconciliation()" label="Upload" styleClass="mr-2"></p-button>
    <p-button icon="pi pi-times" (click)="displayReconciliation=false" label="Đóng" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-card>
  <form #f="ngForm" (ngSubmit)="search(f.invalid)">
    <div class="row mb-2">
      <div class="col-12" style="text-align: right;">
        <button pButton pRipple type="button" (click)="showEarn()"
                label="Cộng điểm cho khách hàng" class="p-button p-button-warning mr-2"></button>
        <button pButton pRipple type="button" (click)="showReconciliation()"
                label="Đối soát theo mã giao dịch" class="p-button p-button-warning"></button>
      </div>
    </div>
    <div class="p-fluid">
      <div class="row">
        <div class="col-12 col-md-2 py-0">
          <div class="p-field">
            <app-label for="txtFromDate" value="label.rptLoyaltyInfo.fromDate"></app-label>
            <p-calendar id="txtFromDate" name="txtFromDate" appendTo="body"
                        [(ngModel)]="fromDate" #txtFromDate="ngModel" monthNavigator="true"
                        yearNavigator="true" [yearRange]="getYearRange()" required
                        [showSeconds]="true" showButtonBar="true"></p-calendar>
            <app-validator-message [templateVar]="txtFromDate"
                                   [isSubmitted]="isSubmitted"></app-validator-message>
          </div>
        </div>
        <div class="col-12 col-md-2 py-0">
          <div class="p-field">
            <app-label for="txtThruDate" value="label.rptLoyaltyInfo.thruDate"></app-label>
            <p-calendar id="txtThruDate" name="txtThruDate" appendTo="body"
                        [(ngModel)]="thruDate" #txtThruDate="ngModel" monthNavigator="true"
                        yearNavigator="true" [yearRange]="getYearRange()" required
                        [showSeconds]="true" showButtonBar="true"></p-calendar>
            <app-validator-message [templateVar]="txtThruDate"
                                   [isSubmitted]="isSubmitted"></app-validator-message>
          </div>
        </div>
        <div class="col-12 col-md-2 py-0">
          <div class="p-field">
            <app-label for="ownerPseudoId" value="Owner Code"></app-label>
            <input id="ownerPseudoId" name="ownerPseudoId" type="text" pInputText
                   [(ngModel)]="ownerPseudoId"/>
          </div>
        </div>
        <div class="col-12 col-md-1 py-0">
          <div class="p-field">
            <label>&nbsp;</label>
            <button pButton pRipple type="submit"
                    label="{{'common.button.search'|translate}}"
                    icon="{{'common.button.icon.search'|translate}}"
                    class="p-button p-button-info"></button>
          </div>
        </div>
        <div class="col-12 col-md-2 py-0">
          <div class="p-field">
            <label>&nbsp;</label>
            <button pButton pRipple type="button" (click)="export()"
                    label="Export Excel"
                    icon="pi pi-file-excel"
                    class="p-button p-button-info"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-table [value]="listData" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
           [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" styleClass="mt-3"
           currentPageReportTemplate="{{'common.datatable.currentPageReportTemplate'|translate}}">

    <ng-template pTemplate="caption">
      <h5 class="p-m-0">Transactions</h5>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="ownerPseudoId">
          Owner Code
          <p-sortIcon field="ownerPseudoId"></p-sortIcon>
        </th>
        <th pSortableColumn="ownerName">
          Owner Name
          <p-sortIcon field="ownerName"></p-sortIcon>
        </th>
        <th pSortableColumn="transType">
          {{"label.mngTransaction.transType" | translate}}
          <p-sortIcon field="transType"></p-sortIcon>
        </th>
        <th pSortableColumn="postedDate">
          {{"label.mngTransaction.postedDate" | translate}}
          <p-sortIcon field="postedDate"></p-sortIcon>
        </th>
        <th pSortableColumn="transAmount">
          {{"label.mngTransaction.transAmount" | translate}}
          <p-sortIcon field="transAmount"></p-sortIcon>
        </th>
        <th pSortableColumn="transMemo">
          {{"label.mngTransaction.description" | translate}}
          <p-sortIcon field="transMemo"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th>
          <p-columnFilter type="text" field="ownerPseudoId" matchMode="contains"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="text" field="ownerName" matchMode="contains"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="text" field="transType" matchMode="contains"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="date" field="postedDate" matchMode="dateIs">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-calendar (onSelect)="filter($event)" [monthNavigator]="true" [yearNavigator]="true"
                          [showButtonBar]="true" [yearRange]="getYearRange()" appendTo="body"></p-calendar>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="numeric" field="transAmount"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="text" field="transMemo" matchMode="contains"></p-columnFilter>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-trans>
      <tr>
        <td>
          {{trans.ownerPseudoId}}
        </td>
        <td>
          {{trans.ownerName}}
        </td>
        <td>
          {{trans.transType}}
        </td>
        <td>
          {{trans.postedDate | date : getDateTimeFormat()}}
        </td>
        <td>
          {{trans.transAmount}}
        </td>
        <td>
          {{trans.transMemo}}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">{{'common.datatable.emptyMessage'|translate}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>