import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimpleCrudComponent} from "../../view/simple-crud-component";
import {BaseComponent} from "../../view/base-component";
import {ConfirmationService, ConfirmEventType} from "primeng/api";
import {AppMessageService} from "../../services/app-message.service";
import {Utils} from "../../../common/utils";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {
    @Output() isMaximizerChange = new EventEmitter<boolean>();
    @Input() form: NgForm;
    @Input() isMaximizer = false;
    @Input() width = "65vw";
    @Input() isMaximizable = true;
    @Input() onHide: () => void;
    @Input() parentComponent: any;

    constructor() {
    }

    ngOnInit(): void {
    }

    onMaximize() {
        this.isMaximizer = !this.isMaximizer;
        this.isMaximizerChange.emit(this.isMaximizer);
    }

    onDialogShow() {
    }

    onDialogHide() {
        if (!Utils.isNullOrUndefined(this.form)) {
            this.form.control.reset();
        }

        if (!Utils.isNullOrUndefined(this.onHide)) {
            this.onHide();
        }

        this.parentComponent.hideEttDialog();
    }
}
