import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../_app-core/customizes/view/base-component";
import {ActivatedRoute, Router} from "@angular/router";
import {RptPartnerGrowthService} from "../../services/dashboard/rpt-partner-growth.service";
import {RptPaymentTransSummaryService} from "../../services/dashboard/rpt-payment-trans-summary.service";
import {RptUsingVoucherChartService} from "../../services/dashboard/rpt-using-voucher-chart.service";
import {RptPartnerActiveService} from "../../services/dashboard/rpt-partner-active.service";
import {RptExpirationPartnerService} from "../../services/dashboard/rpt-expiration-partner.service";
import {RptTotalCustomerEarnService} from "../../services/dashboard/rpt-total-customer-earn.service";
import {forkJoin} from "rxjs";
import {DateUtils} from "../../../_app-core/common/date-utils";
import {RptTotalCustomerRedeemService} from "../../services/dashboard/rpt-total-customer-redeem.service";

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardDemoComponent extends BaseComponent implements OnInit {
    rangeThisDay = DateUtils.getRangeThisDay();
    rangeThisWeek = DateUtils.getRangeThisWeek();
    rangeThisMonth = DateUtils.getRangeThisMonth();
    chartOptions: any;
    partnerGrowthData: any;
    paymentTransSummaryData: any;
    usingVoucherData: any;
    partnerActiveData: any;
    expirationPartnerData: any;
    totalCustEarnData: any;
    totalCustRedeemData: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private rptPartnerGrowthService: RptPartnerGrowthService,
        private rptPaymentTransSummaryService: RptPaymentTransSummaryService,
        private rptUsingVoucherChartService: RptUsingVoucherChartService,
        private rptPartnerActiveService: RptPartnerActiveService,
        private rptExpirationPartnerService: RptExpirationPartnerService,
        private rptTotalCustomerEarnService: RptTotalCustomerEarnService,
        private rptTotalCustomerRedeemService: RptTotalCustomerRedeemService,
    ) {
        super(router, route);
    }

    ngOnInit() {
        this.chartOptions = {
            responsive: true,
            hover: {
                mode: 'index'
            }
        };

        if (this.isMiraAdmin()) {
            this.initChartPartnerActive();
            this.initChartExpirationPartner();
            this.initChartPartnerGrowth();
        }

        if (this.isMiraAdmin() || this.isPartnerAdmin()) {
            this.initChartTotalEarn();
            this.initChartTotalRedeem();
            this.initChartPaymentTrans();
            this.initChartUsingVoucher();
        }
    }

    isMiraAdmin(): boolean {
        return this.authService.getUserInfo().roleTypeId === "Administrator";
    }

    isPartnerAdmin(): boolean {
        return this.authService.getUserInfo().roleTypeId === "Customer_Admin";
    }

    initChartPartnerGrowth() {
        if (this.partnerGrowthData) {
            return;
        }

        this.executeHttpRequest(this.rptPartnerGrowthService.listByPeriod("MONTH", 6), result => {
            this.partnerGrowthData = result;
            this.partnerGrowthData.datasets[0].borderColor = ['#579769'];
            this.partnerGrowthData.datasets[0].backgroundColor = ['rgba(187,251,196,0.2)'];
        });
    }

    initChartPaymentTrans() {
        if (this.paymentTransSummaryData) {
            return;
        }

        this.executeHttpRequest(this.rptPaymentTransSummaryService.listByPeriod("MONTH", 6), result => {
            this.paymentTransSummaryData = result;
            this.paymentTransSummaryData.datasets[0].borderColor = ['#578697'];
            this.paymentTransSummaryData.datasets[0].backgroundColor = ['rgba(187,222,251,0.2)'];

            this.paymentTransSummaryData.datasets[1].borderColor = ['#af601b'];
            this.paymentTransSummaryData.datasets[1].backgroundColor = ['rgba(175,96,27,0.2)'];
        });
    }

    initChartUsingVoucher() {
        if (this.usingVoucherData) {
            return;
        }

        this.executeHttpRequest(this.rptUsingVoucherChartService.listByPeriod("MONTH", 6), result => {
            this.usingVoucherData = result;
            this.usingVoucherData.datasets[0].borderColor = ['#579297'];
            this.usingVoucherData.datasets[0].backgroundColor = ['rgba(187,251,246,0.2)'];
        });
    }

    initChartPartnerActive() {
        if (this.partnerActiveData) {
            return;
        }

        this.executeHttpRequest(this.rptPartnerActiveService.listAll(), result => {
            this.partnerActiveData = result.number;
        });
    }

    initChartExpirationPartner() {
        if (this.expirationPartnerData) {
            return;
        }

        this.executeHttpRequest(this.rptExpirationPartnerService.listAll(), result => {
            this.expirationPartnerData = result.number;
        });
    }

    initChartTotalEarn() {
        if (this.totalCustEarnData) {
            return;
        }

        this.initChartAreaTotal(this.rptTotalCustomerEarnService, 'KH đã earn', result => {
            this.totalCustEarnData = result;
        });
    }

    initChartTotalRedeem() {
        if (this.totalCustRedeemData) {
            return;
        }

        this.initChartAreaTotal(this.rptTotalCustomerRedeemService, 'KH đã redeem', result => {
            this.totalCustRedeemData = result;
        });
    }

    async initChartAreaTotal(service, chartLabel, success: (result) => void): Promise<boolean> {
        let countThisDay;
        let countThisWeek;
        let countThisMonth;

        const options = this.cloneEtt(this.chartOptions);
        options.title = {text: chartLabel, display: true, position: "bottom"};

        forkJoin([
            this.executeHttpRequest(service.listByDateRange(this.rangeThisDay[0], this.rangeThisDay[1]),
                result => {
                    countThisDay = result.number;
                }),
            this.executeHttpRequest(service.listByDateRange(this.rangeThisWeek[0], this.rangeThisWeek[1]),
                result => {
                    countThisWeek = result.number;
                }),
            this.executeHttpRequest(service.listByDateRange(this.rangeThisMonth[0], this.rangeThisMonth[1]),
                result => {
                    countThisMonth = result.number;
                }),

        ]).subscribe(results => {
            const data = {
                chartOptions: options,
                datasets: [{
                    data: [
                        countThisDay,
                        countThisWeek,
                        countThisMonth,
                    ],
                    backgroundColor: [
                        "#42A5F5",
                        "#66BB6A",
                        "#FFA726",
                    ],
                    label: chartLabel
                }],
                labels: [
                    "Hôm nay",
                    "Tuần này",
                    "Tháng này",
                ]
            };

            success(data);
            return true;

        }, error => {
            this._onError(error);
        });

        return false;
    }
}
