import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {AppRoutingUrl} from "../../../app-routing-url";

@Injectable({
    providedIn: 'root'
})
export class RoutePageService {

    constructor(protected router: Router, private authService: AuthService) {
    }

    goErrorPage() {
        this.router.navigate([AppRoutingUrl.common.login]);
    }

    goAccessDeniedPage() {
        this.router.navigate([AppRoutingUrl.common.accessdenied]);
    }
}
