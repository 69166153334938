/**
 *
 * @project MIRAWAY-WEB
 * @author chiendx on 30/08/2021.
 */

import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {LoaderService} from '../common/loader.service';
import {AuthService} from '../auth/auth.service';
import {Router} from "@angular/router";
import {RoutePageService} from "../route-page/route-page.service";
import {StorageService} from "../storage/storage.service";
import {getServerApiUrl} from "../../../app-constant";
import {DateUtils} from "../../common/date-utils";

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
    providedIn: 'root'
})

export class BasicAuthHttpInterceptorService implements HttpInterceptor {
    constructor(
        public authService: AuthService,
        private loaderService: LoaderService,
        private routePageService: RoutePageService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
        | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        // add authorization header with jwt token if available
        if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({headers}));

        } else {
            try {
                req = this.addTokenToRequest(req);
            } catch (error) {
                this.authService.doLogout();
            }
        }

        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((error).status) {
                        case 401: {
                            if (error.error.resultCode === "BAD_CREDENTIAL") {
                                return throwError(error);
                            }

                            if (error.error.resultCode === "UNAUTHORIZED" || error.error.resultCode === "ERR-401") {
                                this.authService.forceLogout();
                            } else {
                                this.routePageService.goErrorPage();
                            }

                            return throwError(error);
                        }
                        case 403: {
                            this.routePageService.goAccessDeniedPage();
                            return throwError(error);
                        }
                        default:
                            return throwError(error);
                    }
                }
                return throwError(error);
            }), finalize(() => this.loaderService.hide()));
    }

    private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
                setHeaders: {
                    // "X-CSRF-Token": this.authService.getToken(),
                    "api_key": this.authService.getToken(),
                    "Accept-Language": this.authService.getLocale().languageCode,
                    "Timezone" : DateUtils.getTimeZone(),
                },
                // withCredentials: true
            }
        );
    }
}
